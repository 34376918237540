import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { adminRole } from 'helper/constant';
import { connect } from 'react-redux';

function VerifyAccessLevel({
    loggedInAdminRole,
    returnType,
    className,
    content,
    children,
    allowedAdmins = [],
}) {
    const role = adminRole[loggedInAdminRole];

    if (allowedAdmins.includes(role)) {
        return <>{children}</>;
    }

    switch (returnType) {
        case 'unClickable':
            return <Box className={className}>{content}</Box>;
        case 'credential':
            return <Typography className={className}>***********</Typography>;
        default:
            return <></>;
    }
}

function mapStateToProps(state) {
    return {
        loggedInAdminRole: state.auth?.userData.user.type,
    };
}

export default connect(mapStateToProps, null)(VerifyAccessLevel);
