import React, { useState } from 'react';

import { Select, MenuItem, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useCustomSelectStyles from './styles';

import { ReactComponent as DropDown } from 'asset/icons/pagination_dropdown.svg';

const CustomSelect = ({
    name,
    value,
    onChange,
    options,
    placeholder,
    error,
    helperText,
    customStyleClass,
    customMenuItemStyleClass,
    showOnlyNumericValue = false,
    menuPropsOverride = {},
}) => {
    const classes = useCustomSelectStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const defaultMenuProps = {
        PaperProps: {
            style: {
                width: anchorEl ? anchorEl.clientWidth : 0,
                maxHeight: 250,
                overflowY: 'auto',
                marginTop: '3px',
            },
            className: classes.menuStyles,
        },
        anchorEl: anchorEl,
        keepMounted: true,
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    };

    const menuProps = {
        ...defaultMenuProps,
        ...menuPropsOverride,
        PaperProps: {
            ...defaultMenuProps.PaperProps,
            ...menuPropsOverride.PaperProps,
        },
        anchorOrigin: {
            ...defaultMenuProps.anchorOrigin,
            ...menuPropsOverride.anchorOrigin,
        },
        transformOrigin: {
            ...defaultMenuProps.transformOrigin,
            ...menuPropsOverride.transformOrigin,
        },
    };

    const dynamicColor = value ? '#101828' : '#98A2B3';

    return (
        <>
            <Select
                name={name}
                open={Boolean(anchorEl)}
                className={clsx(customStyleClass, classes.select)}
                value={value}
                MenuProps={menuProps}
                onChange={onChange}
                placeholder={placeholder}
                IconComponent={DropDown}
                displayEmpty={true}
                onClose={handleClose}
                onOpen={handleOpen}
                error={error}
                renderValue={(selected) => {
                    const selectedOption = options.find((option) => option.value === selected);
                    if (selectedOption) {
                        return showOnlyNumericValue ? selectedOption.value : selectedOption.label;
                    }
                    return placeholder;
                }}
                style={{
                    color: dynamicColor,
                }}
            >
                <MenuItem value="" disabled className={classes.placeholderItem}>
                    {placeholder}
                </MenuItem>
                {options?.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        className={clsx(classes.menuItem, customMenuItemStyleClass)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText className={classes.errMsg}>{helperText}</FormHelperText>}
        </>
    );
};

CustomSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    customStyleClass: PropTypes.string,
    menuPropsOverride: PropTypes.object,
    name: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    customMenuItemStyleClass: PropTypes.string,
    showOnlyNumericValue: PropTypes.bool,
};
export default CustomSelect;
