import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import { Tabs, Tab, Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Input from 'components/Input';
import MedallionHeader from 'components/MedallionPreview/components/MedallionHeader';
import { useCommonScreesStyles } from 'components/MedallionPreview/styles';
import TabPanel from 'components/TabPanel';
import PropTypes from 'prop-types';

import { useContinueVerificationScreenStyle } from './styles';

import { ReactComponent as Email } from 'asset/icons/input_mail.svg';
import { ReactComponent as QrCode } from 'asset/icons/qr_code.svg';

const ContinueVerificationScreen = ({ customScreenStyle, themeLogo, theme, ...props }) => {
    const classes = useContinueVerificationScreenStyle();
    const styles = useCommonScreesStyles();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const mockData =
        'https://verify-stage.authenticating.com/mobile?token=a3e64a66-22bb-4547-bdb2-9082a970b72d';

    return (
        <Box className={clsx(customScreenStyle, styles.cardWrapper)}>
            <MedallionHeader themeLogo={themeLogo} title="Continue Your Verification" />

            <Box className={classes.tabsWrapper}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    classes={{
                        indicator: classes.indicator,
                    }}
                >
                    <Tab
                        label="SMS"
                        className={classes.tabLabel}
                        classes={{
                            selected: classes.selected,
                        }}
                    />
                    <Tab
                        label="Email"
                        className={classes.tabLabel}
                        classes={{
                            selected: classes.selected,
                        }}
                    />
                    <Tab
                        label="QR Code"
                        className={classes.tabLabel}
                        classes={{
                            selected: classes.selected,
                        }}
                    />
                    <Tab
                        label="Link"
                        className={classes.tabLabel}
                        classes={{
                            selected: classes.selected,
                        }}
                    />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Box className={classes.mainSection}>
                        <Typography className={classes.mainSectionHeading}>
                            receive a link on your phone
                        </Typography>
                        <Typography className={classes.mainSectionSubHeading}>
                            Enter your mobile phone number and we will send you a link to complete
                            this verification on your mobile device.
                        </Typography>
                        <Typography className={classes.phoneLabelTxt}>Phone Number</Typography>
                        <PhoneInput
                            country="us"
                            inputStyle={{
                                fontFamily: theme.fontFamily,
                                padding: '9.5px 14px 9.5px 58px',
                                width: '100%',
                                border: '1px solid #E5E7EB',
                                borderRadius: '6px',
                            }}
                            buttonStyle={{
                                border: '1px solid #E5E7EB',
                            }}
                            specialLabel={''}
                        />

                        <Button
                            variant={props.button?.variant || 'contained'}
                            classes={{
                                contained: classes.contained,
                                outlined: classes.outlined,
                            }}
                        >
                            Send Link
                        </Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box className={clsx(classes.mainSection, classes.emailWrapper)}>
                        <Typography className={classes.mainSectionHeading}>
                            receive a link via email
                        </Typography>
                        <Typography className={classes.mainSectionSubHeading}>
                            Enter your email address and we will send you a link to complete this
                            verification on a mobile device.
                        </Typography>
                        <Typography className={classes.phoneLabelTxt}>Email Address</Typography>
                        <Input
                            variant={props.textInputVariant}
                            placeholder="you@example.com"
                            startAdornment={true}
                            StartAdornmentIcon={Email}
                            className={classes.emailInput}
                        />

                        <Button
                            variant={props.button?.variant || 'contained'}
                            classes={{
                                contained: classes.contained,
                                outlined: classes.outlined,
                            }}
                        >
                            Send Link
                        </Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box className={classes.mainSection}>
                        <Typography className={classes.mainSectionHeading}>Scan QR Code</Typography>
                        <Typography className={classes.mainSectionSubHeading}>
                            Point your phone's camera to this QR code and follow the link.
                        </Typography>
                        <Box className={classes.qrcode}>
                            <QrCode />
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Box className={clsx(classes.mainSection, classes.copylinkWrapper)}>
                        <Typography className={classes.mainSectionHeading}>Copy Link</Typography>
                        <Typography className={classes.mainSectionSubHeading}>
                            Copy and open this link on your phone.
                        </Typography>
                        <Typography className={classes.copylink}>{mockData}</Typography>

                        <Button
                            variant={props.button?.variant || 'contained'}
                            classes={{
                                contained: classes.contained,
                                outlined: classes.outlined,
                            }}
                        >
                            Copy Link
                        </Button>
                    </Box>
                </TabPanel>
            </Box>
        </Box>
    );
};

ContinueVerificationScreen.propTypes = {
    themeLogo: PropTypes.any,
    theme: PropTypes.object,
    customScreenStyle: PropTypes.string,
    button: PropTypes.object,
    textInputVariant: PropTypes.object,
};

export default ContinueVerificationScreen;
