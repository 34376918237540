import DropdownContainer from './container/DropDownContainer';
import ColorChooserContainer from './container/ColorChoseContainer';
import ThemeButton from './container/themeButttonContainer';

export const fieldTypes = {
    COLOR: 'COLOR',
    DROPDOWN: 'DROPDOWN',
    BUTTON: 'BUTTON',
};

export const fieldComponents = {
    [fieldTypes.DROPDOWN]: DropdownContainer,
    [fieldTypes.COLOR]: ColorChooserContainer,
    [fieldTypes.BUTTON]: ThemeButton,
};

export const fieldNames = {
    PRIMARY_COLOR: 'primaryColor',
    SECONDARY_COLOR: 'secondaryColor',
    FONT_FAMILY: 'fontFamily',
    BUTTON_VARIANT: 'button',
    TEXT_INPUT_VARIANT: 'textInputVariant',
    MODAL_BACKGROUND_COLOR: 'modalBackgroundColor',
    MODAL_BORDER_RADIUS: 'modalBorderRadius',
    OVERLAY_COLOR: 'overlayColor',
    HEADER_COLOR: 'headerColor',
    NAVBAR_COLOR: 'navbarColor',
};

export const fieldDefaultValues = {
    [fieldNames.PRIMARY_COLOR]: '#154683',
    [fieldNames.SECONDARY_COLOR]: '#cde3fc',
    [fieldNames.FONT_FAMILY]: 'Lora',
    [fieldNames.BUTTON_VARIANT]: {
        variant: 'contained',
        borderRadius: '6px',
    },
    [fieldNames.TEXT_INPUT_VARIANT]: 'outlined',
    [fieldNames.MODAL_BACKGROUND_COLOR]: '#FFFFFF',
    [fieldNames.MODAL_BORDER_RADIUS]: '0px',
    [fieldNames.OVERLAY_COLOR]: '#CDE4FF',
    [fieldNames.HEADER_COLOR]: '#EFF5FD',
    [fieldNames.NAVBAR_COLOR]: '#EFF5FD',
};

const config = [
    {
        title: 'Color',
        fields: [
            {
                title: 'Primary Color',
                subTitle: 'Choose Primary Color',
                name: fieldNames.PRIMARY_COLOR,
                type: fieldTypes.COLOR,
                defaultValue: fieldDefaultValues[fieldNames.PRIMARY_COLOR],
            },
            {
                title: 'Secondary Color',
                subTitle: 'Choose Secondary Color',
                name: fieldNames.SECONDARY_COLOR,
                type: fieldTypes.COLOR,
                defaultValue: fieldDefaultValues[fieldNames.SECONDARY_COLOR],
            },
        ],
    },
    {
        title: 'Font',
        fields: [
            {
                title: 'Font style',
                subTitle: 'Choose Font family',
                type: fieldTypes.DROPDOWN,
                name: fieldNames.FONT_FAMILY,
                defaultValue: fieldDefaultValues[fieldNames.FONT_FAMILY],
                options: [
                    {
                        title: 'Serif : Lora',
                        value: 'Lora',
                    },
                    {
                        title: 'sans-Serif : Rubik',
                        value: 'Rubik',
                    },
                    {
                        title: 'Display : Comfortaa',
                        value: 'Comfortaa',
                    },
                    {
                        title: 'sans-serif : Noto Sans JP',
                        value: 'Noto Sans JP',
                    },
                    {
                        title: 'monospace:IBM Plex',
                        value: 'IBM Plex Sans',
                    },
                ],
            },
        ],
    },
    {
        title: 'Buttons',
        fields: [
            {
                title: 'Button type',
                subTitle: 'Choose Button type',
                type: fieldTypes.BUTTON,
                name: fieldNames.BUTTON_VARIANT,
                defaultValue: fieldDefaultValues[fieldNames.BUTTON_VARIANT],
                options: [
                    {
                        title: 'Outlined',
                        value: 'outlined',
                    },
                    {
                        title: 'Contained',
                        value: 'contained',
                    },
                    {
                        title: 'Text only',
                        value: 'text',
                    },
                ],
            },
        ],
    },
    {
        title: 'Advanced',
        fields: [
            {
                title: 'Input',
                subTitle: 'Input text Field Style',
                type: fieldTypes.DROPDOWN,
                name: fieldNames.TEXT_INPUT_VARIANT,
                defaultValue: fieldDefaultValues[fieldNames.TEXT_INPUT_VARIANT],
                options: [
                    {
                        title: 'Outlined',
                        value: 'outlined',
                    },
                    {
                        title: 'Filled',
                        value: 'filled',
                    },
                ],
            },
            {
                title: 'Modal Background Color',
                subTitle: 'Background color for modal window',
                name: fieldNames.MODAL_BACKGROUND_COLOR,
                type: fieldTypes.COLOR,
                defaultValue: fieldDefaultValues[fieldNames.MODAL_BACKGROUND_COLOR],
            },
            {
                title: 'Modal Border Radius',
                subTitle: 'Border radius for modal window',
                name: fieldNames.MODAL_BORDER_RADIUS,
                type: fieldTypes.DROPDOWN,
                defaultValue: fieldDefaultValues[fieldNames.MODAL_BORDER_RADIUS],
                options: [
                    {
                        title: '0px',
                        value: '0px',
                    },
                    {
                        title: '10px',
                        value: '10px',
                    },
                    {
                        title: '20px',
                        value: '20px',
                    },
                    {
                        title: '30px',
                        value: '30px',
                    },
                    {
                        title: '40px',
                        value: '40px',
                    },
                    {
                        title: '50px',
                        value: '50px',
                    },
                ],
            },
            {
                title: 'Overlay Color',
                name: fieldNames.OVERLAY_COLOR,
                type: fieldTypes.COLOR,
                defaultValue: fieldDefaultValues[fieldNames.OVERLAY_COLOR],
            },
            {
                title: 'Header Color',
                name: fieldNames.HEADER_COLOR,
                type: fieldTypes.COLOR,
                defaultValue: fieldDefaultValues[fieldNames.HEADER_COLOR],
            },
            {
                title: 'Navigation Bar Color',
                name: fieldNames.NAVBAR_COLOR,
                type: fieldTypes.COLOR,
                defaultValue: fieldDefaultValues[fieldNames.NAVBAR_COLOR],
            },
        ],
    },
];

export default config;
