import { makeStyles } from '@material-ui/core/styles';

export const useContinueVerificationScreenStyle = makeStyles((theme) => ({
    tabLabel: {
        minWidth: '0px',
        minHeight: '0px',
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        padding: '0px 19px',
        opacity: 1,
        textTransform: 'none',
    },
    tabs: {
        minHeight: '30px',
        fontFamily: theme.fontFamily,
    },
    indicator: {
        background: theme.primaryColor,
    },
    selected: {
        color: theme.primaryColor,
    },
    tabsWrapper: {
        padding: '24px 14px',
    },
    mainSection: {
        paddingTop: '16px',
    },
    mainSectionHeading: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        color: '#111827',
        paddingBottom: '4px',
    },
    mainSectionSubHeading: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#374151',
        paddingBottom: '36px',
    },
    phoneLabelTxt: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#374151',
        paddingBottom: '8px',
    },

    emailInput: {
        height: '39px',
        '& input': {
            padding: '8px 14px 8px 0px',
        },
    },
    qrcode: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '115px',
    },
    copylink: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        textDecorationLine: 'underline',
        wordBreak: 'break-all',
    },
    contained: {
        color: theme.primaryColor,
        width: '100%',
        padding: '10px 0px',
        marginTop: '88px',
        borderRadius: theme.button?.borderRadius,
        background: theme.primaryColor,
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#FFFFFF',
            textTransform: 'none',
        },
    },
    outlined: {
        border: `1px solid ${theme.primaryColor}`,
        borderRadius: theme.button?.borderRadius,
        background: '#FFFFFF',
        marginTop: '88px',
        width: '100%',
        padding: '10px 0px',
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            color: theme.primaryColor,
            textTransform: 'none',
        },
    },
    emailWrapper: {
        paddingBottom: '17px',
    },
    copylinkWrapper: {
        paddingBottom: '64px',
    },
}));
