import React from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';
import MedallionHeader from 'components/MedallionPreview/components/MedallionHeader';
import { useCommonScreesStyles } from 'components/MedallionPreview/styles';
import PropTypes from 'prop-types';

import { useVerificationOptionScreenStyle } from './styles';

import { ReactComponent as IssuedId } from 'asset/icons/verification_id.svg';
import { ReactComponent as Quiz } from 'asset/icons/verification_quiz.svg';

import VerificationCard from './VerificationCard';

const VerificationOptionsScreen = ({
    customScreenStyle,
    themeLogo,
    theme,
    isHovered,
    ...props
}) => {
    const classes = useVerificationOptionScreenStyle();
    const styles = useCommonScreesStyles();

    return (
        <Box className={clsx(customScreenStyle, styles.cardWrapper)}>
            <MedallionHeader themeLogo={themeLogo} title="Verification Options" />
            <Box className={classes.InnerCardWrapper}>
                <VerificationCard
                    Logo={<IssuedId />}
                    heading="Passport or Government-Issued ID"
                    theme={theme}
                    isHovered={isHovered}
                />
                <VerificationCard
                    theme={theme}
                    isHovered={isHovered}
                    Logo={<Quiz />}
                    heading="Knowledge Authentication Quiz"
                    subHeading="This option is only available to persons
                    found in US commercial databases."
                />
            </Box>
        </Box>
    );
};

VerificationOptionsScreen.propTypes = {
    isHovered: PropTypes.bool,
    themeLogo: PropTypes.any,
    theme: PropTypes.object,
    customScreenStyle: PropTypes.string,
};

export default VerificationOptionsScreen;
