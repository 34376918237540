import React from 'react';

import { Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomDrawer = ({ anchor = 'right', open, onClose, children, className, ...rest }) => {
    return (
        <Drawer anchor={anchor} open={open} onClose={onClose} PaperProps={{ className }} {...rest}>
            {children}
        </Drawer>
    );
};

CustomDrawer.propTypes = {
    anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    rest: PropTypes.object,
};

CustomDrawer.defaultProps = {
    anchor: 'right',
};

export default CustomDrawer;
