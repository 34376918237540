import actionDispatcher, { userActionDispatcher } from 'api/actionDispatcher';
import actionType from './actionConstant';
import {
    fetchAllWorkflowApi,
    createWorkflowApi,
    updateWorkflowApi,
    fetchOneWorkflowApi,
    setDefaultWorkflowApi,
    deleteWorkflowApi,
    updateWorkflowThemeLogoApi,
    deleteWorkflowThemeLogoApi,
} from 'api/workflow';

export const fetchAllWorkflowAction = ({ pageNumber, pageSize, token }, commonConfig) => {
    return actionDispatcher({
        serviceMethod: fetchAllWorkflowApi.bind(null, { pageNumber, pageSize, token }),
        actionTypeSuccess: actionType.FETCH_ALL_WORKFLOWS_SUCCESS,
        actionTypeFailure: actionType.FETCH_ALL_WORKFLOWS_FAILURE,
        actionTypeInProgress: actionType.FETCH_ALL_WORKFLOWS_REQUEST,
        extra: null,
        commonConfig,
        callback: () => {},
    });
};

export const createWorkflowAction = (Data, commonConfig, token, cb) => {
    return actionDispatcher({
        serviceMethod: createWorkflowApi.bind(null, Data, token),
        actionTypeSuccess: actionType.CREATE_WORKFLOW_SUCCESS,
        actionTypeFailure: actionType.CREATE_WORKFLOW_FAILURE,
        actionTypeInProgress: actionType.CREATE_WORKFLOW_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
};

export const updateWorkflowAction = (Data, workflowNanoId, commonConfig, token, cb) => {
    return userActionDispatcher({
        serviceMethod: updateWorkflowApi.bind(null, Data, workflowNanoId, token),
        actionTypeSuccess: actionType.UPDATE_WORKFLOW_SUCCESS,
        actionTypeFailure: actionType.UPDATE_WORKFLOW_FAILURE,
        actionTypeInProgress: actionType.UPDATE_WORKFLOW_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
};

export const fetchOneWorkflowAction = ({ workflowNanoId, token }, commonConfig, cb = () => {}) => {
    return actionDispatcher({
        serviceMethod: fetchOneWorkflowApi.bind(null, { workflowNanoId, token }),
        actionTypeSuccess: actionType.FETCH_ONE_WORKFLOW_SUCCESS,
        actionTypeFailure: actionType.FETCH_ONE_WORKFLOW_FAILURE,
        actionTypeInProgress: actionType.FETCH_ONE_WORKFLOW_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
};

export const setDefaultWorkflowAction = ({ workflowNanoId, token }, commonConfig, cb) => {
    return userActionDispatcher({
        serviceMethod: setDefaultWorkflowApi.bind(null, { workflowNanoId, token }),
        actionTypeSuccess: actionType.SET_DEFAULT_WORKFLOW_SUCCESS,
        actionTypeFailure: actionType.SET_DEFAULT_WORKFLOW_FAILURE,
        actionTypeInProgress: actionType.SET_DEFAULT_WORKFLOW_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
};

export const deleteWorkflowAction = ({ workflowNanoId, token }, commonConfig, cb) => {
    return userActionDispatcher({
        serviceMethod: deleteWorkflowApi.bind(null, { workflowNanoId, token }),
        actionTypeSuccess: actionType.DELETE_WORKFLOW_SUCCESS,
        actionTypeFailure: actionType.DELETE_WORKFLOW_FAILURE,
        actionTypeInProgress: actionType.DELETE_WORKFLOW_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
};

export const updateWorkflowThemeLogoAction = (Data, workflowNanoId, commonConfig, token, cb) => {
    return actionDispatcher({
        serviceMethod: updateWorkflowThemeLogoApi.bind(null, Data, workflowNanoId, token),
        actionTypeSuccess: actionType.UPDATE_WORKFLOW_THEME_LOGO_SUCCESS,
        actionTypeFailure: actionType.UPDATE_WORKFLOW_THEME_LOGO_FAILURE,
        actionTypeInProgress: actionType.UPDATE_WORKFLOW_THEME_LOGO_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
};

export const deleteWorkflowThemeLogoAction = ({ workflowNanoId, token }, commonConfig, cb) => {
    return userActionDispatcher({
        serviceMethod: deleteWorkflowThemeLogoApi.bind(null, { workflowNanoId, token }),
        actionTypeSuccess: actionType.DELETE_WORKFLOW_THEME_LOGO_SUCCESS,
        actionTypeFailure: actionType.DELETE_WORKFLOW_THEME_LOGO_FAILURE,
        actionTypeInProgress: actionType.DELETE_WORKFLOW_THEME_LOGO_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });
};

export function setSettingValues(data) {
    return { type: actionType.SET_SETTINGS_VALUES, payload: data };
}

export function clearSettingValues() {
    return { type: actionType.RESET_SETTINGS_VALUES };
}

export function saveThemeValues(data) {
    return { type: actionType.SAVE_THEME_VALUES, payload: data };
}

export function clearThemeValues() {
    return { type: actionType.CLEAR_THEME_VALUES };
}

export function setWorkflowTitleValues(data) {
    return { type: actionType.SET_WORKFLOW_TITLE_VALUES, payload: data };
}

export function clearFetchOneWorkflow() {
    return { type: actionType.CLEAR_FETCH_ONE_WORKFLOW };
}

export function clearWorkflowTitleValues() {
    return { type: actionType.CLEAR_WORKFLOW_TITLE_VALUES };
}

export function setWorkflowThemeLogo(data) {
    return { type: actionType.SET_WORKFLOW_THEME_LOGO, payload: data };
}

export function clearWorkflowThemeLogo() {
    return { type: actionType.CLEAR_WORKFLOW_THEME_LOGO };
}

export function setIsThemeChanged(value) {
    return { type: actionType.IS_THEME_CHANGED, payload: value };
}
