import React from 'react';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import LoadingSpinner from 'components/LoadingSpinner';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';

import classes from './listingsTable.module.css';

import { ReactComponent as DeleteIcon } from 'asset/IconsPack/at_trash-03.svg';

import SelectionInfoRow from './SelectionInfoRow';

const ListingsTable = ({
    totalListingsCount,
    header,
    rows,
    onDelete,
    isFetching,
    showCheckbox = false,
    selectedListings = [],
    isSelectAll,
    handleSelectAll,
    handleToggleItemSelection,
    handleClearSelection,
    onChange,
    isChecked,
    disableSelection,
}) => {
    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {showCheckbox && <TableCell className={classes.tableHeadCell} />}
                        {header.map((item) => (
                            <TableCell key={item.id} className={classes.tableHeadCell}>
                                {item.label}
                            </TableCell>
                        ))}
                        {onDelete && (
                            <TableCell className={classes.tableHeadCell}>Actions</TableCell>
                        )}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {showCheckbox && (
                        <SelectionInfoRow
                            isChecked={isChecked}
                            selectedCount={selectedListings.length}
                            rowsCount={rows.length}
                            totalListingsCount={totalListingsCount}
                            isSelectAll={isSelectAll}
                            onSelectAll={handleSelectAll}
                            onClear={handleClearSelection}
                            onChange={onChange}
                            disableSelection={disableSelection}
                        />
                    )}
                    {rows.map((row, index) => {
                        const isSelected =
                            selectedListings.find((item) => item.id === row.id) || false;
                        const isFirstRow = index === 0;
                        const cellClass =
                            isFirstRow && showCheckbox
                                ? clsx(classes.tableBodyCell, classes.topMostCell)
                                : classes.tableBodyCell;

                        return (
                            <TableRow key={row.id} className={classes.tableBodyRow}>
                                {showCheckbox && (
                                    <TableCell className={cellClass}>
                                        <Tooltip
                                            title={
                                                disableSelection && !isSelected
                                                    ? "You can manually select up to 500 listing's only."
                                                    : ''
                                            }
                                            arrow
                                            placement="right"
                                        >
                                            <span>
                                                <Checkbox
                                                    checked={isSelected}
                                                    onChange={() => handleToggleItemSelection(row)}
                                                    disableRipple
                                                    disableFocusRipple
                                                    classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked,
                                                    }}
                                                    disabled={disableSelection && !isSelected}
                                                />
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                )}
                                {Object.entries(row)
                                    .filter(([key, value]) => key.toLowerCase() !== 'id')
                                    .map(([key, item], index) => (
                                        <TableCell
                                            key={item}
                                            className={
                                                header[index].label === 'Listing Name'
                                                    ? clsx(cellClass, classes.strongFont)
                                                    : cellClass
                                            }
                                        >
                                            {item}
                                        </TableCell>
                                    ))}
                                {onDelete && (
                                    <TableCell className={classes.tableBodyCell}>
                                        <DeleteIcon
                                            className={classes.deleteIcon}
                                            onClick={() => onDelete(row)}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                    {isFetching && (
                        <TableRow className={classes.tableBodyRow}>
                            <TableCell
                                colSpan={showCheckbox ? 4 : 3}
                                className={classes.tableBodyCell}
                            >
                                <LoadingSpinner />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

ListingsTable.propTypes = {
    header: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
        })
    ),
    rows: PropTypes.array,
    onDelete: PropTypes.func,
    isFetching: PropTypes.bool,
    showCheckbox: PropTypes.bool,
    totalListingsCount: PropTypes.number,
    selectedListings: PropTypes.array,
    isSelectAll: PropTypes.bool,
    handleSelectAll: PropTypes.func,
    onChange: PropTypes.func,
    handleToggleItemSelection: PropTypes.func,
    handleClearSelection: PropTypes.func,
    isChecked: PropTypes.bool,
    disableSelection: PropTypes.bool,
};

export default ListingsTable;
