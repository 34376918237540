import apiCall from './apiRequest';
import endPoints from './endpoints';

export function getPaymentMethod(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.getPaymentMethods,
        payload: data,
        authToken,
    });
}

export function getStripeCustomer(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.getStripeCustomer,
        payload: data,
        authToken,
    });
}

export function setupIntent(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.setupIntent,
        payload: data,
        authToken,
    });
}

export function createMethod(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.createMethod,
        payload: data,
        authToken,
    });
}

export function removeCard(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.removeCard,
        payload: data,
        authToken,
    });
}

export function fetchCustomSenderEmail(token, companyUUID) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.settings.customSenderEmail.default,
        authToken: token,
        query: `companyUUID=${companyUUID}`,
    });
}

export function createCustomSenderEmail(token, companyUUID, senderName, email) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.settings.customSenderEmail.default,
        authToken: token,
        payload: {
            companyAccessCode: companyUUID,
            fromName: senderName,
            fromEmail: email,
        },
    });
}

export function deleteCustomSenderEmail(token, companyUUID) {
    return apiCall({
        method: 'delete',
        endpoint: endPoints.settings.customSenderEmail.default,
        authToken: token,
        payload: {
            companyAccessCode: companyUUID,
        },
    });
}

export function submitVerifyUINotification(token, companyUUID, status) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.settings.email,
        authToken: token,
        payload: {
            companyAccessCode: companyUUID,
            emailNotificationStatus: status,
        },
    });
}

export function getAccessCodeAPI(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.accessCode,
        payload: data,
        authToken,
    });
}

export function setWebhookUrlAPI(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.addWebhookUrl,
        payload: data,
        authToken,
    });
}

export function getWebhookUrlAPI(authToken) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.addWebhookUrl,
        authToken,
    });
}

export function updateCustomSenderEmail(token, companyUUID, senderName, email) {
    return apiCall({
        method: 'put',
        endpoint: endPoints.settings.customSenderEmail.default,
        authToken: token,
        payload: {
            companyAccessCode: companyUUID,
            fromName: senderName,
            fromEmail: email,
        },
    });
}

export function getAdminLists(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.getAdmins,
        payload: data,
        authToken,
    });
}

export function createNewMemberApi(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.createNewMember,
        payload: data,
        authToken,
    });
}

export function updateMemberApi(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: `admin/company/admin-account/${data.uac}/update`,
        payload: data,
        authToken,
    });
}

export function resendInvitationMemberApi(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: `admin/company/admin-account/${data.uac}/resend-invite`,
        payload: data,
        authToken,
    });
}

export function reActivateMemberApi(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: `admin/company/admin-account/${data.uac}/re-activate`,
        payload: data,
        authToken,
    });
}

export function changeMemberRoleApi(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: `admin/company/admin-account/${data.uac}/change-role`,
        payload: data,
        authToken,
    });
}

export function suspendMemberApi(data, authToken) {
    return apiCall({
        method: 'post',
        endpoint: `admin/company/admin-account/${data.uac}/suspend`,
        payload: data,
        authToken,
    });
}
