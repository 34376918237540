const USER_DASHBOARD = {
    GENERATED_REPORT_STATUS: {
        REPORT_GENERATED: 'report_generated',
        REPORT_NOT_GENERATED: 'report_not_generated',
    },
    CRIMINAL_RECORD: {
        NO_RECORD_FOUND: 'No Criminal Records Found',
    },

    ID_VERIFICATIONS: {
        SSN_VERIFIED: 'The verification was completed through SSN.',
        GOVT_ID_VERIFIED:
            'The data on the ID or Passport provided was verified in authoritative databases.',
    },
    CUSTOM_DATE_RANGE: {
        INVALID_END_DATE: 'End date should be greater than start date',
        MIN_SEVEN_DAYS_ERR: 'Minimum 7 days needed.',
        INVALID_INPUT_FORMAT: 'Not a valid date.',
    },
};

const ERR_MESSAGES = {
    NO_MVR_REPORT_FOUND: 'No existing MVR Report found.',
    NO_INTEGRATIONS_FOUND: 'No integrations found for this id.',
    ACTION_NOT_ALLOWED_FOR_DECEASED_USER: 'This action is not allowed for a deceased user.',
    DEFAULT_BROWSER_ERROR:
        'Request terminated: Possible causes include network issues, access control restrictions, or the page being unloaded.',
    ZIP_CODE_INVALID: 'zip_code has to be added in the user object for this request.',
};

const CUSTOM_ERR_MESSAGES = {
    RISK_SCORE_ZIP_CODE_REQUIRED: 'Zip Code is required to run this check.',
};

const SESSION_ERR_CODES = ['SESSION_INVALID', 'SESSION_EXPIRED'];

const values = { USER_DASHBOARD };

const OTP_RETRY_LIMIT = 2;

const OTP_RETRY_SECONDS = 60;

const RESET_PASSWORD_SUCCESS_MESSAGE = `Your request has been submitted.
                   If your email is associated with an account you will
                   receive an email with a link to reset your password.`;

const INCORRECT_OTP_MESSAGE = 'You have entered an invalid OTP, please try again.';

const ALREADY_REGISTERED_COMPANY_EMAIL_MESSAGE =
    'This email is already registered. Please login to access your account.';

export default values;
export {
    USER_DASHBOARD,
    ERR_MESSAGES,
    SESSION_ERR_CODES,
    CUSTOM_ERR_MESSAGES,
    OTP_RETRY_LIMIT,
    OTP_RETRY_SECONDS,
    RESET_PASSWORD_SUCCESS_MESSAGE,
    INCORRECT_OTP_MESSAGE,
    ALREADY_REGISTERED_COMPANY_EMAIL_MESSAGE,
};
