import React from 'react';

import { Box, Typography } from '@material-ui/core';
import CustomSelect from 'components/CustomSelect';
import PropTypes from 'prop-types';

import classes from './selectBrandDropdown.module.css';

const SelectBrandDropdown = ({ options, value, setValue }) => {
    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <Box className={classes.selectWrapper}>
            <Typography className={classes.inputLabel}>Select Brand *</Typography>
            <CustomSelect
                name="brand"
                value={value}
                onChange={onChange}
                options={options ?? []}
                placeholder="Brand"
                customStyleClass={classes.customSelect}
                customMenuItemStyleClass={classes.listItem}
            />
        </Box>
    );
};

SelectBrandDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    value: PropTypes.string,
    setValue: PropTypes.func,
};

export default SelectBrandDropdown;
