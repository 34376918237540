import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import {
    Box,
    IconButton,
    Typography,
    Link,
    Divider,
    useTheme,
    useMediaQuery,
    Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import PreviewNavbar from 'components/PreviewNavbar';
import Sidebar from 'components/V2/Sidebar';
import { getAdminDetails, truncateUserName } from 'helper/utility';
import useHubSpotChat from 'hooks/useHubSpotChat';
import PropTypes from 'prop-types';
import PaymentInfoModal from 'views/AddPaymentMethodModal/PaymentInfoModal';

import { fetchAdminInformationAction } from 'redux/actions/auth';

import styles from './dashboard.module.css';

import { ReactComponent as FileIcon } from 'asset/IconsPack/at_file-06.svg';
import { ReactComponent as MenuIcon } from 'asset/IconsPack/at_menu-03-filled.svg';

import UserSettings from './components/UserSettings';

function Dashboard({
    children,
    user,
    adminData,
    mainContainerStyles,
    fetchAdminInformationAction,
    token,
}) {
    const [openSidebar, setOpenSidebar] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const { firstName } = getAdminDetails(user?.company, adminData);

    const truncatedFirstName = truncateUserName(firstName, 24);

    useEffect(() => {
        if (!adminData) {
            fetchAdminInformationAction(token);
        }
    }, [fetchAdminInformationAction, adminData, token]);

    const handleToggler = () => {
        setOpenSidebar(!openSidebar);
    };

    const { openChatBot } = useHubSpotChat();

    const handleHelpClick = () => {
        openChatBot();
    };

    return (
        <>
            <Helmet>
                {!isMobile && (
                    <script
                        type="text/javascript"
                        id="hs-script-loader"
                        async
                        defer
                        src="//js-na1.hs-scripts.com/6431620.js"
                    ></script>
                )}
            </Helmet>
            <Box className={styles.dashboardContainer}>
                <Sidebar
                    isMobile={isMobile}
                    isTablet={isTablet}
                    openSidebar={openSidebar}
                    handleToggler={handleToggler}
                    firstName={truncatedFirstName}
                />
                <Grid className={styles.dashboardInnerContent}>
                    <Box className={styles.navigationContainer}>
                        <Box className={styles.companyInfo}>
                            {isTablet && (
                                <IconButton
                                    className={styles.menuToggleBtn}
                                    onClick={handleToggler}
                                >
                                    <MenuIcon className={styles.menuIcon} />
                                </IconButton>
                            )}
                            <Typography className={styles.companyName}>
                                {truncateUserName(user.company.company_name, 24)}
                            </Typography>
                        </Box>
                        <Box className={styles.userInfoSection}>
                            <Link
                                href="https://docs.authenticate.com/"
                                target="_blank"
                                rel="noreferrer"
                                className={styles.docLink}
                            >
                                <FileIcon className={styles.fileIcon} /> <span>Docs</span>
                            </Link>
                            {!isMobile && (
                                <>
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        className={styles.divider}
                                    />
                                    <UserSettings
                                        firstName={truncatedFirstName}
                                        handleHelpClick={handleHelpClick}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                    <Box className={clsx(mainContainerStyles, styles.renderScreenContainer)}>
                        <Box className={styles.innerScreenContainer}>{children}</Box>
                    </Box>
                </Grid>
            </Box>
            <PaymentInfoModal />
            {!isMobile && <PreviewNavbar />}
        </>
    );
}

Dashboard.propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.node,
    adminData: PropTypes.bool,
    mainContainerStyles: PropTypes.string,
    token: PropTypes.string,
    fetchAdminInformationAction: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        user: state.auth?.userData?.user,
        adminData: state.auth?.adminData,
        token: state.auth?.userData.token,
    };
}

export default connect(mapStateToProps, { fetchAdminInformationAction })(Dashboard);
