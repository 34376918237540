import React from 'react';
import { Handle, Position } from 'reactflow';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from '../flowChart.module.css';

import { ReactComponent as BankIcon } from 'asset/IconsPack/at_bank.svg';
import { ReactComponent as FolderSearchIcon } from 'asset/IconsPack/at_folder-search.svg';
import { ReactComponent as MessageIcon } from 'asset/IconsPack/at_message-question-square.svg';
import { ReactComponent as UserIcon } from 'asset/IconsPack/at_user-square.svg';

const iconMapper = {
    document: UserIcon,
    kba: MessageIcon,
    fao: BankIcon,
    criminal: FolderSearchIcon,
};

const VerificationMethodNode = ({ data }) => {
    const Icon = iconMapper[data.type];
    return (
        <Box className={styles.methodNode}>
            <Box className={styles.methodIconWrapper}>
                <Icon className={styles.methodIcon} />
            </Box>
            <Typography className={styles.methodLabel}>{data.label}</Typography>
            <Handle type="source" position={Position.Bottom} />
            <Handle type="target" position={Position.Top} />
        </Box>
    );
};

VerificationMethodNode.propTypes = {
    data: PropTypes.object,
};

export default VerificationMethodNode;
