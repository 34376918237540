import React, { useState } from 'react';

import Modal from 'components/ModalV2';
import PropTypes from 'prop-types';

import styles from './passCriteriaPreview.module.css';

import PassCriteriaCanvas from './components/PassCriteriaCanvas';

function PassCriteriaPreview({ data }) {
    const [maxScreen, setMaxScreen] = useState(false);

    return (
        <>
            <PassCriteriaCanvas data={data} maxScreen={maxScreen} setMaxScreen={setMaxScreen} />
            <Modal
                open={maxScreen}
                onClose={() => setMaxScreen(false)}
                className={styles.previewModalContainer}
            >
                <PassCriteriaCanvas data={data} maxScreen={maxScreen} setMaxScreen={setMaxScreen} />
            </Modal>
        </>
    );
}

PassCriteriaPreview.propTypes = {
    data: PropTypes.array,
};

export default PassCriteriaPreview;
