import { CircularProgress } from '@material-ui/core';
import * as yup from 'yup';

import { ReactComponent as GithubLogo } from 'asset/IconsPack/at_github_logo.svg';
import { ReactComponent as GitHubFooterLogo } from 'asset/IconsPack/at_github_vector.svg';
import { ReactComponent as LinkedinLogo } from 'asset/IconsPack/at_linkedin_logo.svg';
import { ReactComponent as LinkedinFooterLogo } from 'asset/IconsPack/at_linkedin_vector.svg';
import { ReactComponent as TwitterLogo } from 'asset/IconsPack/at_twitter_logo.svg';
import { ReactComponent as TwitterFooterLogo } from 'asset/IconsPack/at_twitter_vector.svg';
import { ReactComponent as UserIconHat } from 'asset/IconsPack/at_user-02-hat.svg';
import { ReactComponent as UserIconSearch } from 'asset/IconsPack/at_user-03-search.svg';
import { ReactComponent as UserIconHeart } from 'asset/IconsPack/at_user-04-heart.svg';
import { ReactComponent as UserIconTick } from 'asset/IconsPack/at_user-05-tick.svg';
import { ReactComponent as DownloadIcon } from 'asset/IconsPack/hx_download-01.svg';
import AmericanExpress from 'asset/icons/american-express.png';
import DinersClubCard from 'asset/icons/diner_club.png';
import DiscoverCard from 'asset/icons/discover.png';
import JcbCard from 'asset/icons/jcb.png';
import MasterCard from 'asset/icons/master_card.png';
import VisaCard from 'asset/icons/visa.png';
// navigation preview modal GIFs
import GIF_1 from 'asset/img/preview-screen1.gif';
import GIF_2 from 'asset/img/preview-screen2.gif';

const rowPerPage = [
    { label: '10 Entries', value: 10 },
    { label: '20 Entries', value: 20 },
];

const sortByFilters = [
    { name: 'created_at', label: 'Created' },
    { name: 'first_name', label: 'First Name' },
    { name: 'last_name', label: 'Last Name' },
    { name: 'uuid', label: 'User key' },
];

const invertDirection = {
    asc: 'desc',
    desc: 'asc',
};

const apiCallTableheader = [
    {
        name: 'USER',
        value: 'firstName',
    },
    {
        name: 'DOB',
        value: 'dateOfBirth',
    },
    {
        name: 'IDENTITY Verified',
        value: 'identity',
    },
    {
        name: 'Has Criminal Report',
        value: 'criminal',
    },
    {
        name: 'CREATED',
        value: 'created_at',
    },
    {
        name: 'USER ACCESS CODE',
        value: 'userAccessCode',
    },
];

const unauthorizedUserMessage = 'Your company has not been authorized for this action yet.';
const pendingAccountMessage = 'Please wait until the technical staff approves your account.';

const statusCopy = {
    true: 'Passed',
    false: 'Failed',
};

const consentAgreements = [
    {
        id: 1,
        title: 'FCRA',
        name: 'fcra_response',
        content: [
            `Authenticating.com LLC (“Authenticate”) is not a consumer reporting agency and none of its services or the data contained therein constitute a 'consumer report' as such term is defined in the Federal Fair Credit Reporting Act (FCRA), 15 U.S.C. sec. 1681 et seq. The data provided to you may not be used as a factor in consumer debt collection decisioning, establishing a consumer's eligibility for credit, insurance, employment, government benefits, or housing, or for any other purpose authorized under the FCRA. By accessing Authenticate’s services, you agree not to use the service or data for any purpose authorized under the FCRA or in relation to taking an adverse action relating to a consumer application.`,
            `Use of Authenticate’s services is permitted for the sole purpose of preventing or investigating fraud related to consumer transactions or applications. In order to utilize Authenticate’s services every consumer must authorize you to use Authenticate as necessary, to effect, administer, or enforce a transaction the consumer requested or authorized.`,
        ],
    },
    {
        id: 2,
        title: 'GLBA & DPPA',
        name: 'glba_dppa_response',
        content: [
            `Authenticate may return certain data items governed by the Gramm-Leach-Bliley Act, 15 U.S.C. Section 6801 et seq. (“GLBA”) and the Driver’s Privacy Protection Act, 18 U.S.C. Section 2721 et seq., or similar state laws (collectively “DPPA”). You must certify your intended use is permitted by GLBA or DPPA. The only intended use that Authenticate permits is as necessary to effect, administer, or enforce a transaction requested or authorized by the consumer, and to protect against or prevent actual or potential fraud, unauthorized transactions, claims, or other liability, institutional risk control and for resolving consumer disputes or inquiries.`,
        ],
    },
];

const fakeUserInfo = {
    firstName: 'John',
    middleName: 'Smith',
    lastName: 'Doe',
    dob: '01-01-1971',
    ssn: '',
    email: 'johndoe@email.com',
};

const workflowMessages = {
    default: {
        variant: 'default',
        message: 'Default workflow changed',
    },
    delete: {
        variant: 'default',
        message: 'Workflow deleted',
    },
    create: {
        variant: 'default',
        message: 'Workflow created',
    },
    save: {
        variant: 'default',
        message: 'Changes saved',
    },
    discard: {
        variant: 'default',
        message: 'Changes discarded',
    },
    rename: {
        variant: 'default',
        message: 'Workflow renamed',
    },
    idProofError: {
        variant: 'error',
        message: 'You need to select at least one type of Identity Proof.',
    },
    manualReview: {
        variant: 'default',
        message: 'Manual review initiated',
    },
};

const usaStates = [
    { name: 'ALABAMA', abbreviation: 'AL' },
    { name: 'ALASKA', abbreviation: 'AK' },
    { name: 'ARIZONA', abbreviation: 'AZ' },
    { name: 'ARKANSAS', abbreviation: 'AR' },
    { name: 'CALIFORNIA', abbreviation: 'CA' },
    { name: 'COLORADO', abbreviation: 'CO' },
    { name: 'CONNECTICUT', abbreviation: 'CT' },
    { name: 'DELAWARE', abbreviation: 'DE' },
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
    { name: 'FLORIDA', abbreviation: 'FL' },
    { name: 'GEORGIA', abbreviation: 'GA' },
    { name: 'HAWAII', abbreviation: 'HI' },
    { name: 'IDAHO', abbreviation: 'ID' },
    { name: 'ILLINOIS', abbreviation: 'IL' },
    { name: 'INDIANA', abbreviation: 'IN' },
    { name: 'IOWA', abbreviation: 'IA' },
    { name: 'KANSAS', abbreviation: 'KS' },
    { name: 'KENTUCKY', abbreviation: 'KY' },
    { name: 'LOUISIANA', abbreviation: 'LA' },
    { name: 'MAINE', abbreviation: 'ME' },
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
    { name: 'MARYLAND', abbreviation: 'MD' },
    { name: 'MASSACHUSETTS', abbreviation: 'MA' },
    { name: 'MICHIGAN', abbreviation: 'MI' },
    { name: 'MINNESOTA', abbreviation: 'MN' },
    { name: 'MISSISSIPPI', abbreviation: 'MS' },
    { name: 'MISSOURI', abbreviation: 'MO' },
    { name: 'MONTANA', abbreviation: 'MT' },
    { name: 'NEBRASKA', abbreviation: 'NE' },
    { name: 'NEVADA', abbreviation: 'NV' },
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
    { name: 'NEW JERSEY', abbreviation: 'NJ' },
    { name: 'NEW MEXICO', abbreviation: 'NM' },
    { name: 'NEW YORK', abbreviation: 'NY' },
    { name: 'NORTH CAROLINA', abbreviation: 'NC' },
    { name: 'NORTH DAKOTA', abbreviation: 'ND' },
    { name: 'OHIO', abbreviation: 'OH' },
    { name: 'OKLAHOMA', abbreviation: 'OK' },
    { name: 'OREGON', abbreviation: 'OR' },
    { name: 'PALAU', abbreviation: 'PW' },
    { name: 'PENNSYLVANIA', abbreviation: 'PA' },
    { name: 'RHODE ISLAND', abbreviation: 'RI' },
    { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
    { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
    { name: 'TENNESSEE', abbreviation: 'TN' },
    { name: 'TEXAS', abbreviation: 'TX' },
    { name: 'UTAH', abbreviation: 'UT' },
    { name: 'VERMONT', abbreviation: 'VT' },
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
    { name: 'VIRGINIA', abbreviation: 'VA' },
    { name: 'WASHINGTON', abbreviation: 'WA' },
    { name: 'WEST VIRGINIA', abbreviation: 'WV' },
    { name: 'WISCONSIN', abbreviation: 'WI' },
    { name: 'WYOMING', abbreviation: 'WY' },
];

const commonMessages = {
    'low balance': {
        title: 'Account balance low',
        message:
            'Your account balance has fallen below $100. Your account balance auto-replenishes when it falls below $50 but your account will be revoked if your balance falls to $0 before the payment method on file can be re-billed. To avoid your account from being revoked, you will need to top-up your account.',
    },
    'account revoked': {
        title: 'Account revoked',
        message:
            'Your account has been revoked as your balance is $0. To activate your account, you will need to top-up your account with a minimum of $100.',
    },
    'account rejected': {
        title: 'Account rejected',
        message:
            'Your account could not be approved. Please contact support for further information.',
    },
};

const breakPoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
    xxl: 1920,
};

const buttonThemeSet = {
    button_1: { variant: 'contained', borderRadius: '6px' },
    button_2: { variant: 'outlined', borderRadius: '6px' },
    button_3: { variant: 'contained', borderRadius: '15px' },
    button_4: { variant: 'outlined', borderRadius: '15px' },
    button_5: { variant: 'contained', borderRadius: '0px' },
    button_6: { variant: 'outlined', borderRadius: '0px' },
    button_7: { variant: 'contained', borderRadius: '44px' },
    button_8: { variant: 'outlined', borderRadius: '44px' },
};

const userSearchValidationSchema = yup.object().shape({
    firstName: yup
        .string('Enter your First Name')
        .when(['userAccessCode', 'email', 'reservationId'], (uac, email, resId) => {
            if (uac === null && email === null && resId === null) {
                return yup.string().required('Enter your First Name').nullable();
            }
        })
        .nullable(),
    middleName: yup.string('Enter your Middle Name').nullable(),
    lastName: yup
        .string('Enter your Last Name')
        .when(['userAccessCode', 'email', 'reservationId'], (uac, email, resId) => {
            if (uac === null && email === null && resId === null) {
                return yup.string().required('Enter your Last Name').nullable();
            }
        })
        .nullable(),
    dateOfBirth: yup
        .string('Enter your Birth Date')
        .when(['userAccessCode', 'email', 'reservationId'], (uac, email, resId) => {
            if (uac === null && email === null && resId === null) {
                return yup.string().required('Enter your Birth Date').nullable();
            }
        })
        .nullable(),
    userAccessCode: yup.string('Enter User Access Code').nullable(),
    email: yup.string('Enter your Email').email('Enter a valid email').nullable(),
    reservationId: yup.string('Enter reservation Id').nullable(),
    platform: yup
        .string('Select platform ')
        .when('reservationId', (id) => {
            if (id !== null) {
                return yup.string().required('Select a platform').nullable();
            }
        })
        .nullable(),
});

const platformOptions = [
    { label: 'None', value: null },
    { label: 'Guesty', value: 'guesty' },
    { label: 'Hostaway', value: 'hostaway' },
];

const productAnalyticsEvent = {
    register: 'Registration Started',
    comapanyDetails: 'Company Details Entered',
    reviewDetails: 'Application Reviewed',
    survey: 'Survey Viewed',

    USER_PROFILE_ADMIN_ENGAGEMENT: 'USER_PROFILE_ADMIN_ENGAGEMENT',
};

const ADMIN_ENGAGEMENT_TYPES = {
    USER_PROFILE_VISITED: 'USER_PROFILE_VISITED',
    USER_PII_UPDATED: 'USER_PII_UPDATED',
    WORKFLOW_ASSIGNED_TO_USER: 'WORKFLOW_ASSIGNED_TO_USER',
    UAC_COPIED: 'UAC_COPIED',
    MEDALLION_STATUS_CHECKED: 'LINK_EXPIRY_STATUS_CHECKED',
    IDV_STATUS_CHECKED: 'VERIFICATION_STATUS_CHECKED',
    USER_EVENT_TAB_ACCESSED: 'USER_EVENT_TAB_ACCESSED',
    USER_VERIFICATION_TAB_ACCESSED: 'USER_VERIFICATION_TAB_ACCESSED',
};

const voidDocumentStatus = ['document.rejected', 'document.voided', 'document.declined'];

const adminRole = {
    company_owner: 'Owner',
    company_admin: 'Admin',
    viewer: 'Viewer',
};

const adminTypes = {
    COMPANY_OWNER: adminRole.company_owner,
    COMPANY_ADMIN: adminRole.company_admin,
    COMPANY_VIEWER: adminRole.viewer,
};

const adminListTableHeader = [
    { _id: 1, name: 'ACCOUNT', value: 'created_at' },
    { _id: 2, name: 'ROLE', value: 'type' },
    { _id: 3, name: 'STATUS', value: 'verified' },
    { _id: 4, name: 'OPTIONS', value: 'first_name' },
];

const adminListFilters = [
    { name: 'created_at', label: 'Created' },
    { name: 'first_name', label: 'First Name' },
    { name: 'last_name', label: 'Last Name' },
    { name: 'type', label: 'Role' },
    { name: 'verified', label: 'Status' },
];

const adminAccountStatus = {
    ACTIVE: 'active',
    PENDING: 'pending',
    SUSPENDED: 'suspended',
};

// feature flag is added for BAV feature, so we can toggle the feature, more info => https://www.martinfowler.com/articles/feature-toggles.html
const bankFeatureFlag = true;

const passportUncertainStatus = [
    'complete',
    'under_review',
    'parsing_failed',
    'Attention',
    'Failed',
    'Unknown',
];

const consentNameMap = {
    glb_purpose_and_dppa_purpose: 'GLBA and DPPA',
    fcra_purpose: 'FCRA',
    background_check_disclosure_accepted: 'Background check',
};

const guidesPaths = [
    'https://info.authenticate.com/shopify-integration',
    'https://info.authenticate.com/guesty-integration',
    'https://info.authenticate.com/hostaway-integration',
];

const Utils = {
    CHART_COLORS: [
        'rgba(2, 62, 138, 1)',
        'rgba(118, 198, 240, 1)',
        'rgba(188, 108, 37, 1)',
        'rgba(247, 178, 102, 1)',
        'rgba(0, 109, 119, 1)',
        'rgba(131, 197, 190, 1)',
    ],
};

const checksInfoUpdated = {
    'Docs Verified': 'Verified govt. ID',
    'Docs Unverified': 'Unverified govt. ID',
    'Quiz Verified': 'Verified quiz',
    'Quiz Unverified': 'Unverified quiz',
    'Bank Accounts Verified': 'Verified financial account ownership',
    'Bank Accounts Unverified': 'Unverified financial account ownership',
};

const checksInfo = [
    'Docs Verified',
    'Docs Unverified',
    'Quiz Verified',
    'Quiz Unverified',
    'Bank Accounts Verified',
    'Bank Accounts Unverified',
];

const REPORT_STATUS = {
    INITIAL: 'Initial',
    IN_PROGRESS: 'In_progress',
    COMPLETED: 'Completed',
};

const REPORT_BUTTON_TEXTS = {
    [REPORT_STATUS.INITIAL]: 'Generate Report',
    [REPORT_STATUS.IN_PROGRESS]: 'Generating Report',
    [REPORT_STATUS.COMPLETED]: 'Generate Report',
};

const REPORT_MESSAGES = {
    INITIATED: 'Please wait while we generate your report.',
    GENERATED: 'Download Successful.',
    GENERATION_FAILED: 'Report generation failed. Please try after 5 mins.',
    DOWNLOAD_FAILED:
        "Couldn't download the report. Please check your email, we've sent it there too.",
};

const REPORT_ICON_BASED_STATUS = {
    [REPORT_STATUS.INITIAL]: <DownloadIcon className="download_icon" />,
    [REPORT_STATUS.IN_PROGRESS]: <CircularProgress size={15} className="btn_loading" />,
    [REPORT_STATUS.COMPLETED]: <DownloadIcon className="download_icon" />,
};

const userPdfReport = {
    REPORT_STATUS,
    REPORT_BUTTON_TEXTS,
    REPORT_MESSAGES,
    REPORT_ICON_BASED_STATUS,
};

const hideCardStats = ['Quiz Verification', 'Financial Account Ownership Verification'];

const initialGraphData = { statistics: {} };

const KBAResultKeys = {
    numQuestions: 'Number of Questions',
    score: 'Quiz Score',
    status: 'Verification Status',
};

const promotionalTierPrices = [
    { id: 1, price: '$25,000 - 49,999', percentage: '30%' },
    { id: 2, price: '$50,000 - 99,999', percentage: '35%' },
    { id: 3, price: '$100,000 - 299,999', percentage: '40%' },
    { id: 4, price: '$300,000 - 399,999', percentage: '45%' },
    { id: 5, price: '$400,000 - 499,999', percentage: '50%' },
    { id: 6, price: '$500,000 or more', percentage: '55%' },
];

const companyACHInformation = [
    {
        id: 1,
        title: 'Account Name',
        description: 'Authenticating.com LLC',
    },

    {
        id: 2,
        title: 'Bank Name',
        description: 'Bank of America',
    },

    {
        id: 3,
        title: 'Routing Number',
        description: '026009593',
    },

    {
        id: 4,
        title: 'Account Number',
        description: '325110021353',
    },
    {
        id: 5,
        title: 'Company Address',
        description: '730 Arizona Avenue, 230, Santa Monica, CA 90401',
    },
];

const stripeSupportedCardIcons = {
    visa: VisaCard,
    'american express': AmericanExpress,
    mastercard: MasterCard,
    discover: DiscoverCard,
    jcb: JcbCard,
    'diners club': DinersClubCard,
};

// reference: https://docs.stripe.com/error-codes
const STRIPE_ERROR_CODES = {
    CARD_DECLINED: 'card_declined',
    EXPIRED_CARD: 'expired_card',
    INCORRECT_CVC: 'incorrect_cvc',
    INCORRECT_NUMBER: 'incorrect_number',
    INVALID_CVC: 'invalid_cvc',
    INVALID_EXPIRY_MONTH: 'invalid_expiry_month',
    INVALID_EXPIRY_YEAR: 'invalid_expiry_year',
    INVALID_NUMBER: 'invalid_number',
    NO_ACCOUNT: 'no_account',
};

const STRIPE_ERROR_MESSAGES = {
    [STRIPE_ERROR_CODES.CARD_DECLINED]: 'Your card has declined.',
    [STRIPE_ERROR_CODES.EXPIRED_CARD]: 'Your card has expired.',
    [STRIPE_ERROR_CODES.INCORRECT_CVC]: "Your card's security code is incorrect.",
    [STRIPE_ERROR_CODES.INCORRECT_NUMBER]: 'Your card number is incorrect.',
    [STRIPE_ERROR_CODES.INVALID_CVC]: "Your card's security code is invalid.",
    [STRIPE_ERROR_CODES.INVALID_EXPIRY_MONTH]: "Your card's expiration month is incorrect.",
    [STRIPE_ERROR_CODES.INVALID_EXPIRY_YEAR]: "Your card's expiration year is incorrect.",
    [STRIPE_ERROR_CODES.INVALID_NUMBER]: 'Your card number is invalid.',
    [STRIPE_ERROR_CODES.NO_ACCOUNT]: 'The bank account could not be located.',
};

const STRIPE_ERROR = {
    CODES: STRIPE_ERROR_CODES,
    MESSAGES: STRIPE_ERROR_MESSAGES,
};

const CONSENT_MESSAGES = {
    CONSENT_AND_PERMISSIONS:
        'Please certify that you have the following consent and permissions to verify this user',
    SINGLE_BACKGROUND_CHECK: 'Please certify you have the following consent to verify this user',
    SINGLE_FCRA_PURPOSE: 'Please certify you have the following permission to verify this user',
    SINGLE_GLB_DPPA_PURPOSES:
        'Please certify you have the following permissions to verify this user',
    PERMISSION_AND_CONSENT:
        'Please certify you have the following permission and consent to verify this user',
    PERMISSIONS_AND_CONSENT:
        'Please certify you have the following permissions and consent to verify this user',
};

const LOW_CODE_SCRIPT_MAX_LINKS_PER_DAY = 5000;

const LOW_CODE_SDK_MESSAGES = {
    base: 'New low code SDK settings has enabled.',
    update: 'Low code SDK settings has updated.',
    disable: 'Low code SDK settings has disabled.',
    reactivate: 'Low code SDK settings has reactivated.',
};

const licenseClasses = [
    {
        label: 'Class A',
        value: 'A',
    },
    {
        label: 'Class B',
        value: 'B',
    },
    {
        label: 'Class C',
        value: 'C',
    },
    {
        label: 'Class D',
        value: 'D',
    },
    {
        label: 'Class E',
        value: 'E',
    },
    {
        label: 'Class F',
        value: 'F',
    },

    {
        label: 'Class H',
        value: 'H',
    },
    {
        label: 'Class L',
        value: 'L',
    },
    {
        label: 'Class M',
        value: 'M',
    },
    {
        label: 'Class MJ',
        value: 'MJ',
    },
    {
        label: 'Class M1',
        value: 'M1',
    },
    {
        label: 'Class M2',
        value: 'M2',
    },

    {
        label: 'Class N',
        value: 'N',
    },
    {
        label: 'Class O',
        value: 'O',
    },
    {
        label: 'Class P',
        value: 'P',
    },
    {
        label: 'Class R',
        value: 'R',
    },
    {
        label: 'Class S',
        value: 'S',
    },
    {
        label: 'Class T',
        value: 'T',
    },
    {
        label: 'Class TPXS',
        value: 'TPXS',
    },

    {
        label: 'Class X',
        value: 'X',
    },
    {
        label: 'Class 1',
        value: '1',
    },
    {
        label: 'Class 2',
        value: '2',
    },
    {
        label: 'Class 3',
        value: '3',
    },
];

const PASSWORD_POLICY = {
    REGEX: /^(?:(?=.*\d)(?=.*[a-z]).{8,15}|.{15,})$/,
    ERROR_MESSAGE:
        'Make sure it has at least 15 characters OR at least 8 characters including a number and a lowercase letter',
};

// users table

const PAGE_SIZE = [
    { label: '10 Entries', value: 10 },
    { label: '20 Entries', value: 20 },
];

const SORT_OPTIONS = [
    { label: 'Oldest', value: 'oldest' },
    { label: 'Newest', value: 'newest' },
    { label: 'Last Name', value: 'last_name' },
];

const SORT_LABELS_TO_HIGHTLIGHT = {
    newest: 'Newest',
    oldest: 'Oldest',
    last_name: 'Last Name',
};

const USER_TABLE_TOOLTIP_CONTENTS = {
    VERIFICATION_STATUS: `Displays the user's verification progress, Not Started, Attempted, Reinitialized, Failed, Completed, along with the chosen verification method.`,
    IDENTITY_VERIFICATION: `The Identity Verification Status confirms if a user's identity has been validated through an authoritative database check.`,
    CRIMINAL_RECORD:
        'The Criminal record indicates if a user may have been identified with a criminal record.',
    MEDALLION_LINK_EXPIRED: `Medallion™ link has expired.`,
    RE_INITIALIZED: `Medallion™ link has been reinitialized for the user.`,
};

const USERS_TABLE_HEADER = [
    {
        _id: 0,
        name: 'Name',
        value: 'fullName',
        showTooltip: false,
    },
    {
        _id: 1,
        name: 'Date of Birth',
        value: 'dateOfBirth',
        showTooltip: false,
    },
    {
        _id: 2,
        name: 'Verification Status',
        value: 'verificationStatus',
        showTooltip: true,
        tooltipContent: USER_TABLE_TOOLTIP_CONTENTS.VERIFICATION_STATUS,
    },
    {
        _id: 3,
        name: 'Identity Verified',
        value: 'identityVerified',
        showTooltip: true,
        tooltipContent: USER_TABLE_TOOLTIP_CONTENTS.IDENTITY_VERIFICATION,
    },
    {
        _id: 4,
        name: 'Criminal Record',
        value: 'criminalRecord',
        showTooltip: true,
        tooltipContent: USER_TABLE_TOOLTIP_CONTENTS.CRIMINAL_RECORD,
    },
    {
        _id: 5,
        name: 'Created At',
        value: 'createdAt',
        showTooltip: false,
    },
    {
        _id: 6,
        name: 'User Access Code',
        value: 'userAccessCode',
        showTooltip: false,
    },
];

const RANGE_DATE_PICKER_MESSAGES = {
    MAX_LIMIT: `Duration's permissible limit is 2 years.`,
    INVALID_RANGE: 'Invalid date range.',
    FUTURE_DATE: 'Future date selection is not permissible.',
};

const VERIFICATION_TYPES = {
    GOVT_ID: 'govt_id',
    KBA: 'kba',
    BANK: 'bank',
    EEP: 'eep',
    MVR: 'mvr',
    SSN: 'ssn',
};

const VERIFICATION_TYPE_LABELS = {
    [VERIFICATION_TYPES.GOVT_ID]: 'Government ID',
    [VERIFICATION_TYPES.KBA]: 'KBA',
    [VERIFICATION_TYPES.BANK]: 'Financial Account Ownership',
    [VERIFICATION_TYPES.EEP]: 'Employment',
    [VERIFICATION_TYPES.MVR]: 'Motor Vehicle Registration',
    [VERIFICATION_TYPES.SSN]: 'SSN',
};

const VERIFICATION_STATUS_TYPES = {
    NOT_STARTED: 'NOT_STARTED',
    ATTEMPTED: 'ATTEMPTED',
    ERROR: 'ERROR',
    COMPLETED: 'COMPLETED',
    RE_INITIALIZED: 'RE_INITIALIZED',
};

const VERIFICATION_STATUS = {
    [VERIFICATION_STATUS_TYPES.NOT_STARTED]: 'Not Started',
    [VERIFICATION_STATUS_TYPES.ATTEMPTED]: 'Attempted',
    [VERIFICATION_STATUS_TYPES.ERROR]: 'Failed',
    [VERIFICATION_STATUS_TYPES.COMPLETED]: 'Completed',
    [VERIFICATION_STATUS_TYPES.RE_INITIALIZED]: 'Reinitialized',
};

const VERIFICATION_STATUS_COLORS = {
    [VERIFICATION_STATUS_TYPES.ATTEMPTED]: '#175CD3',
    [VERIFICATION_STATUS_TYPES.COMPLETED]: '#027A48',
    [VERIFICATION_STATUS_TYPES.ERROR]: '#B42318',
    [VERIFICATION_STATUS_TYPES.RE_INITIALIZED]: '#5925DC',
    default: '#374151',
};

const USER_TYPES = {
    NO_CODE: 'NO_CODE',
    API: 'API',
};

const MEDALLION_DEFAULT_LINK_EXPIRY_HOURS = 24;

const ID_MATCH_FAILURE_MESSAGES = {
    ID_DATA_NOT_MATCH:
        'We were able to read the document, but the information on the ID does not match the user input. Please make sure the user information matches the information on the ID and try again.',
    PASSPORT_DATA_NOT_MATCH:
        'We were able to read the document, but the information on the Passport does not match the user input. Please make sure the user information matches the information on the Passport and try again.',
};

const KBA_GENERATION_FAILURE_MESSAGES = {
    INCORRECT_USER_PII:
        'There was incorrect or insufficient information provided. Try updating the PII or adding SSN for better results.',
    IDENTITY_NOT_FOUND:
        'Checks for this user are restricted due to uncertainty of true identity. Please update the user object with correct information.',
    NOT_ENOUGH_QUESTION:
        'There were not enough questions available to generate a quiz for this user. Please try verifying the user with another method.',

    NOT_ENOUGH_QUESTION_2:
        'There were not enough questions available to generate a quiz for this user. Please try adding SSN for better results.',
};

const TCM_TOOLTIP_CONTENTS = {
    ID_VERIFIED: `The Identity Verification Status confirms if a user's identity has been validated through an authoritative database check.`,
    CRIMINAL_RECORD: `The Criminal record indicates if a user may have been identified with a criminal record.`,
    UNVERIFIED_USER: `Only verified users can be added to True Continuous Monitoring™`,
    RECORDS_FOUND: `Indicates whether any records were identified for the user during the monitoring period.`,
};

const TCM_TABLE_HEADER = [
    { id: 1, name: 'Name' },
    { id: 2, name: 'Date of Birth' },
    {
        id: 3,
        name: 'Identity Verified',
        tooltip: true,
        tooltipContent: TCM_TOOLTIP_CONTENTS.ID_VERIFIED,
    },
    {
        id: 4,
        name: 'Criminal Record',
        tooltip: true,
        tooltipContent: TCM_TOOLTIP_CONTENTS.CRIMINAL_RECORD,
    },
    { id: 5, name: 'Status' },
    {
        id: 6,
        name: 'Records Found',
        tooltip: true,
        tooltipWidth: '200px',
        tooltipPosition: 'bottom-end',
        tooltipContent: TCM_TOOLTIP_CONTENTS.RECORDS_FOUND,
    },
    { id: 7, name: 'Enrolled On' },
];
const USER_SESSION_EXPIRE_MESSAGE = 'Your session has expired. Please log in.';

export const navigationPreviewCards = {
    1: {
        img: GIF_1,
        heading: '🎉 Simplified Navigation',
        desc: 'With our new navigation feature offering expandable and collapsible menus, you’ll enjoy a clearer workspace. Easily switch between views as needed for a more efficient experience.',
    },
    2: {
        img: GIF_2,
        heading: '🛎️ Chat Support',
        desc: "Looking for the chat support icon at the bottom right corner? We've moved it to the top-right for easier access. Don't worry—new messages will still pop up at the bottom right as usual.",
    },
};

const RISK_PROFILES = {
    LOW: 'Low',
    MODERATE: 'Moderate',
    HIGH: 'High',
};

const RISK_PROFILE_COLORS = {
    [RISK_PROFILES.LOW]: 'green',
    [RISK_PROFILES.MODERATE]: 'yellow',
    [RISK_PROFILES.HIGH]: 'red',
};

const RISK_SCORE_NUDGE_STATUS = {
    [RISK_PROFILES.LOW]: 'success',
    [RISK_PROFILES.MODERATE]: 'warning',
    [RISK_PROFILES.HIGH]: 'cancel',
};

const RISK_SCORE_RING_COLOR = {
    [RISK_PROFILES.LOW]: '#12B76A',
    [RISK_PROFILES.MODERATE]: '#F79009',
    [RISK_PROFILES.HIGH]: '#F04438',
};

const RISK_SCORE_PROFILE_LABEL_COLOR = {
    [RISK_PROFILES.LOW]: '#D7F7D5',
    [RISK_PROFILES.MODERATE]: '#fffaeb',
    [RISK_PROFILES.HIGH]: '#FFD1D0',
};

const RUN_RISK_SCORE_NUDGE_DESCRIPTION =
    'Risk Score shows if user data matches our identity graph, providing a risk percentage based on the match or mismatch.';

const RUN_RISK_SCORE_TOOLTIP =
    'User insights from Risk Score involve understanding risk levels, engagement across channels, behavioral patterns, and user segmentation based on activity scores. Higher confidence scores typically indicate lower risk, aiding organizations in making informed decisions.';

const RUN_RISK_INSIGHT_TOOLTIP =
    "Risk Insights provides input data insights into how confident we are about the given person's identity, inputs, and risk level. Risk Insights are highly specific and provide high-value information. Our identity graph spans both the digital and terrestrial ecosystems and provides linkages between how individuals represent themselves professionally and personally.";

const RISK_SCORE_MATCH_CATEGORY = {
    TANGLED: 'tangled',
    UNKNOWN: 'unknown',
    HOUSEHOLD: 'household',
    SELF: 'self',
};

const RISK_SCORE_MATCH_NUDGE_ICONS = {
    [RISK_SCORE_MATCH_CATEGORY.TANGLED]: <UserIconHat />,
    [RISK_SCORE_MATCH_CATEGORY.UNKNOWN]: <UserIconSearch />,
    [RISK_SCORE_MATCH_CATEGORY.HOUSEHOLD]: <UserIconHeart />,
    [RISK_SCORE_MATCH_CATEGORY.SELF]: <UserIconTick />,
};

const RISK_SCORE_MATCH_COLORS = {
    [RISK_SCORE_MATCH_CATEGORY.TANGLED]: '#b66200',
    [RISK_SCORE_MATCH_CATEGORY.UNKNOWN]: '#6D6D6D',
    [RISK_SCORE_MATCH_CATEGORY.HOUSEHOLD]: '#0053B6',
    [RISK_SCORE_MATCH_CATEGORY.SELF]: '#00B65B',
};

const RISK_SCORE_MATCH_NUDGE_TOOLTIP = {
    [RISK_SCORE_MATCH_CATEGORY.TANGLED]:
        'Increased fraud risk; recommended to add extra security checks.',
    [RISK_SCORE_MATCH_CATEGORY.UNKNOWN]: `There isn't enough information to classify. This may indicate a fraud risk.`,
    [RISK_SCORE_MATCH_CATEGORY.HOUSEHOLD]:
        'Input matches another individual in the same household; not indicating fraud risk.',
    [RISK_SCORE_MATCH_CATEGORY.SELF]: 'The input matches the most likely requested person.',
};

const RISK_INSIGHT_SOCIAL_PROFILE_TYPE = {
    TWITTER: 'Twitter',
    LINKEDIN: 'Linkedin',
    GITHUB: 'Github',
};

const RISK_INSIGHT_SOCIAL_PROFILE_LOGO = {
    [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.GITHUB]: <GithubLogo />,
    [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.LINKEDIN]: <LinkedinLogo />,
    [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.TWITTER]: <TwitterLogo />,
};

const RISK_INSIGHT_SOCIAL_PROFILE_FOOTER_LOGO = {
    [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.GITHUB]: <GitHubFooterLogo />,
    [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.LINKEDIN]: <LinkedinFooterLogo />,
    [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.TWITTER]: <TwitterFooterLogo />,
};

const RISK_INSIGHT_SOCIAL_TYPE_URL_MAP = {
    twitterUrl: [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.TWITTER],
    linkedInUrl: [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.LINKEDIN],
    githubUrl: [RISK_INSIGHT_SOCIAL_PROFILE_TYPE.GITHUB],
};

const RISK_INSIGHT_MOBILE_HEADERS = {
    EMAIL: 'email',
    PHONE: 'phone',
    ADDITIONAL_DATA: 'additional emails',
    EMPLOYMENT_SOCIAL_PROFILES: 'employment & social',
};

const RISK_SCORE_INITIATE_METHODS = {
    USER_CREATION: 'USER_CREATION',
    MAIN_NUDGE: 'MAIN_NUDGE',
    INSIGHTS_TAB: 'INSIGHTS_TAB',
};

const RISK_SCORE_DOC_LINK = 'https://info.authenticate.com/score-inputs';

const RISK_INSIGHTS_DOC_LINK = 'https://info.authenticate.com/verify-inputs';

const USER_RISK_SCORE = {
    PROFILES: RISK_PROFILES,
    COLORS: RISK_PROFILE_COLORS,
    NUDGE_STATUS: RISK_SCORE_NUDGE_STATUS,
    RUN_RISK_SCORE_DESC: RUN_RISK_SCORE_NUDGE_DESCRIPTION,
    RUN_RISK_INSIGHT_TOOLTIP: RUN_RISK_INSIGHT_TOOLTIP,
    RUN_RISK_SCORE_TOOLTIP: RUN_RISK_SCORE_TOOLTIP,
    RISK_SCORE_RING_COLOR: RISK_SCORE_RING_COLOR,
    RISK_SCORE_PROFILE_LABEL_COLOR: RISK_SCORE_PROFILE_LABEL_COLOR,
    RISK_SCORE_MATCH: {
        CATEGORY: RISK_SCORE_MATCH_CATEGORY,
        COLORS: RISK_SCORE_MATCH_COLORS,
        USER_ICONS: RISK_SCORE_MATCH_NUDGE_ICONS,
        TOOLTIPS: RISK_SCORE_MATCH_NUDGE_TOOLTIP,
    },
    RISK_SCORE_DOC_LINK: RISK_SCORE_DOC_LINK,
    INITIATE_METHODS: RISK_SCORE_INITIATE_METHODS,
};

const USER_RISK_INSIGHT = {
    SOCIAL_PROFILE: {
        TYPE: RISK_INSIGHT_SOCIAL_PROFILE_TYPE,
        LOGO: RISK_INSIGHT_SOCIAL_PROFILE_LOGO,
        FOOTER_LOGO: RISK_INSIGHT_SOCIAL_PROFILE_FOOTER_LOGO,
        URL_MAP: RISK_INSIGHT_SOCIAL_TYPE_URL_MAP,
    },
    MOBILE_HEADERS: RISK_INSIGHT_MOBILE_HEADERS,
    RISK_INSIGHTS_DOC_LINK: RISK_INSIGHTS_DOC_LINK,
    RISK_INSIGHT_FORM_TOOLTIPS: {
        EMAIL: 'Add additional email addresses if available. This helps improve data matching and ensures more accurate results',
        PHONE: 'Add additional phone numbers if available. This helps improve data matching and ensures more accurate results.',
    },
};

const USER_PROFILE_ACTIVE_MODAL_TYPE = {
    RISK_INSIGHT_EMPLOYMENT_FORM: 'RISK_INSIGHT_EMPLOYMENT_FORM',
};

export {
    rowPerPage,
    sortByFilters,
    invertDirection,
    apiCallTableheader,
    unauthorizedUserMessage,
    pendingAccountMessage,
    statusCopy,
    consentAgreements,
    fakeUserInfo,
    workflowMessages,
    commonMessages,
    breakPoints,
    usaStates,
    buttonThemeSet,
    userSearchValidationSchema,
    platformOptions,
    productAnalyticsEvent,
    voidDocumentStatus,
    adminRole,
    adminListTableHeader,
    adminListFilters,
    adminTypes,
    adminAccountStatus,
    bankFeatureFlag,
    passportUncertainStatus,
    consentNameMap,
    guidesPaths,
    userPdfReport,
    Utils,
    checksInfo,
    checksInfoUpdated,
    initialGraphData,
    hideCardStats,
    KBAResultKeys,
    promotionalTierPrices,
    companyACHInformation,
    stripeSupportedCardIcons,
    STRIPE_ERROR,
    CONSENT_MESSAGES,
    LOW_CODE_SCRIPT_MAX_LINKS_PER_DAY,
    LOW_CODE_SDK_MESSAGES,
    licenseClasses,
    PASSWORD_POLICY,
    PAGE_SIZE,
    SORT_OPTIONS,
    SORT_LABELS_TO_HIGHTLIGHT,
    USER_TABLE_TOOLTIP_CONTENTS,
    USERS_TABLE_HEADER,
    RANGE_DATE_PICKER_MESSAGES,
    VERIFICATION_TYPES,
    VERIFICATION_TYPE_LABELS,
    VERIFICATION_STATUS_TYPES,
    VERIFICATION_STATUS,
    USER_TYPES,
    VERIFICATION_STATUS_COLORS,
    MEDALLION_DEFAULT_LINK_EXPIRY_HOURS,
    KBA_GENERATION_FAILURE_MESSAGES,
    ADMIN_ENGAGEMENT_TYPES,
    TCM_TOOLTIP_CONTENTS,
    TCM_TABLE_HEADER,
    USER_SESSION_EXPIRE_MESSAGE,
    USER_RISK_SCORE,
    USER_RISK_INSIGHT,
    USER_PROFILE_ACTIVE_MODAL_TYPE,
    ID_MATCH_FAILURE_MESSAGES,
};
