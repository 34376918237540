import { TextField, Grid } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useColorChooserStyle } from '../../../styles/themeEditorStyles/colorChooser';

let evtTimer;

function ColorChooser(props) {
    const {
        label,
        name,
        value,
        onChange,
        variant = 'outlined',
        defaultValue,
        title,
        setIsHovered,
    } = props;
    const classes = useColorChooserStyle();
    const inputRef = useRef();

    useEffect(() => {
        if (!value) {
            onChange && onChange({ name, value: defaultValue });
        }
    });

    const timer = name === 'secondaryColor' ? 500 : 50;

    const handleChange = (event) => {
        if (name === 'secondaryColor') {
            setIsHovered(true);
        }
        clearTimeout(evtTimer);
        const { value } = event.target;
        evtTimer = setTimeout(() => {
            onChange && onChange({ name, value });
            setIsHovered(false);
        }, timer);
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={6}>
                {title}
            </Grid>
            <Grid item xs={6}>
                <div
                    className={classes.container}
                    onClick={() => {
                        inputRef.current.click();
                    }}
                >
                    <div
                        className={classes.colorPreviewBox}
                        style={{ backgroundColor: value }}
                    ></div>
                    <input
                        ref={inputRef}
                        type="color"
                        style={{ visibility: 'hidden', position: 'absolute' }}
                        value={value}
                        onChange={(event) => handleChange(event)}
                    />
                    <TextField
                        fullWidth
                        label={label}
                        variant={variant}
                        value={value}
                        // inputProps={{ className: classes.textField }}
                        InputProps={{
                            classes: {
                                root: classes.textField,
                            },
                        }}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default ColorChooser;
