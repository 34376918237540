import { useState, useRef, useCallback, useEffect } from 'react';

import useDebounce from 'hooks/useDebounce';

import { useLazyGetBrandListingsQuery } from 'redux/slices/globalApi';

const useBrandListings = (brandUuid) => {
    const [triggerGetListingsApi, { data, isFetching }] = useLazyGetBrandListingsQuery();

    const [listings, setListings] = useState([]);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearchTerm = useDebounce(searchTerm, 400);

    const listingRef = useRef(null);

    const getBrandListings = useCallback(
        async (value) => {
            try {
                if (brandUuid) {
                    const query = {
                        brandUuid,
                        page,
                        filter: value,
                    };
                    await triggerGetListingsApi(query).unwrap();
                }
            } catch (err) {}
        },
        [page, brandUuid, triggerGetListingsApi]
    );

    useEffect(() => {
        (async () => await getBrandListings(debouncedSearchTerm))();
    }, [debouncedSearchTerm, page, getBrandListings]);

    useEffect(() => {
        if (data) {
            setListings((prevListings) =>
                page === 1 ? data.listings : [...prevListings, ...data.listings]
            );
        }
    }, [data, page]);

    const handleScroll = () => {
        const clientHeight = listingRef?.current?.clientHeight;
        const scrollTop = listingRef?.current?.scrollTop;
        const scrollHeight = listingRef?.current?.scrollHeight;

        const reachedEnd = clientHeight + scrollTop >= scrollHeight - 5;

        if (reachedEnd && !isFetching && data?.listings?.length > 0) {
            setPage((prev) => prev + 1);
        }
    };

    const onChange = (e) => {
        setSearchTerm((prevValue) => {
            //this is to reset the page if it was changed on scroll
            if (!prevValue || prevValue !== e.target.value) {
                setPage(1);
            }
            return e.target.value;
        });
    };

    return {
        searchTerm,
        listingRef,
        listings,
        handleScroll,
        onChange,
        totalCount: data?.total,
        isFetching,
    };
};

export default useBrandListings;
