import actionType from 'redux/actions/actionConstant';

import { USER_DASHBOARD } from '../../constants';

const initialObject = {
    loaded: false,
    data: null,
};

const initialState = {
    bearerToken: initialObject,
    resultData: initialObject,
    // seven year criminal data
    criminalData: undefined,
    userToken: undefined,
    latestToken: {
        isLoading: false,
        token: null,
    }, // stores the config of the latest generated token
    // criminal report
    criminalReport: undefined,
    fetchCriminalData: initialObject,
    getUser: initialObject,
    updateUser: undefined,
    verifyUploadId: undefined,
    verifyUploadPassport: undefined,

    workflowOverview: undefined,
    getTestCount: 0,
    workflowStatusCount: 0,
    resumeWorkflowCount: 0,
    extendedChecksStatus: undefined,

    userDataUpdated: false,
    eventLogs: {},
    mvrDetails: undefined,

    riskScoreAndInsight: undefined,

    userMetaData: undefined,

    //Represents the modal type triggered dynamically from outside the feature,
    //currently used to initiate the employment form from the risk insight section.
    activeModalType: undefined,
};

const statusToReportMap = {
    200: USER_DASHBOARD.GENERATED_REPORT_STATUS.REPORT_GENERATED,
    204: USER_DASHBOARD.GENERATED_REPORT_STATUS.REPORT_NOT_GENERATED,
};

export default function dashboard(state = initialState, action) {
    const countActionTypes = [
        actionType.VERIFY_UPLOAD_ID_FAILURE,
        actionType.VERIFY_UPLOAD_PASSPORT_FAILURE,
    ];

    switch (action.type) {
        case actionType.GET_BEARER_TOKEN_SUCCESS:
            return {
                ...state,
                bearerToken: {
                    loaded: true,
                    data: action.payload,
                },
            };

        case actionType.CLEAR_BEARER_TOKEN:
            return {
                ...state,
                bearerToken: {
                    loaded: false,
                    data: null,
                },
            };

        case actionType.GET_TEST_RESULT_SUCCESS:
            return {
                ...state,
                resultData: {
                    loaded: true,
                    data: action.payload,
                },
            };

        case actionType.CLEAR_TEST_RESULT:
            return {
                ...state,
                resultData: {
                    loaded: false,
                    data: null,
                },
            };

        case actionType.SEVEN_YEAR_DATA_SUCCESS:
            return {
                ...state,
                criminalData: action.payload,
            };

        case actionType.GET_JWT_TOKEN_SUCCESS:
            return {
                ...state,
                userToken: {
                    loaded: true,
                    data: action.payload,
                },
            };

        case actionType.GET_JWT_LATEST_SUCCESS:
            return {
                ...state,
                latestToken: {
                    isLoading: false,
                    apiCalled: true,
                    token: action.payload?.token,
                    expiresOn: action.payload?.expiresOn,
                    verificationOptions: action.payload?.verificationOptions,
                    customVerifications: action.payload?.customVerifications,
                    preferredWorkflow: action.payload?.preferredWorkflow,
                },
            };
        case actionType.GET_JWT_LATEST_REQUEST:
        case actionType.GET_JWT_LATEST_FAILURE:
            let { isLoading: ignoreIsLoading, ...rest } = state.latestToken ?? {};
            return {
                ...state,
                latestToken: {
                    isLoading: action.type === actionType.GET_JWT_LATEST_REQUEST,
                    ...rest,
                },
            };

        case actionType.CLEAR_JWT_TOKEN:
            return {
                ...state,
                userToken: {
                    loaded: false,
                    data: null,
                },
                latestToken: undefined,
            };

        case actionType.GET_CRIMINAL_REPORT_SUCCESS:
            return {
                ...state,
                criminalReport: action.payload,
            };

        case actionType.FETCH_SEVEN_YEAR_DATA_FAILURE:
            return {
                ...state,
                fetchCriminalData: {
                    loaded: true,
                    data: null,
                    isReportGenerated: statusToReportMap[action.status],
                },
            };

        case actionType.FETCH_SEVEN_YEAR_DATA_SUCCESS:
            return {
                ...state,
                fetchCriminalData: {
                    loaded: true,
                    data: action.payload,
                    isReportGenerated: statusToReportMap[action.status],
                },
            };

        case actionType.CLEAR_SEVEN_YEAR_DATA:
            return {
                ...state,
                fetchCriminalData: {
                    loaded: false,
                    data: null,
                },
                criminalData: undefined,
            };

        case actionType.GET_USER_DATA_SUCCESS:
            return {
                ...state,
                getUser: {
                    loaded: true,
                    data: action.payload,
                },
            };

        case actionType.CLEAR_USER_DATA:
            return {
                ...state,
                getUser: {
                    loaded: false,
                    data: null,
                },
            };

        case actionType.UPDATE_USER_DATA_SUCCESS:
            return {
                ...state,
                updateUser: action.payload,
            };

        case actionType.VERIFY_UPLOAD_ID_SUCCESS:
            return {
                ...state,
                verifyUploadId: action.payload,
            };

        case actionType.VERIFY_UPLOAD_PASSPORT_SUCCESS:
            return {
                ...state,
                verifyUploadPassport: action.payload,
            };
        case actionType.WORKFLOW_OVERVIEW_SUCCESS:
            return {
                ...state,
                workflowOverview: action.payload,
            };
        case actionType.CLEAR_WORKFLOW_OVERVIEW:
            return {
                ...state,
                workflowOverview: undefined,
            };

        case actionType.RESUME_WORKFLOW_OVERVIEW_SUCCESS:
            return {
                ...state,
                workflowOverview: {
                    ...state.workflowOverview,
                    workflow_status: action.payload.status,
                },
            };

        case actionType.CLEAR_GET_TEST_COUNT:
            return {
                ...state,
                getTestCount: 0,
            };

        case actionType.CLEAR_WORKFLOW_STATUS_COUNT:
            return {
                ...state,
                workflowStatusCount: 0,
            };
        case actionType.INCREASE_GET_TEST_COUNT:
            return {
                ...state,
                getTestCount: state.getTestCount + 1,
            };

        case actionType.INCREASE_WORKFLOW_STATUS_COUNT:
            return {
                ...state,
                workflowStatusCount: state.workflowStatusCount + 1,
            };

        case actionType.CLEAR_RESUME_WORKFLOW_COUNT:
            return {
                ...state,
                resumeWorkflowCount: 0,
            };

        case actionType.EXTENDED_CHECKS_STATUS_SUCCESS:
            return {
                ...state,
                extendedChecksStatus: action.payload,
            };

        case actionType.RESET_EXTENDED_CHECKS_STATUS:
            return {
                ...state,
                extendedChecksStatus: undefined,
            };

        case actionType.USER_DATA_UPDATED:
            return {
                ...state,
                userDataUpdated: action.payload,
            };

        case actionType.LOGS_VERIFICATION_SUCCESS:
            return {
                ...state,
                eventLogs: action.payload,
            };
        case actionType.FETCH_MVR_SUCCESS:
            return {
                ...state,
                mvrDetails: action.payload,
            };
        case actionType.FETCH_MVR_FAILURE:
            return {
                ...state,
                mvrDetails: undefined,
            };
        case actionType.FETCH_RISK_SCORE_AND_INSIGHT_SUCCESS:
            return {
                ...state,
                riskScoreAndInsight: {
                    score: action.payload?.result?.score,
                    insights: action.payload?.result?.insights,
                },
            };

        case actionType.CLEAR_USER_RISK_SCORE_AND_INSIGHT:
            return {
                ...state,
                riskScoreAndInsight: undefined,
            };

        case actionType.USER_PROFILE_ACTIVE_MODAL_TYPE:
            return {
                ...state,
                activeModalType: action.payload,
            };

        case actionType.CLEAR_USER_PROFILE_ACTIVE_MODAL_TYPE:
            return {
                ...state,
                activeModalType: undefined,
            };

        case actionType.FETCH_USER_METADATA_SUCCESS:
            return {
                ...state,
                userMetaData: action.payload,
            };

        case actionType.CLEAR_USER_METADATA:
            return {
                ...state,
                userMetaData: undefined,
            };

        case action.type:
            if (countActionTypes.includes(action.type)) {
                return {
                    ...state,
                    resumeWorkflowCount: state.resumeWorkflowCount + 1,
                };
            }
            return state;

        default:
            return state;
    }
}
