import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, Divider, Hidden } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'asset/IconsPack/hx_x-close.svg';
import styles from './formComponents.module.css';

function FormHeader({ title, handleClose, children }) {
    return (
        <>
            <Box className={styles.commonFormHeader}>
                <Box className={styles.mobileFormHeading}>
                    <Hidden smUp>
                        <IconButton onClick={handleClose} className={styles.modalCloseBtnMobile}>
                            <CloseIcon className={styles.modalCloseIcon} />
                        </IconButton>
                    </Hidden>
                    <Box>
                        <Typography className={styles.commonFormHeading}>{title}</Typography>
                        {children}
                    </Box>
                </Box>

                <Hidden xsDown>
                    <IconButton onClick={handleClose} className={styles.modalCloseBtn}>
                        <CloseIcon className={styles.modalCloseIcon} />
                    </IconButton>
                </Hidden>
            </Box>
            <Divider className={styles.divider} />
        </>
    );
}

FormHeader.propTypes = {
    title: PropTypes.string,
    handleClose: PropTypes.func,
    children: PropTypes.node,
};

export default FormHeader;
