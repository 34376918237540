import React from 'react';
import './Loader.scss';
import loader from 'asset/img/loader.png';
import { connect } from 'react-redux';

const Loader = (props) => {
    if (props.loader) {
        return (
            <div className="loaderPageColor">
                <div className="loaderContainer">
                    <div className="loaderAlignCenter">
                        <img className="loaderLogoImg" width="40%" src={loader} alt={'Loader'} />
                        <div className="loaderText">LOADING...</div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default connect(mapStateToProps, {})(Loader);

function mapStateToProps(state) {
    return {
        loader: state.loader.showLoader,
    };
}
