import actionType from 'redux/actions/actionConstant';

const initialState = {
    searchData: undefined,
    adminJwtToken: undefined,
    sendLinkViaEmail: undefined,
    cacheFilterValues: undefined,
};

export default function search(state = initialState, action) {
    switch (action.type) {
        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                searchData: undefined,
                adminJwtToken: undefined,
                sendLinkViaEmail: undefined,
                cacheFilterValues: undefined,
            };

        default:
            return state;
    }
}
