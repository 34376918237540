const fontStyles = {
    fontFamily: 'Rubik, sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'inherit',
};

const chipStyles = (theme) => ({
    default: {
        padding: '2px 8px',
        borderRadius: '16px',
        background: '#F2F4F7',
        color: '#344054',
        height: 'auto',

        '& .MuiChip-label': {
            padding: '0',
            fontFamily: 'Rubik, sans-serif',
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: '500',
        },
    },

    md: {
        padding: '2px 10px',

        '& .MuiChip-label': {
            ...fontStyles,
        },
    },

    lg: {
        padding: '4px 12px',

        '& .MuiChip-label': {
            ...fontStyles,
        },
    },
});

export default chipStyles;
