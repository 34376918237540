import actionDispatcher from 'api/actionDispatcher';
import actionType from './actionConstant';
import {
    activeApi,
    settingApi,
    settingGetApi,
    settingPostApi,
    getProductsAPI,
    getEnabledProductsAPI,
    setEnabledProductsAPI,
    getPaymentStatusApi,
    setPaymentStatusApi,
} from 'api/integration';

export const activeAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: activeApi.bind(null, Data, token),
        actionTypeSuccess: actionType.ACTIVE_SUCCESS,
        actionTypeFailure: actionType.ACTIVE_FAILURE,
        actionTypeInProgress: actionType.ACTIVE_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

//to get all integration keys
export const settingAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: settingApi.bind(null, Data, token),
        actionTypeSuccess: actionType.SETTING_SUCCESS,
        actionTypeFailure: actionType.SETTING_FAILURE,
        actionTypeInProgress: actionType.SETTING_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

//to get all integration settings saved values
export const settingGetAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: settingGetApi.bind(null, Data, token),
        actionTypeSuccess: actionType.SETTING_GET_SUCCESS,
        actionTypeFailure: actionType.SETTING_GET_FAILURE,
        actionTypeInProgress: actionType.SETTING_GET_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

//post new changes in integration settings
export const settingPostAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: settingPostApi.bind(null, Data, token),
        actionTypeSuccess: actionType.SETTING_POST_SUCCESS,
        actionTypeFailure: actionType.SETTING_POST_FAILURE,
        actionTypeInProgress: actionType.SETTING_POST_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const getProductsAction = (Data, commonConfig, token, extra = null, cb) =>
    actionDispatcher({
        serviceMethod: getProductsAPI.bind(null, Data, token),
        actionTypeSuccess: actionType.GET_PRODUCTS_SUCCESS,
        actionTypeFailure: actionType.GET_PRODUCTS_FAILURE,
        actionTypeInProgress: actionType.GET_PRODUCTS_REQUEST,
        extra: extra,
        commonConfig,
        callback: cb,
    });

export const getPrevProductsAction = (Data, commonConfig, token, extra = null, cb) =>
    actionDispatcher({
        serviceMethod: getProductsAPI.bind(null, Data, token),
        actionTypeSuccess: actionType.GET_PREV_PRODUCTS_SUCCESS,
        actionTypeFailure: actionType.GET_PREV_PRODUCTS_FAILURE,
        actionTypeInProgress: actionType.GET_PREV_PRODUCTS_REQUEST,
        extra: extra,
        commonConfig,
        callback: cb,
    });
export const getNextProductsAction = (Data, commonConfig, token, extra = null, cb) =>
    actionDispatcher({
        serviceMethod: getProductsAPI.bind(null, Data, token),
        actionTypeSuccess: actionType.GET_NEXT_PRODUCTS_SUCCESS,
        actionTypeFailure: actionType.GET_NEXT_PRODUCTS_FAILURE,
        actionTypeInProgress: actionType.GET_NEXT_PRODUCTS_REQUEST,
        extra: extra,
        commonConfig,
        callback: cb,
    });

export const getEnabledProductsAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: getEnabledProductsAPI.bind(null, Data, token),
        actionTypeSuccess: actionType.GET_ENABLED_PRODUCTS_SUCCESS,
        actionTypeFailure: actionType.GET_ENABLED_PRODUCTS_FAILURE,
        actionTypeInProgress: actionType.GET_ENABLED_PRODUCTS_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const setEnabledProductsAction = (Data, commonConfig, token, cb) =>
    actionDispatcher({
        serviceMethod: setEnabledProductsAPI.bind(null, Data, token),
        actionTypeSuccess: actionType.SET_ENABLED_PRODUCTS_SUCCESS,
        actionTypeFailure: actionType.SET_ENABLED_PRODUCTS_FAILURE,
        actionTypeInProgress: actionType.SET_ENABLED_PRODUCTS_REQUEST,
        extra: null,
        commonConfig,
        callback: cb,
    });

export const clearNextCursorAction = () => {
    return {
        type: actionType.CLEAR_NEXT_CURSOR,
    };
};

export const resetProductScreenAction = () => {
    return {
        type: actionType.RESET_PRODUCTS_SCREEN,
    };
};

export const getPaymentStatusAction = (
    token,
    companyAccessCode,
    commonConfig,
    callback = () => {}
) => {
    return actionDispatcher({
        serviceMethod: getPaymentStatusApi.bind(null, token, companyAccessCode),
        actionTypeSuccess: actionType.RUTTER.PAYMENTS.STATUS.SUCCESS,
        actionTypeFailure: actionType.RUTTER.PAYMENTS.STATUS.FAILURE,
        actionTypeInProgress: actionType.RUTTER.PAYMENTS.STATUS.INPROGRESS,
        extra: null,
        commonConfig,
        callback,
    });
};

export const setPaymentStatusAction = (
    token,
    payload = { companyAccessCode: null, paymentUUID: null },
    commonConfig,
    callback = () => {}
) => {
    return actionDispatcher({
        serviceMethod: setPaymentStatusApi.bind(null, token, payload),
        actionTypeSuccess: actionType.RUTTER.PAYMENTS.CONFIRM.SUCCESS,
        actionTypeFailure: actionType.RUTTER.PAYMENTS.CONFIRM.FAILURE,
        actionTypeInProgress: actionType.RUTTER.PAYMENTS.CONFIRM.INPROGRESS,
        extra: null,
        commonConfig,
        callback,
    });
};
