import React from 'react';

import { Box, Typography } from '@material-ui/core';

import classes from './noResultFound.module.css';

import { ReactComponent as FileCrossIcon } from 'asset/IconsPack/at_file-x-01.svg';

const NoResultFound = () => {
    return (
        <Box className={classes.container}>
            <FileCrossIcon className={classes.icon} />
            <Typography className={classes.text}>No Results found</Typography>
        </Box>
    );
};

export default NoResultFound;
