import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ListingsModal from 'views/V2/Integration/components/ListingsModal';
import ListingsTable from 'views/V2/Integration/components/ListingsTable';

import classes from './confirmMoveListings.module.css';

const ConfirmMoveListings = ({ data, open, onClose, onConfirm, onCancel, onDeleteAction }) => {
    const { header, rows, brandName } = data;
    return (
        <ListingsModal open={open} onClose={onClose} className={classes.container}>
            <ListingsModal.Header title="Confirmation" onClose={onClose} />
            <ListingsModal.Content>
                <Box className={classes.contentWrapper}>
                    <Typography className={classes.text}>
                        Are you sure you want to move the below{' '}
                        {rows?.length > 1 ? 'listings' : 'listing'} to {brandName}?
                    </Typography>
                    <ListingsTable header={header} rows={rows} onDelete={onDeleteAction} />
                </Box>
            </ListingsModal.Content>
            <ListingsModal.Footer
                primaryBtnProps={{
                    title: 'Confirm',
                    onClick: onConfirm,
                }}
                secondaryBtnProps={{
                    title: 'Cancel',
                    onClick: onCancel,
                }}
            />
        </ListingsModal>
    );
};

ConfirmMoveListings.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onDeleteAction: PropTypes.func,
};
export default ConfirmMoveListings;
