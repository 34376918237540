import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeIntegrations: null,
};

export const integrationSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        setActiveIntegrations: (state, action) => {
            state.activeIntegrations = action.payload;
        },
    },
});

export const { setActiveIntegrations } = integrationSlice.actions;

export default integrationSlice.reducer;
