import { makeStyles } from '@material-ui/core/styles';

export const useCommonScreesStyles = makeStyles((theme) => ({
    // company logo on header and screen heading
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        background: theme.navbarColor,
        borderBottom: `5px solid ${theme.secondaryColor}`,
    },

    headerLogo: {
        maxHeight: '50px',
        width: 'auto',
        maxWidth: '340px',
    },

    screenHeading: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        textAlign: 'center',
        color: '#111827',
        padding: '24px 30px',
        background: theme.headerColor,
        marginBottom: '36px',
        fontFamily: theme.fontFamily,
        borderTopLeftRadius: theme.modalBorderRadius,
        borderTopRightRadius: theme.modalBorderRadius,
    },

    cardWrapper: {
        width: '360px',
        height: '640px',
        margin: '16px',
        background: theme.modalBackgroundColor,
        borderRadius: theme.modalBorderRadius,
        fontFamily: theme.fontFamily,
        border: '1px solid rgba(0,0,0,.125)',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .16%)',
    },

    contained: {
        color: theme.primaryColor,
        width: '100%',
        padding: '10px 0px',
        borderRadius: theme.button?.borderRadius,
        background: theme.primaryColor,
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            color: '#FFFFFF',
            textTransform: 'none',
        },
    },

    outlined: {
        border: `1px solid ${theme.primaryColor}`,
        borderRadius: theme.button?.borderRadius,
        background: '#FFFFFF',
        width: '100%',
        padding: '10px 0px',
        '& span': {
            fontFamily: theme.fontFamily,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '150%',
            color: theme.primaryColor,
            textTransform: 'none',
        },
    },
}));
