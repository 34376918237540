import React from 'react';

import PropTypes from 'prop-types';

const Content = ({ children }) => <>{children}</>;

Content.propTypes = {
    children: PropTypes.node,
};

export default Content;
