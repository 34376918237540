import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import classes from './noListings.module.css';

import { ReactComponent as CircleArrowIcon } from 'asset/IconsPack/at_arrow-circle-right.svg';
import { ReactComponent as HotelImg } from 'asset/img/Hotel Booking-rafiki 1.svg';

const NoListings = ({ onClick, canUserUpdate }) => {
    return (
        <Box className={classes.container}>
            <HotelImg className={classes.hotelImg} />
            <Button
                intent="primary"
                className={classes.btn}
                onClick={onClick}
                startIcon={<CircleArrowIcon className={classes.arrowIcon} />}
                disabled={!canUserUpdate}
            >
                Move Listings Here
            </Button>
            <Typography className={classes.text}>
                Get started by moving listings from your other brands here.
            </Typography>
        </Box>
    );
};

NoListings.propTypes = {
    onClick: PropTypes.func,
    canUserUpdate: PropTypes.bool,
};

export default NoListings;
