import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { ListItem, ListItemIcon, Typography, Box } from '@material-ui/core/';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip';
import { isRouteRestricted } from 'helper/utility';
import PropTypes from 'prop-types';

import { fetchAdminInformationAction } from 'redux/actions/auth';

import styles from '../sidebar.module.css';

import { navigationItems } from '../constant';

function NavigationList({ user, token, adminData, fetchAdminInformationAction, collapseMenu }) {
    let location = useLocation();

    // verifies if routeName is the one active
    function activeRoute(routeName) {
        // home and billing page include same '/dashboard' word in a route so separate condition to make sure both section don't highlight at same time
        if (location.pathname === '/dashboard' || location.pathname === '/billing/dashboard') {
            return location.pathname === routeName;
        } else {
            return location.pathname.includes(routeName);
        }
    }

    useEffect(() => {
        if (!adminData) {
            fetchAdminInformationAction(token);
        }
    }, [fetchAdminInformationAction, adminData, token]);

    return (
        <Box className={styles.sidebarNav}>
            {navigationItems?.map((item) => {
                const isActiveRoute = activeRoute(item.path);
                const listItemStyle = isActiveRoute ? styles.activeListItem : styles.listItem;
                const restrictedRoute = isRouteRestricted(item.label, user);
                const collapseNavStyle = collapseMenu && styles.collapseNavigationItem;

                if (restrictedRoute) {
                    return null;
                } else {
                    return (
                        <NavLink to={item.path} key={item.id} className={styles.navLinkContainer}>
                            <Tooltip
                                title={
                                    item.id === 4 && collapseMenu
                                        ? 'True Continuous Monitoring™'
                                        : ''
                                }
                                placement="right"
                                classes={{ tooltip: styles.collapseTooltip }}
                            >
                                <ListItem
                                    className={clsx(
                                        styles.navigationItem,
                                        listItemStyle,
                                        collapseNavStyle
                                    )}
                                >
                                    <ListItemIcon className={styles.listItemIcon}>
                                        {isActiveRoute ? item.icons.selected : item.icons.default}
                                    </ListItemIcon>
                                    <Typography
                                        className={clsx(
                                            styles.listItemLabel,
                                            collapseMenu && styles.mimiItemLabel
                                        )}
                                    >
                                        {item.label}
                                    </Typography>
                                </ListItem>
                            </Tooltip>
                        </NavLink>
                    );
                }
            })}
        </Box>
    );
}

NavigationList.propTypes = {
    user: PropTypes.object.isRequired,
    adminData: PropTypes.bool,
    fetchAdminInformationAction: PropTypes.func,
    token: PropTypes.string,
    collapseMenu: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        user: state.auth?.userData?.user,
        adminData: state.auth?.adminData,
        token: state.auth?.userData.token,
    };
}

export default connect(mapStateToProps, { fetchAdminInformationAction })(NavigationList);
