import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from './listingAlreadyExists.module.css';

import { ReactComponent as BuildingIcon } from 'asset/IconsPack/at_building-07-filled.svg';

const ListingAlreadyExists = ({ listingName, brandName }) => {
    return (
        <Box className={classes.container}>
            <Box className={classes.iconWrapper}>
                <BuildingIcon className={classes.buildingIcon} />
            </Box>
            {listingName ? (
                <Box>
                    <Typography className={classes.topText}>
                        The listing you were searching for
                    </Typography>
                    <Typography className={classes.bottomText}>
                        <b>{listingName}</b> is part of <b>{brandName}</b>
                    </Typography>{' '}
                </Box>
            ) : (
                <Box>
                    <Typography className={classes.multipleListingsText}>
                        The listings you've searched for are already a part of <b>{brandName}</b>
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

ListingAlreadyExists.propTypes = {
    listingName: PropTypes.string,
    brandName: PropTypes.string,
};

export default ListingAlreadyExists;
