import React from 'react';

import PropTypes from 'prop-types';
import Listings from 'views/V2/Integration/components/Listings';

import styles from '../ConfigureBrand/configureBrand.module.css';

import { ReactComponent as PlusIcon } from 'asset/IconsPack/at_plus-circle.svg';

import NoListings from '../NoListings';

const ReusableBrandListings = ({
    noListings,
    onOpenModal,
    canUserUpdate,
    listingRef,
    listings,
    totalCount,
    onScroll,
    value,
    onChange,
    isLoading,
}) => {
    return noListings ? (
        <NoListings onClick={onOpenModal} canUserUpdate={canUserUpdate} />
    ) : (
        <Listings
            classes={{
                searchInput: styles.listingSearch,
                listItem: styles.listingItem,
                itemPlaceholder: styles.listItemPlaceholder,
            }}
            ref={listingRef}
            onScroll={onScroll}
            value={value}
            onChange={onChange}
            listings={listings}
            total={totalCount}
            isLoading={isLoading}
            btnProps={
                canUserUpdate && {
                    text: 'Move Here',
                    className: styles.moveHereBtn,
                    onClick: onOpenModal,
                    startIcon: <PlusIcon className={styles.plusIcon} />,
                }
            }
        />
    );
};

ReusableBrandListings.propTypes = {
    noListings: PropTypes.bool,
    onOpenModal: PropTypes.func,
    canUserUpdate: PropTypes.bool,
    listingRef: PropTypes.node,
    listings: PropTypes.array,
    totalCount: PropTypes.number,
    onScroll: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default ReusableBrandListings;
