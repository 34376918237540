import { makeStyles } from '@material-ui/core/styles';

export const useVerificationOptionScreenStyle = makeStyles((theme) => ({
    InnerCardWrapper: {
        padding: '0px 14px',
        paddingBottom: '58px',
    },
    issueIdCard: {
        marginTop: '24px',
        border: '2px solid #E5E7EB',
        borderRadius: '6px',
        '&:hover': {
            border: `2px solid ${theme.secondaryColor}`,
        },
    },
    cardBtn: {
        width: '100%',
    },
    CardContent: {
        width: '100%',
    },
    issueid: {
        padding: '14px 0px 20px 0px',
    },
    verificationCardHeading: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#111827',
        paddingBottom: '8px',
    },
    verificationCardSubHeading: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        color: '#374151',
        minHeight: '28px',
        textAlign: 'center',
        padding: '0px 25px 26px',
    },
    attempt: {
        fontFamily: theme.fontFamily,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        color: '#9CA3AF',
        textAlign: 'end',
    },
}));
