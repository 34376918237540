import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import RotatingLoader from 'components/RotatingLoader';
import PropTypes from 'prop-types';

import { getPaymentStatusAction, setPaymentStatusAction } from 'redux/actions/integrations';

import classes from './paymentStatus.module.css';

const _PaymentStatus = ({
    payment,
    paymentConfirmation,
    token,
    companyUUID,
    getPaymentStatusAction,
    setPaymentStatusAction,
}) => {
    const [apiStatus, setAPIStatus] = useState('not_started'); // not_started, in_progress, complete
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paymentUUID = queryParams.get('payment');
    const showLoader =
        apiStatus !== 'complete' ||
        !paymentConfirmation?.triggered ||
        !paymentConfirmation?.completed;
    const message =
        apiStatus === 'complete' && payment?.status !== 'subscribed'
            ? 'We could not confirm your payment, please contact support.'
            : 'Confirming your subscription..';

    //confirm the payment if the UUID exists
    useEffect(() => {
        if (paymentUUID)
            setPaymentStatusAction(
                token,
                { paymentUUID, companyAccessCode: companyUUID },
                { loader: false }
            );
    }, [paymentUUID, companyUUID, token, setPaymentStatusAction]);
    //get the payment status, depending on whether paymentConfirmation state has changed.
    useEffect(() => {
        let isInUse = true;
        if (paymentConfirmation?.completed) {
            getPaymentStatusAction(token, companyUUID, { loader: false }, () => {
                if (isInUse) {
                    setAPIStatus('complete');
                }
            });
            setAPIStatus('in_progress');
        }
        return () => {
            isInUse = false;
        };
    }, [token, companyUUID, getPaymentStatusAction, paymentConfirmation?.completed]);

    useEffect(() => {
        if (
            payment?.status === 'subscribed' &&
            apiStatus === 'complete' &&
            paymentConfirmation?.completed
        ) {
            history.push('/integrations/e-commerce');
        }
    }, [payment, apiStatus, history, paymentConfirmation?.completed]);

    return (
        <div>
            <div className={classes.container}>
                <span className={classes.message}>{message}</span>
                {showLoader && <RotatingLoader />}
            </div>
        </div>
    );
};

_PaymentStatus.propTypes = {
    payment: PropTypes.object,
    paymentConfirmation: PropTypes.object,
    token: PropTypes.string,
    companyUUID: PropTypes.string,
    getPaymentStatusAction: PropTypes.func,
    setPaymentStatusAction: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        paymentConfirmation: state?.integration?.paymentConfirmation?.api,
        payment: state?.integration?.payment,
        token: state?.auth?.userData?.token,
        companyUUID: state?.auth?.userData?.user?.company?.uuid,
    };
}

const PaymentStatus = connect(mapStateToProps, {
    getPaymentStatusAction,
    setPaymentStatusAction,
})(_PaymentStatus);

export { PaymentStatus };
