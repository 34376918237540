import React from 'react';
import { makeStyles } from '@material-ui/core';
import loader from 'asset/img/loader.png';

const useStyles = makeStyles(() => ({
    loaderLogoImg: {
        animationName: 'spin',
        animationDuration: '5000ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        display: 'block',
        margin: '"auto"',
    },
}));

const RotatingLoader = ({ width = '70px' }) => {
    const classes = useStyles();
    return <img className={classes.loaderLogoImg} width={width} src={loader} alt={'Loader'} />;
};

export default RotatingLoader;
