import actionType from 'redux/actions/actionConstant';

const initialState = {
    showLoader: false,
};

export default function loader(state = initialState, action) {
    switch (action.type) {
        case actionType.START_LOADER:
            return {
                showLoader: true,
            };
        case actionType.STOP_LOADER:
            return {
                showLoader: false,
            };
        default:
            return state;
    }
}
