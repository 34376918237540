import React from 'react';

import { Box, Typography } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Modal from 'components/ModalV2';
import PropTypes from 'prop-types';

import classes from './deactivateModal.module.css';

const DeactivateModal = ({ open, setOpen, onClose, onConfirm }) => {
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box className={classes.container}>
                <FormHeader title="Deactivate Confirmation" handleClose={onClose} />
                <Typography className={classes.text}>
                    Are you sure you want to deactivate Integration?
                </Typography>
                <FormFooter
                    formType="onClick"
                    primaryBtnHandler={onClose}
                    secondaryBtnHandler={onConfirm}
                    secondaryBtnText="Yes"
                    btnText="No"
                />
            </Box>
        </Modal>
    );
};

DeactivateModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};

export default DeactivateModal;
