import React from 'react';
import { connect } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import CustomDrawer from 'components/CustomDrawer';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';

import { setSidebarCollapse } from 'redux/actions/settings';

import styles from '../sidebar.module.css';

import { ReactComponent as Collapse } from 'asset/icons/collapse.svg';
import { ReactComponent as Expand } from 'asset/icons/expand.svg';
import { ReactComponent as AuthenticateLogoMini } from 'asset/img/Authenticate_ R_Logo.svg';
import { ReactComponent as AuthenticateLogo } from 'asset/img/authenticate_rgb.svg';

import NavigationList from './NavigationList';

function DesktopSidebar({ sidebarCollapse, setSidebarCollapse }) {
    const handleCollapse = () => {
        setSidebarCollapse(!sidebarCollapse);
    };

    return (
        <CustomDrawer
            variant="permanent"
            className={clsx(styles.desktopDrawer, sidebarCollapse && styles.miniDesktopDrawer)}
        >
            <Box className={styles.desktopNavHeader}>
                {sidebarCollapse ? (
                    <AuthenticateLogoMini />
                ) : (
                    <AuthenticateLogo className={styles.authenticateLogo} />
                )}
            </Box>
            <NavigationList collapseMenu={sidebarCollapse} />
            <Tooltip
                title={sidebarCollapse ? 'Show Menu' : 'Collapse Menu'}
                placement="right"
                classes={{ tooltip: styles.collapseTooltip }}
            >
                <IconButton
                    className={clsx(
                        styles.sidebarCollapseBtn,
                        sidebarCollapse && styles.sidebarExpandBtn
                    )}
                    onClick={handleCollapse}
                    disableRipple
                >
                    {sidebarCollapse ? (
                        <Expand className={styles.collapseIcon} />
                    ) : (
                        <Collapse className={styles.collapseIcon} />
                    )}
                </IconButton>
            </Tooltip>
        </CustomDrawer>
    );
}

DesktopSidebar.propTypes = {
    sidebarCollapse: PropTypes.bool,
    setSidebarCollapse: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        sidebarCollapse: state.settings?.sidebarCollapse,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSidebarCollapse: (show) => dispatch(setSidebarCollapse(show)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesktopSidebar);
