import React from 'react';

import { Box, Typography } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Modal from 'components/ModalV2';
import PropTypes from 'prop-types';

import styles from './unsavedBrandWarning.module.css';

const UnsavedBrandWarning = ({ open, setOpen, resetForm, setUnsavedChanges }) => {
    const handleClose = () => {
        resetForm();
        setUnsavedChanges(false);
        setOpen(false);
    };

    const handlePrimaryClick = () => {
        setUnsavedChanges(false);
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} disableBackdropClick={true}>
            <Box className={styles.unsavedChangeBox}>
                <FormHeader title="Unsaved Changes" handleClose={handleClose} />
                <Box className={styles.modalBody}>
                    <Typography className={styles.warningMessage}>
                        You have unsaved changes. Would you like to discard them and proceed?
                    </Typography>
                </Box>
                <FormFooter
                    secondaryBtnHandler={handleClose}
                    secondaryBtnText="Yes"
                    btnText="No"
                    formType="onClick"
                    primaryBtnHandler={handlePrimaryClick}
                />
            </Box>
        </Modal>
    );
};

UnsavedBrandWarning.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    resetForm: PropTypes.func,
    setUnsavedChanges: PropTypes.func,
};

export default UnsavedBrandWarning;
