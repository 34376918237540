import superagent from 'superagent';
import config from 'config';

const { API } = config;

/*
 * @function "call" common method that makes api requests
 * @param {object} "request" stores the request 'method','endpoint', 'payload', 'query',
 * 'token' as keys...'
 */
export default function call({
    method = 'get',
    url,
    endpoint,
    payload,
    query,
    type = 'application/json',
    authToken,
}) {
    const _url = `${API.BASE_URL}/${endpoint}`;

    const request = superagent(method, endpoint ? _url : url).set('Content-Type', type);
    // .set('x-api-key', API.X_API_KEY);
    if (authToken) {
        request.set('secret_token', authToken);
    }
    return request.send(payload).query(query);
}

export function userApiCall({
    method = 'get',
    url,
    endpoint,
    payload,
    query,
    type = 'application/json',
    bearerToken,
}) {
    const _url = `${API.BASE_URL}/${endpoint}`;

    const request = superagent(method, endpoint ? _url : url).set('Content-Type', type);

    if (bearerToken) {
        request.set('Authorization', `Bearer ${bearerToken}`);
    }
    return request.send(payload).query(query);
}
