import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Input from 'components/Input';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import classes from './searchBrandAndListings.module.css';

import { ReactComponent as SearchIcon } from 'asset/IconsPack/at_search-sm-filled.svg';

import Tag from './Tag';

const SearchBrandAndListings = ({ value, setValue, onSubmit, tags, setTags, removeSearchTag }) => {
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onKeyPress = async (e) => {
        if (e.charCode === 13 && e.code === 'Enter') {
            const query = e.target.value.trim();

            if (!query) return;

            const existingTagLabels = new Set(tags.map((tag) => tag.label));
            const newTags = [];

            query.split(',').forEach((tag) => {
                const trimmedTag = tag.trim();
                if (trimmedTag && !existingTagLabels.has(trimmedTag)) {
                    newTags.push({ id: nanoid(), label: trimmedTag });
                    existingTagLabels.add(trimmedTag);
                }
            });

            if (newTags.length > 0) {
                const updatedTags = [...tags, ...newTags];
                setTags(updatedTags);
                setValue('');
                await onSubmit(updatedTags);
            }
        }
    };

    return (
        <Box className={classes.wrapper}>
            <Input
                variant="outlined"
                label=""
                value={value}
                name="searchInput"
                className={classes.searchInput}
                placeholder="Search by brand name or listing"
                startAdornment={true}
                overrideStyles={{ startAdornment: classes.searchIcon }}
                StartAdornmentIcon={SearchIcon}
                handleChange={onChange}
                onKeyPress={onKeyPress}
                autoComplete="off"
            />
            {tags.length > 0 ? (
                <Box className={classes.tags}>
                    {tags.map((tag) => (
                        <Tag
                            key={tag.id}
                            content={tag.label}
                            onDelete={(e) => removeSearchTag(tag.id)}
                        />
                    ))}
                </Box>
            ) : (
                <Typography className={classes.hintText}>
                    Type the search and add “’,” for a search to be added. For Ex: “Brand 1,” and
                    press enter which will be added as a filter.
                </Typography>
            )}
        </Box>
    );
};

SearchBrandAndListings.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    onSubmit: PropTypes.func,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
        })
    ),
    setTags: PropTypes.func,
    removeSearchTag: PropTypes.func,
};

export default SearchBrandAndListings;
