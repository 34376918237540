import { useState } from 'react';

import { Menu, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import classes from './activateMenu.module.css';

import { ReactComponent as ChevronIcon } from 'asset/IconsPack/at_chevron-down-filled.svg';
import ActiveIndicator from 'asset/img/active_indicator_white_bg.gif';

const ActivateMenu = ({ onClickDeactivate, canUserUpdate }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();

    const onClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const PaperProps = {
        style: {
            width: anchorEl ? anchorEl.clientWidth : undefined,
        },
        className: classes.paper,
    };

    const onClickDeactivateExtended = () => {
        onClickDeactivate();
        handleClose();
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const anchorOrigin = isMobile
        ? { vertical: 'top', horizontal: 'center' }
        : { vertical: 'bottom', horizontal: 'center' };

    const transformOrigin = isMobile
        ? { vertical: 55, horizontal: 'center' }
        : { vertical: 'top', horizontal: 'center' };

    return (
        <div>
            <Button
                aria-haspopup="true"
                onClick={onClick}
                className={classes.btn}
                startIcon={
                    <img
                        src={ActiveIndicator}
                        alt="green-indicator"
                        className={classes.indicatorImg}
                    />
                }
                endIcon={
                    <ChevronIcon
                        className={
                            open
                                ? clsx(classes.chevronIcon, classes.chevronIconOpen)
                                : classes.chevronIcon
                        }
                    />
                }
                disabled={!canUserUpdate}
            >
                Active
            </Button>
            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={PaperProps}
            >
                <MenuItem className={classes.menuItem} onClick={onClickDeactivateExtended}>
                    Deactivate
                </MenuItem>
            </Menu>
        </div>
    );
};

ActivateMenu.propTypes = {
    onClickDeactivate: PropTypes.func,
    canUserUpdate: PropTypes.bool,
};

export default ActivateMenu;
