import { createApi } from '@reduxjs/toolkit/query/react';
import apiEndpoints from 'api/endpoints';

import { baseAuthQuery } from './baseApi';

export const onBoardingAPI = createApi({
    reducerPath: 'onboarding-api',
    baseQuery: baseAuthQuery,
    endpoints: (builder) => ({
        registerCompanyEmail: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.registerCompanyEmail}`,
                method: 'POST',
                body: payload,
            }),
        }),

        registerCompany: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.registerCompany}`,
                method: 'POST',
                body: payload,
            }),
        }),

        completeRegistration: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.finishRegistration}`,
                method: 'POST',
                body: payload,
            }),
        }),

        verifyEmail: builder.query({
            query: () => ({
                url: `${apiEndpoints.verifyRegisteredEmail}`,
                method: 'GET',
            }),
        }),

        resendVerifyEmail: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.resendVerifyEmail}`,
                method: 'POST',
                body: payload,
            }),
        }),

        updateEmail: builder.mutation({
            query: (payload) => ({
                url: `${apiEndpoints.updaterRegisteredEmail}`,
                method: 'PUT',
                body: payload,
            }),
        }),
    }),
});

export const {
    useRegisterCompanyEmailMutation: useRegisterCompanyEmail,
    useRegisterCompanyMutation: useRegisterCompany,
    useCompleteRegistrationMutation: useCompleteRegistrationFlow,
    useLazyVerifyEmailQuery,
    useResendVerifyEmailMutation: useResendVerifyEmail,
    useUpdateEmailMutation: useUpdateCompanyEmail,
} = onBoardingAPI;

export default onBoardingAPI.middleware;
