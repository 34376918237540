import React from 'react';

import clsx from 'clsx';
import CustomDrawer from 'components/CustomDrawer';
import PropTypes from 'prop-types';

import classes from './listingsModal.module.css';

import Content from './Content';
import Footer from './Footer';
import Header from './Header';

const ListingsModal = ({ open, onClose, className, children }) => {
    return (
        <CustomDrawer
            open={open}
            onClose={onClose}
            anchor="right"
            className={clsx(classes.container, className)}
        >
            {children}
        </CustomDrawer>
    );
};

ListingsModal.Header = Header;
ListingsModal.Content = Content;
ListingsModal.Footer = Footer;

ListingsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default ListingsModal;
