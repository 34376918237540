import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, IconButton, Divider, Grid, Typography, Link } from '@material-ui/core';
import CustomDrawer from 'components/CustomDrawer';
import { truncateUserName } from 'helper/utility';
import PropTypes from 'prop-types';
import routeEndpoints from 'route/endpoints';

import styles from '../sidebar.module.css';

import { ReactComponent as LeftArrow } from 'asset/IconsPack/at_chevron-left-filled.svg';
import { ReactComponent as LogoutIcon } from 'asset/IconsPack/at_log-out-03.svg';
import { ReactComponent as TicketIcon } from 'asset/IconsPack/at_ticket-01.svg';
import { ReactComponent as Profile } from 'asset/IconsPack/at_user-circle.svg';
import { ReactComponent as AuthenticateLogo } from 'asset/img/authenticate_rgb.svg';

import NavigationList from './NavigationList';

function SmallScreenSidebar({ openSidebar, handleToggler, firstName, isMobile }) {
    const history = useHistory();

    const handleLogout = () => {
        history.push(routeEndpoints.logout);
    };

    return (
        <CustomDrawer
            anchor="left"
            variant="temporary"
            className={styles.tabletSidebar}
            open={openSidebar}
            onClose={handleToggler}
        >
            <Box className={styles.tabletNavHeader}>
                <IconButton onClick={handleToggler} className={styles.toggleButton}>
                    <LeftArrow className={styles.leftArrow} />
                </IconButton>
                <Divider orientation="vertical" flexItem className={styles.divider} />
                <AuthenticateLogo className={styles.authenticateLogo} />
            </Box>
            <NavigationList />
            {isMobile && (
                <Box className={styles.userDetailsBox}>
                    <Grid className={styles.userNameBox}>
                        <Profile className={styles.profileLogo} />
                        <Typography className={styles.userNameLabel}>
                            Signed in as <span>{truncateUserName(firstName, 20)}</span>
                        </Typography>
                    </Grid>
                    <Divider className={styles.divider} />
                    <Box className={styles.userOptionsBox}>
                        <Link
                            href="https://info.authenticate.com/kb-tickets/new"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.userMenuItem}
                        >
                            <TicketIcon className={styles.userMenuIcon} /> Raise a Support Ticket
                        </Link>
                        <Link
                            component="button"
                            variant="body2"
                            className={styles.userMenuItem}
                            onClick={handleLogout}
                        >
                            <LogoutIcon className={styles.userMenuIcon} /> Logout
                        </Link>
                    </Box>
                </Box>
            )}
        </CustomDrawer>
    );
}

SmallScreenSidebar.propTypes = {
    isMobile: PropTypes.bool,
    openSidebar: PropTypes.bool,
    handleToggler: PropTypes.func,
    firstName: PropTypes.string,
};

export default SmallScreenSidebar;
