/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRutterLink } from 'react-rutter-link';

import { IconButton, Grid, Switch } from '@material-ui/core';
import HistoryBackArrow from 'components/HistoryBackArrow';
import RotatingLoader from 'components/RotatingLoader';
import VerifyAccessLevel from 'components/VerifyAccessLevel';
import { adminTypes } from 'helper/constant';
import { checkAdminAccess } from 'helper/utility';
import PropTypes from 'prop-types';

import { getEnabledProductsAction, getPaymentStatusAction } from 'redux/actions/integrations';
import { actions as statusActions, selectors as statusSelectors } from 'redux/actions/status';

import commonClasses from '../../index.module.css';
import classes from './eCommerce.module.css';

// assets
import { ReactComponent as Edit } from 'asset/icons/edit.svg';

import EnabledProducts from './components/EnabledProducts';
// components
import RedirectForPayment from './components/RedirectForPayment';

const ECommerce = ({
    history,
    match,
    location,
    settingAction,
    settingPostAction,
    settingGetAction,
    paymentStatus,
    paymentStatusAction,
    token,
    uid,
    active,
    activeAction,
    getEnabledProductsAction,
    enabledProducts,
    settingsValues,
    isIntegrated,
    startPolling,
    stopPolling,
    isProductsReady,
    loggedInAdminRole,
}) => {
    const [isActive, setIsActive] = useState(false);

    const { COMPANY_OWNER, COMPANY_ADMIN } = adminTypes;

    const searchParams = new URLSearchParams(location.search);
    const publicToken = location?.state?.publicKey
        ? location?.state?.publicKey
        : searchParams.get('public_token');

    const platform = settingsValues?.find((setting) => setting.name === 'platform');

    const isRutterEnable = isIntegrated?.[0]?.active;
    const settingId = active?.find((key) => key.name === 'E-Commerce').id;

    const isIntegrationActive = isIntegrated?.[0]?.active
        ? 'Your store is being synced, this could take a couple of minutes.'
        : 'Connect your store to get started.';

    const subHeading = isProductsReady
        ? 'Products for which identity verification is enabled:'
        : isIntegrationActive;

    const showEnabledProducts = isProductsReady && Boolean(enabledProducts?.length) && isActive;
    const showRedirect =
        isActive && paymentStatus?.status !== 'subscribed' && platform?.value === 'SHOPIFY';

    useEffect(() => {
        setIsActive(Boolean(isIntegrated?.[0]?.active));
        if (isIntegrated?.[0]?.active) {
            if (isProductsReady) {
                getEnabledProductsAction({ companyUUID: uid }, { loader: true }, token);
            } else {
                startPolling();
            }
        } else {
            activeAction({ companyAccessCode: uid }, { loader: true }, token);
        }

        //stop polling request
        if (isProductsReady) {
            stopPolling();
        }

        //stop polling on umount
        return () => {
            stopPolling();
        };
    }, [isRutterEnable, isProductsReady]);

    useEffect(() => {
        //for first time integration
        if (publicToken && isIntegrated && !isIntegrated[0]?.active && settingId) {
            settingAction({ id: settingId }, { loader: true }, token, setSettings);
        }
    }, [Boolean(isIntegrated?.[0]?.active), settingId]);

    useEffect(() => {
        if (isIntegrated?.[0]?.active) paymentStatusAction(token, uid);

        // Fetch the current integration settings
        if (settingId) {
            settingGetAction(
                {
                    companyAccessCode: uid,
                    integration_id: settingId,
                },
                { loader: true },
                token
            );
        }
    }, [isIntegrated?.[0]?.active, settingId]);

    function setSettings(response) {
        const newSettings = response.body.map((setting) => {
            switch (setting.name) {
                case 'accessToken':
                    return {
                        integration_id: setting.integration_id,
                        integration_setting_id: setting.id,
                        name: setting.name,
                        value: publicToken,
                    };
                case 'Active':
                    return {
                        integration_id: setting.integration_id,
                        integration_setting_id: setting.id,
                        name: setting.name,
                        value: 1,
                    };
                default:
                    return {
                        integration_id: setting.integration_id,
                        integration_setting_id: setting.id,
                        name: setting.name,
                        value: '0',
                    };
            }
        });
        settingPostAction(
            {
                companyAccessCode: uid,
                settings: newSettings,
                key: {
                    id: settingId,
                },
            },
            { loader: true },
            token,
            () => {
                activeAction({ companyAccessCode: uid }, { loader: true }, token);
            }
        );
    }

    //integration rutter using toggle
    const onSuccess = (output) => {
        settingAction({ id: settingId }, { loader: true }, token, (response) => {
            const newSettings = response.body.map((setting) => {
                switch (setting.name) {
                    case 'accessToken':
                        return {
                            integration_id: setting.integration_id,
                            integration_setting_id: setting.id,
                            name: setting.name,
                            value: output,
                        };
                    case 'Active':
                        return {
                            integration_id: setting.integration_id,
                            integration_setting_id: setting.id,
                            name: setting.name,
                            value: 1,
                        };
                    default:
                        return {
                            integration_id: setting.integration_id,
                            integration_setting_id: setting.id,
                            name: setting.name,
                            value: '0',
                        };
                }
            });
            settingPostAction(
                {
                    companyAccessCode: uid,
                    settings: newSettings,
                    key: {
                        id: settingId,
                    },
                },
                { loader: true },
                token,
                () => {
                    setIsActive(true);
                    activeAction({ companyAccessCode: uid }, { loader: true }, token);
                    startPolling();
                }
            );
        });
    };
    const rutterConfig = {
        publicKey: process.env.REACT_APP_RUTTER_KEY,
        onSuccess: onSuccess,
    };

    const { open } = useRutterLink(rutterConfig);

    const switchHander = (e) => {
        if (e.target.checked) {
            open();
        } else {
            settingAction({ id: settingId }, { loader: true }, token, (response) => {
                const newSettings = response.body.map((setting) => {
                    switch (setting.name) {
                        case 'accessToken':
                            return {
                                integration_id: setting.integration_id,
                                integration_setting_id: setting.id,
                                name: setting.name,
                                value: null,
                            };
                        case 'Active':
                            return {
                                integration_id: setting.integration_id,
                                integration_setting_id: setting.id,
                                name: setting.name,
                                value: 0,
                            };
                        default:
                            return {
                                integration_id: setting.integration_id,
                                integration_setting_id: setting.id,
                                name: setting.name,
                                value: '0',
                            };
                    }
                });
                settingPostAction(
                    {
                        companyAccessCode: uid,
                        settings: newSettings,
                        key: {
                            id: settingId,
                        },
                    },
                    { loader: true },
                    token,
                    () => {
                        setIsActive(false);
                        activeAction({ companyAccessCode: uid }, { loader: true }, token);
                    }
                );
            });
        }
    };

    const isItAllowedAdmin = checkAdminAccess(loggedInAdminRole, [COMPANY_OWNER, COMPANY_ADMIN]);

    return (
        <>
            {
                <div className={classes.wrapper}>
                    <HistoryBackArrow />
                    <Grid container alignItems="center">
                        <Grid item xs={10} sm={11}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div className={classes.heading}>E-Commerce Store</div>
                                <Switch
                                    onChange={switchHander}
                                    checked={isActive}
                                    disabled={!isItAllowedAdmin}
                                    classes={{
                                        switchBase: commonClasses.switchBase,
                                        track: isActive
                                            ? commonClasses.activeTrack
                                            : commonClasses.track,
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {isActive && isProductsReady && (
                                <VerifyAccessLevel allowedAdmins={[COMPANY_OWNER, COMPANY_ADMIN]}>
                                    <IconButton
                                        onClick={() => {
                                            history.push(`${match.path}/products`);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </VerifyAccessLevel>
                            )}
                        </Grid>
                    </Grid>
                    <p className={classes.subHeading}>{subHeading}</p>

                    {!isProductsReady && Boolean(isRutterEnable) && (
                        <div className={classes.loaderAlignCenter}>
                            <RotatingLoader />
                        </div>
                    )}

                    <span>
                        {isActive ? (
                            <>
                                {!enabledProducts?.length && isProductsReady && (
                                    <span className={classes.noProduct}>
                                        {' '}
                                        No Products added
                                        <br />
                                        for verification yet!
                                    </span>
                                )}
                            </>
                        ) : (
                            <span className={classes.noProduct}>
                                {' '}
                                Integration Disabled - No Products <br />
                                added for verification yet!
                            </span>
                        )}
                    </span>

                    {showEnabledProducts && <EnabledProducts enabledProducts={enabledProducts} />}
                    {showRedirect && (
                        <RedirectForPayment open={true} redirectUrl={paymentStatus?.paymentLink} />
                    )}
                </div>
            }
        </>
    );
};

ECommerce.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    settingAction: PropTypes.func,
    settingPostAction: PropTypes.func,
    settingGetAction: PropTypes.func,
    paymentStatus: PropTypes.object,
    paymentStatusAction: PropTypes.func,
    token: PropTypes.string,
    uid: PropTypes.string,
    active: PropTypes.number,
    activeAction: PropTypes.func,
    getEnabledProductsAction: PropTypes.func,
    enabledProducts: PropTypes.array,
    settingsValues: PropTypes.object,
    isIntegrated: PropTypes.bool,
    startPolling: PropTypes.func,
    stopPolling: PropTypes.func,
    isProductsReady: PropTypes.bool,
    loggedInAdminRole: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        enabledProducts: state.integration?.enabledProducts,
        paymentStatus: state.integration?.payment,
        isProductsReady: statusSelectors.getLastPoint(state)?.body?.isReady,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        paymentStatusAction: (token, companyAccessCode) =>
            dispatch(getPaymentStatusAction(token, companyAccessCode)),
        startPolling: () => dispatch(statusActions.start()),
        stopPolling: () => dispatch(statusActions.stop()),
        resetPolling: () => dispatch(statusActions.reset()),
        getEnabledProductsAction: (data, config, token) =>
            dispatch(getEnabledProductsAction(data, config, token)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ECommerce);
