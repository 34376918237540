import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';

import { checkAuthTokenExpiry } from 'helper/utility';
// new layout
import Onborading from 'layouts/Onboarding';
// old layout
import OnBoarding from 'layouts/V2/OnBoarding';
import PropTypes from 'prop-types';

const ENABLE_NEW_LOGIN_FLOW = process.env.REACT_APP_ENABLE_NEW_LOGIN_FLOW === 'true';

const LoginRoute = ({ path, exact, ComponentNew, ComponentOld, type, ...props }) => {
    const history = useHistory();
    const location = useLocation();

    const isAuthUser = useSelector((state) => state?.auth?.userData?.token);
    const tokenExpireTime = useSelector(
        (state) => state.auth?.userData?.user.token_expiration_date
    );

    useEffect(() => {
        checkAuthTokenExpiry({ history, tokenExpireTime });
    }, [history, tokenExpireTime]);
    return (
        <Route
            path={path}
            exact={exact}
            render={() =>
                isAuthUser ? (
                    <Redirect to={'/dashboard' + location.search} />
                ) : (
                    <>
                        {ENABLE_NEW_LOGIN_FLOW ? (
                            <OnBoarding>
                                <ComponentNew {...props} type={type} />
                            </OnBoarding>
                        ) : (
                            <Onborading>
                                <ComponentOld {...props} type={type} />
                            </Onborading>
                        )}
                    </>
                )
            }
        />
    );
};

LoginRoute.propTypes = {
    path: PropTypes.string,
    exact: PropTypes.bool,
    ComponentNew: PropTypes.elementType,
    ComponentOld: PropTypes.elementType,
    type: PropTypes.string,
};

export default LoginRoute;
