import { makeStyles } from '@material-ui/core/styles';

// InputWrapper component
export const useInputWrapperStyle = makeStyles((theme) => ({
    inputWrapperContainer: {
        padding: '8px 0px',
    },
}));

export const useDropDownStyle = makeStyles(() => ({
    formControl: {
        width: '100%',
    },

    themeDropDown: {
        fontFamily: 'Rubik',
        height: '44px',
        border: '1px solid #9CA3AF',
        boxSizing: 'border-box',
        borderRadius: '8px',
        '& svg': {
            top: '18px',
        },
    },

    selectoutlined: {
        padding: '10px 32px  9px 14px',
    },
}));
