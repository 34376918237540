import { makeStyles } from '@material-ui/core/styles';

export const useMoreOptionStyles = makeStyles((theme) => ({
    moreOptionBtn: {
        backgroundColor: '#fff',
        border: '1px solid #bfbfbf',
        borderRadius: '2px',
        padding: '12px',

        '&:hover': {
            backgroundColor: '#fff',
        },

        '&:disabled': {
            backgroundColor: '#F5F5F5',
            '& span svg path': {
                fill: '#BFBFBF',
            },
        },
    },

    menuIcon: {
        width: '18px',
        height: '18px',
        color: '#262626',
    },

    brandOptionDropdown: {
        marginTop: '5px',
        borderRadius: '2px',

        '& .MuiPaper-elevation8': {
            padding: '0',
            border: ' 1px solid #D9D9D9',
            boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
        },
    },

    menuItems: {
        minHeight: 'auto',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '18px',
        padding: '8.5px 20px',
        background: '#fff',
        color: '#565656',

        '&:hover': {
            background: '#F5F5F5',
        },
    },
}));
