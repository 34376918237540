import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import RadioButton from 'components/RadioButton';
import PropTypes from 'prop-types';

import classes from './mobileSelectBrandList.module.css';

//note: update with listings count once change is made on backend
const MobileSelectBrand = ({ selectedBrand, setSelectedBrand, brands }) => {
    return (
        <Box className={classes.container}>
            {brands.map((brand) => {
                const isSelected = selectedBrand === brand.value;
                return (
                    <Box
                        key={brand.value}
                        className={
                            isSelected ? clsx(classes.item, classes.highlightItem) : classes.item
                        }
                    >
                        <RadioButton
                            aria-selected={isSelected ? 'true' : 'false'}
                            checked={isSelected}
                            className={classes.radio}
                            onChange={() => setSelectedBrand(brand.value)}
                        />
                        <Typography className={classes.brandName}>{brand.label}</Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

MobileSelectBrand.propTypes = {
    brands: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    selectedBrand: PropTypes.string,
    setSelectedBrand: PropTypes.func,
};

export default MobileSelectBrand;
