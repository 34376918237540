import { createSlice } from '@reduxjs/toolkit';
import { pollingApi } from 'api/polling';

const initialState = {
    data: null,
    error: null,
    isFetching: false,
    intervalId: null,
};

export const pollApiSlice = createSlice({
    name: 'pollApi',
    initialState,
    reducers: {
        fetchDataStart: (state) => {
            state.isFetching = true;
        },
        fetchDataSuccess: (state, action) => {
            state.data = action.payload;
            state.error = null;
        },
        fetchDataStop: (state) => {
            state.isFetching = false;
            state.intervalId = null;
        },
        fetchDataFailure: (state, action) => {
            state.error = action.payload;
            state.isFetching = false;
            state.intervalId = null;
        },
        updateIntervalId: (state, action) => {
            state.intervalId = action.payload;
        },

        resetFetchData: (state) => {
            state.data = null;
            state.error = null;
            state.intervalId = null;
            state.isFetching = false;
        },
    },
});

export const {
    fetchDataStart,
    fetchDataSuccess,
    fetchDataFailure,
    fetchDataStop,
    updateIntervalId,
    resetFetchData,
} = pollApiSlice.actions;

const fetchData = async ({
    apiUrl,
    body = {},
    tokenType = 'Authorization',
    method = 'post',
    auth,
    secretToken = null,
}) => {
    try {
        const token = secretToken ?? auth?.userData?.token;

        const response = await pollingApi({ apiUrl, body, token, tokenType, method });

        if (response.isOk()) {
            return response.value.body;
        }

        return Promise.reject(response.error?.response);
    } catch (error) {
        return Promise.reject(error);
    }
};

/**
 * The polling feature with support for exponential backOff.
 * enable it by providing the "exponentialBackOff" and "maxAttempts" props.
 * Otherwise, it will perform standard polling without backOff.
 * When exponential backOff is enabled, the waiting period for each request is determined by the "pollingInterval."
 */

export const startPolling = (props) => (dispatch, getState) => {
    let waitingPeriod = props.pollingInterval;
    let pollingId = null;
    let attemptCount = 0;
    const { auth } = getState();
    const performPolling = async () => {
        try {
            dispatch(fetchDataStart());
            const response = await fetchData({ ...props, auth });

            if (response) {
                dispatch(fetchDataSuccess(response));

                if (props.exponentialBackOff) {
                    clearInterval(pollingId);
                    attemptCount++;

                    if (attemptCount <= props.maxAttempts) {
                        waitingPeriod *= 2; // doubling waiting period of each attempt
                        pollingId = setInterval(performPolling, waitingPeriod);
                        dispatch(updateIntervalId(pollingId));
                    } else {
                        dispatch(stopPolling());
                    }
                }
            }
        } catch (error) {
            dispatch(pollingFailed(error));
        }
    };

    pollingId = setInterval(performPolling, waitingPeriod);
    dispatch(updateIntervalId(pollingId));
    return pollingId;
};

export const stopPolling = () => (dispatch, getState) => {
    const { pollApi } = getState();
    dispatch(fetchDataStop());
    clearInterval(pollApi.intervalId);
};

export const pollingFailed = (error) => (dispatch, getState) => {
    const { pollApi } = getState();
    dispatch(fetchDataFailure(error));
    clearInterval(pollApi.intervalId);
};

export const resetPolling = () => (dispatch, getState) => {
    const { pollApi } = getState();
    clearInterval(pollApi.intervalId);
    dispatch(resetFetchData());
};

export default pollApiSlice.reducer;
