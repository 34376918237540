import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import classes from './listingsModal.module.css';

import { ReactComponent as CloseIcon } from 'asset/IconsPack/at_x-close.svg';

const Header = ({ title, onClose, children }) => {
    return (
        <Box className={classes.headerWrapper}>
            <Box className={classes.header}>
                <Typography className={classes.title}>{title}</Typography>
                <Box className={classes.closeIconWrapper}>
                    <CloseIcon className={classes.closeIcon} onClick={onClose} />
                </Box>
            </Box>
            {children}
        </Box>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.node,
};

export default Header;
