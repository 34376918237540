import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as yup from 'yup';

import actionConstants from 'redux/actions/actionConstant';
import { openToast } from 'redux/actions/toaster';
import { useUpdateBrandMutation, useLazyGetBrandQuery } from 'redux/slices/globalApi';

const useCustomFormik = (brandUUID, setUnsavedChanges) => {
    const dispatch = useDispatch();
    const [triggerGetBrandApi, { data }] = useLazyGetBrandQuery();
    const [triggerUpdateBrandApi] = useUpdateBrandMutation();

    const getBrandData = useCallback(async () => {
        try {
            await triggerGetBrandApi(brandUUID).unwrap();
        } catch (err) {}
    }, [brandUUID, triggerGetBrandApi]);

    useEffect(() => {
        getBrandData();
    }, [getBrandData]);

    const validationSchema = yup.object().shape({
        passCriteria: yup.array(),
        preferredWorkflowId: yup.string('Select preferred workflow id').nullable(),
    });

    const workflowID = data?.preferredWorkflowConfig?.workflow?.nanoid;

    const initialValues = {
        passCriteria: data?.passCriteria || [],
        preferredWorkflowId: workflowID || null,
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                dispatch({ type: actionConstants.START_LOADER });
                const query = {
                    brandUUID,
                    payload: { brandName: data?.brandName, ...values },
                };
                const result = await triggerUpdateBrandApi(query).unwrap();

                if (result?.success) {
                    await triggerGetBrandApi(brandUUID).unwrap();

                    dispatch(
                        openToast({
                            variant: 'success',
                            message: 'Brand edited successfully.',
                        })
                    );
                }
            } catch (err) {
            } finally {
                dispatch({ type: actionConstants.STOP_LOADER });
                setUnsavedChanges(false);
            }
        },
    });

    return { formik, brandData: data };
};

export default useCustomFormik;
