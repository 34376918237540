import { makeStyles } from '@material-ui/core/styles';

export const useThemeButtonStyle = makeStyles(() => ({
    set: {
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#111827',
    },

    btnSetContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '18px',
        alignItems: 'center',
        marginBottom: '10px',
    },

    commonBtn: {
        fontFamily: 'Rubik',
        textTransform: 'none',
        fontWeight: '400',
        width: '100%',
        boxShadow: 'none',
        color: '#fff',

        '&:hover': {
            boxShadow: 'none',
        },
    },

    btnBox: {
        padding: '5px',
        borderRadius: '5px',

        '&:hover': {
            background: '#becdea',
        },
    },

    btnBoxSelected: {
        background: '#becdea',
    },

    containedSet_1: {
        background: '#154683',
        borderRadius: '6px',
        textTransform: 'none',
        '&:hover': {
            background: '#154683',
        },
    },

    outlinedSet_1: {
        color: '#154683',
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        border: '1px solid #154683',
        textTransform: 'none',
        '&:hover': {
            background: '#FFFFFF',
        },
    },

    containedSet_2: {
        background: '#1BA1DB',
        borderRadius: '15px',
        textTransform: 'none',
        '&:hover': {
            background: '#1BA1DB',
        },
    },

    outlinedSet_2: {
        color: '#1BA1DB',
        background: '#FFFFFF',
        borderRadius: '15px',
        border: '1px solid #1BA1DB',
        textTransform: 'none',
        '&:hover': {
            background: '#FFFFFF',
        },
    },

    containedSet_3: {
        background: '#154683',
        textTransform: 'none',
        '&:hover': {
            background: '#154683',
        },
    },

    outlinedSet_3: {
        color: '#154683',
        background: '#FFFFFF',
        border: '1px solid #154683',
        textTransform: 'none',
        '&:hover': {
            background: '#FFFFFF',
        },
    },

    containedSet_4: {
        background: '#7B61FF',
        borderRadius: '44px',
        textTransform: 'none',
        '&:hover': {
            background: '#7B61FF',
        },
    },

    outlinedSet_4: {
        color: '#7B61FF',
        background: '#FFFFFF',
        border: '1px solid #7B61FF',
        borderRadius: '44px',
        textTransform: 'none',
        '&:hover': {
            background: '#FFFFFF',
        },
    },
}));
