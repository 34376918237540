import apiCall, { userApiCall } from './apiRequest';
import endPoints from './endpoints';

export function login2FAApi(data = { code: '' }, authToken) {
    return userApiCall({
        method: 'post',
        endpoint: endPoints.login2FA,
        payload: data,
        bearerToken: authToken,
    });
}
export function registerApi(data) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.register,
        payload: data,
    });
}

export function resetPasswordApi(data) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.reset,
        payload: data,
    });
}

export function verifyTokenApi(data) {
    return apiCall({
        method: 'get',
        endpoint: `${endPoints.verify}${data}`,
    });
}

export function setForgetPasswordApi(data) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.setForgetPassword,
        payload: data,
    });
}

export function updatePasswordApi(data, token) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.updatePassword,
        payload: data,
        authToken: token,
    });
}

export function refreshCompanyToken({ companyUUID, token }) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.refreshCompany,
        authToken: token,
        query: `companyUUID=${companyUUID}`,
    });
}

export function fetchAdminInformationApi({ token }) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.admin,
        authToken: token,
    });
}

export function setInvitePasswordApi(data) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.setInvitePassword,
        payload: data,
    });
}

export function logoutApi({ token }) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.logout,
        authToken: token,
    });
}
