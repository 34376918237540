import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { chatbotWidgetState } from 'redux/selectors/general';
import { setOpenChatWidget } from 'redux/slices/general';

const useHubSpotChat = () => {
    const chatbotConfig = window?.HubSpotConversations;
    const chatWidget = document.getElementById('hubspot-messages-iframe-container');
    const chatBotStatus = chatbotConfig?.widget?.status()?.loaded;
    const dispatch = useDispatch();
    const openChatWidget = useSelector(chatbotWidgetState);

    // apply styles to show chatbot widget
    const showChatWidget = useCallback(() => {
        if (chatBotStatus) {
            chatWidget.setAttribute(
                'style',
                'min-height: 96px; min-width: 100px; width: 408px; height: 634px; z-index:999999 !important;'
            );
        }
    }, [chatBotStatus, chatWidget]);

    // apply styles to hide chatbot widget
    const hideChatWidget = useCallback(() => {
        if (chatBotStatus) {
            chatWidget.setAttribute('style', 'display: none !important');
        }
    }, [chatBotStatus, chatWidget]);

    // functionality to open chatbot from menu
    const openChatBot = useCallback(() => {
        if (chatbotConfig) {
            dispatch(setOpenChatWidget(true));
            chatbotConfig.widget.open();
            showChatWidget();
        }
    }, [dispatch, chatbotConfig, showChatWidget]);

    useEffect(() => {
        if (!openChatWidget) {
            hideChatWidget();
        } else {
            showChatWidget();
        }
    }, [openChatWidget, hideChatWidget, showChatWidget]);

    // when chat is closed remove the chatbot widget
    useEffect(() => {
        chatbotConfig?.on('widgetClosed', () => {
            if (openChatWidget) {
                hideChatWidget();
            }
            dispatch(setOpenChatWidget(false));
        });
    }, [chatbotConfig, hideChatWidget, openChatWidget, dispatch]);

    const removeChatWidget = useCallback(() => {
        hideChatWidget();
    }, [hideChatWidget]);

    // if new message comes if chatbot is closed then open the chat
    useEffect(() => {
        if (chatbotConfig) {
            chatbotConfig?.on('unreadConversationCountChanged', () => {
                openChatBot();
                chatbotConfig?.widget.open();
            });
        }

        return () => removeChatWidget();
    }, [openChatBot, removeChatWidget, chatbotConfig]);

    return {
        openChatBot,
        removeChatWidget,
    };
};

export default useHubSpotChat;
