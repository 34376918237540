import { Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';

function RedirectRoute({ pathname, search = null, includeQueryParams }) {
    if (includeQueryParams) {
        return <Redirect to={{ pathname, search: window.location.search }} />;
    }
    return <Redirect to={{ pathname, search }} />;
}

RedirectRoute.propTypes = {
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    includeQueryParams: PropTypes.bool, // if true, will include query params from current url
};

export { RedirectRoute };
