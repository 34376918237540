import React from 'react';

import Input from 'components/Input';
import VerifyAccessLevel from 'components/VerifyAccessLevel';
import { adminTypes } from 'helper/constant';
import PropTypes from 'prop-types';

import classes from './credentialInput.module.css';

const CredentialInput = ({ name, value, error, helperText, handleChange }) => {
    const { COMPANY_OWNER, COMPANY_ADMIN } = adminTypes;

    return (
        <VerifyAccessLevel
            allowedAdmins={[COMPANY_OWNER, COMPANY_ADMIN]}
            returnType="credential"
            className={classes.credentialText}
        >
            <Input
                variant="outlined"
                name={name}
                value={value}
                handleChange={handleChange}
                error={error}
                helperText={helperText}
                classes={{
                    textField: classes.textField,
                }}
            />
        </VerifyAccessLevel>
    );
};

CredentialInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    handleChange: PropTypes.func,
};

export default CredentialInput;
