export const styles = (theme) => ({
    box: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        fontFamily: 'Rubik',
        padding: '16px',
        boxSizing: 'border-box',
        borderRadius: '6px',
        backgroundColor: '#262626',
        gap: '10px',

        '@media screen and (max-width: 600px)': {
            width: '100%',
        },
    },
    icon: {
        height: '24px',
        width: '24px',
    },
    closeIconBtn: {
        height: '24px',
        width: '24px',
    },
    closeIcon: {
        height: 'inherit',
    },
    messageContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px',
        overflow: 'hidden',
    },
    message: {
        color: '#FCFCFC',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        overflow: 'hidden',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
    },
});
