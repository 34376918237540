const { makeStyles } = require('@material-ui/core');

const usePassCriteriaStyles = makeStyles((theme) => ({
    select: {
        width: '100%',
        borderRadius: '4px 4px 0px 0px',
        opacity: '0px',
        boxShadow: ' 0px 1px 2px 0px #1018280D',
        outline: 'none',
        border: '1px solid #DDDDDD',

        '&::before, &::after': {
            borderBottom: '0px',
        },

        '&:hover:not(.Mui-disabled)::before': {
            border: 'none',
        },

        '&:hover:not(.Mui-disabled)': {
            border: '1px solid #1BA1DB',
        },

        '& .MuiSelect-root.MuiSelect-select': {
            fontFamily: 'Rubik, sans-serif',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400,
            textAlign: 'left',
            padding: '10px 32px 10px 14px',

            '&:focus': {
                backgroundColor: 'transparent',
            },

            '&:hover': {
                borderBottom: 'none',
            },
        },

        '& .MuiSelect-icon': {
            '& path': {
                fill: '#98A2B3',
            },
            top: '40%',
            right: '14px',
        },
    },

    menuItem: {
        padding: '5px 12px',
        gap: '8px',
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#344054',
        display: 'flex',
        alignItems: 'center',
        pointerEvents: 'auto',

        '&.Mui-selected': {
            background: 'none',
        },

        '&:hover': {
            background: '#EFF8FF',
            fontWeight: '500',
        },

        '&.Mui-disabled': {
            color: '#565656',
            pointerEvents: 'auto',
        },
    },

    checkbox: {
        padding: '0',

        '&.MuiCheckbox-root': {
            color: '#DDDDDD',
            width: '16px',
            borderRadius: '9px',
        },
        '&.Mui-checked': {
            color: '#154683',
        },

        '&:hover': {
            background: 'none',
        },
    },

    passCriteriaDropdown: {
        display: 'grid',
        gridTemplateColumns: '100%',
        position: 'relative',
    },

    optionsPreviewBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px',
    },

    rightSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40%',
        position: 'absolute',
        height: '100%',
        top: '50%',
        right: '0',
        transform: 'translateY(-50%)',
        borderLeft: '1px solid #D1D5DB',
        zIndex: '1',
        background: '#fff',
    },

    infoIcon: {
        color: '#6B7280',
        width: '24px',
        height: '24px',
    },

    previewText: {
        marginTop: '10px',
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#000',
        textAlign: 'center',
    },
}));

export default usePassCriteriaStyles;
