import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import { SESSION_ERR_CODES } from 'constants';
import routeEnpoints from 'route/endpoints';

const { API } = config;

const baseQuery = fetchBaseQuery({
    baseUrl: `${API.BASE_URL}/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState()?.auth?.userData?.token;
        headers.set('secret_token', token);
        return headers;
    },
});

export const baseQueryWrapper = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    const isSessionInvalid =
        result.error &&
        result.error.status === 401 &&
        SESSION_ERR_CODES.includes(result.error?.data?.errorCode);

    if (isSessionInvalid) {
        window.location.href = routeEnpoints.logout;
    }

    return result;
};

// This method is for onboarding APIs which use bearer token
export const baseAuthQuery = fetchBaseQuery({
    baseUrl: `${API.BASE_URL}/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState()?.general?.bearerToken;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
