import { makeStyles } from '@material-ui/core/styles';

export const userSettingsStyles = makeStyles((theme) => ({
    usersDropDownMenu: {
        marginTop: '8px',
        borderRadius: '6px',

        '& .MuiPaper-elevation8': {
            border: '1px solid #EAECF0',
            boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;',
        },
    },

    userMenuItem: {
        '&.MuiMenuItem-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            padding: '10px 16px',
            background: '#fff',
            color: '#101828',

            '&:hover': {
                background: '#F5F5F5',
            },
        },
    },
}));
