import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { checkAuthTokenExpiry } from 'helper/utility';
export const PublicRoute = ({
    isAuthenticated,
    tokenExpireDate,
    component: Component,
    ...rest
}) => {
    let searchParams = useLocation();
    const history = useHistory();
    useEffect(() => {
        return checkAuthTokenExpiry({ history, tokenExpireDate });
    }, [tokenExpireDate, history]);

    return (
        <Route
            {...rest}
            component={(props) =>
                !isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={'/dashboard' + searchParams.search} />
                )
            }
        />
    );
};

function mapStateToProps(state) {
    return {
        isAuthenticated: state?.auth?.userData?.token,
        tokenExpireDate: state.auth?.userData?.user.token_expiration_date,
    };
}

export default connect(mapStateToProps)(PublicRoute);
