import React, { useState } from 'react';

import { Tooltip as MaterialToolTip, withStyles, IconButton } from '@material-ui/core';
import cn from 'clsx';
import PropTypes from 'prop-types';

import styles from './tooltip.module.css';

import { ReactComponent as InfoFilledIcon } from 'asset/IconsPack/hx_info-circle-filled.svg';
import { ReactComponent as InfoOutlinedIcon } from 'asset/IconsPack/hx_info-circle.svg';

const Tooltip = withStyles((theme) => ({
    tooltip: (props) => ({
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '20px',
        background: '#1D2939',
        color: '#fff',
        padding: '8px 12px',
        borderRadius: '8px',
        boxShadow: '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
        maxWidth: props.customWidth ? props.customWidth : 'auto',
    }),
    arrow: {
        color: '#1D2939',
    },
}))(MaterialToolTip);

const ToolTipWithIcon = ({
    classes,
    children,
    className = '',
    tooltipIconClassName = '',
    title = '',
    placement = 'right',
    ariaLabel = null,
    arrow,
    customWidth,
    onClick,
    ...props
}) => {
    const [isHover, setIsHover] = useState(false);
    return (
        <Tooltip
            {...props}
            classes={classes}
            className={className}
            arrow={arrow}
            title={title}
            placement={placement}
            aria-label={ariaLabel}
            enterTouchDelay={0}
            customWidth={customWidth}
            onClick={onClick}
        >
            {children || (
                <IconButton
                    style={{ padding: '0' }}
                    className={styles.container}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                >
                    {isHover ? (
                        <InfoFilledIcon className={cn(tooltipIconClassName, styles.tooltipIcon)} />
                    ) : (
                        <InfoOutlinedIcon
                            className={cn(tooltipIconClassName, styles.tooltipIcon)}
                        />
                    )}
                </IconButton>
            )}
        </Tooltip>
    );
};

ToolTipWithIcon.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    tooltipIconClassName: PropTypes.string,
    title: PropTypes.string,
    placement: PropTypes.string,
    ariaLabel: PropTypes.string,
    arrow: PropTypes.bool,
    classes: PropTypes.object,
    customWidth: PropTypes.string,
    onClick: PropTypes.func,
};

export default ToolTipWithIcon;
