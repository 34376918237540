import { Snackbar, IconButton, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// components
import { styles } from './toasterStyle';
// assets
import Warning from 'asset/icons/warning.svg';
import Success from 'asset/icons/success.svg';
import Info from 'asset/icons/info.svg';
import Error from 'asset/icons/error.svg';
import Close from 'asset/icons/toast_close.svg';

const variantIcon = {
    default: Success,
    success: Success,
    warning: Warning,
    error: Error,
    info: Info,
};

const MySnackbarContent = (props) => {
    const { classes, className, message, onClose, variant } = props;
    const Icon = variantIcon[variant];

    return (
        <Box className={clsx(className, classes.box)}>
            <Box className={classes.messageContainer}>
                <img className={classes.icon} src={Icon} alt={`${variant}-icon`} />
                <Typography className={classes.message}>{message}</Typography>
            </Box>
            <IconButton
                key="close"
                aria-label="Close"
                className={classes.closeIconBtn}
                onClick={onClose}
            >
                <img className={classes.closeIcon} src={Close} alt="close-icon" />
            </IconButton>
        </Box>
    );
};

MySnackbarContent.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.string,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

const Toaster = ({ open, message, closeToast, variant, ...rest }) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeToast();
    };
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <MySnackbarContentWrapper onClose={handleClose} variant={variant} message={message} />
        </Snackbar>
    );
};

Toaster.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    closeToast: PropTypes.func,
    variant: PropTypes.string,
};

export default Toaster;
