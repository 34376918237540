import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

// components
import { closeToast } from 'redux/actions/toaster';

import Toaster from './Toaster';

function ToastAdapter({ closeToast, variant, message, open }) {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            // checks if the path has changed
            if (open) {
                closeToast();
            }
        });

        return () => unlisten();
    }, [open, history, closeToast]);

    return <Toaster closeToast={closeToast} variant={variant} message={message} open={open} />;
}

function mapStateToProps(state) {
    return {
        open: state.toaster.open,
        message: state.toaster.message,
        variant: state.toaster.variant,
    };
}

ToastAdapter.propTypes = {
    closeToast: PropTypes.func.isRequired,
    variant: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
    closeToast,
})(ToastAdapter);
