import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAllWorkflowAction } from 'redux/actions/workflow';
import { Redirect } from 'react-router-dom';
import Loader from 'components/Loader';

function DefaultWorkflow({ fetchAllWorkflowAction, fetchAllWorkflows, token }) {
    const [defaultId, setDefaultId] = useState();

    useEffect(() => {
        fetchAllWorkflowAction({ pageNumber: 0, pageSize: 50, token: token }, { loader: true });
    }, [token, fetchAllWorkflowAction]);

    useEffect(() => {
        return (
            fetchAllWorkflows &&
            fetchAllWorkflows.workflows.forEach((element) => {
                if (element.is_default) {
                    setDefaultId(element.nanoid);
                }
            })
        );
    });

    if (fetchAllWorkflows && defaultId) {
        return <Redirect to={`/workflows/${defaultId}`} />;
    }

    return <Loader />;
}

function mapStateToProps(state) {
    return {
        fetchAllWorkflows: state.workflow && state.workflow.fetchAllWorkflows,
        token: state.auth.userData && state.auth.userData.token,
    };
}

export default connect(mapStateToProps, {
    fetchAllWorkflowAction,
})(DefaultWorkflow);
