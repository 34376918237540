import React from 'react';

import { Box, Divider, Button } from '@material-ui/core';
import cn from 'clsx';
import PropTypes from 'prop-types';

import styles from './formComponents.module.css';

// * btnText -> passed to primary action button
// * formType -> two values can be passed. default is submit 👇
// * 1) "onClick" = when primary button need custom onClick function.
// * 2) "submit" = when form type is submit no custom event handler needed
// * secondaryBtnHandler -> to handle onClick functionality of secondary button (Cancel button)
// * primaryBtnHandler -> only required when form type is not submit

function FormFooter({
    btnText,
    secondaryBtnText,
    secondaryBtnHandler,
    primaryBtnHandler,
    formType = 'submit',
    form,
    isPrimaryBtnDisabled = false,
    primaryClassName,
    children,
}) {
    return (
        <>
            <Divider className={styles.divider} />
            <Box className={styles.commonFormFooter}>
                {children}
                <Button
                    className={cn(styles.commonFooterBtn, styles.secondaryBtn)}
                    onClick={secondaryBtnHandler}
                >
                    {secondaryBtnText || 'Cancel'}
                </Button>
                <Button
                    className={cn(styles.commonFooterBtn, styles.primaryBtn, primaryClassName)}
                    onClick={formType === 'onClick' ? primaryBtnHandler : undefined}
                    type={formType === 'onClick' ? 'button' : 'submit'}
                    form={form}
                    disabled={isPrimaryBtnDisabled}
                >
                    {btnText}
                </Button>
            </Box>
        </>
    );
}

FormFooter.propTypes = {
    btnText: PropTypes.string.isRequired,
    secondaryBtnText: PropTypes.string,
    formType: PropTypes.oneOf(['onClick', 'submit']),
    form: PropTypes.string,
    primaryBtnHandler: PropTypes.func,
    secondaryBtnHandler: PropTypes.func.isRequired,
    isPrimaryBtnDisabled: PropTypes.bool,
    primaryClassName: PropTypes.string,
    children: PropTypes.node,
};

export default FormFooter;
