import React from 'react';

import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './onboarding.module.css';

import { ReactComponent as Logo } from 'asset/img/authenticate_rgb.svg';

const OnBoarding = (props) => {
    return (
        <Box className={styles.wrapper}>
            <Box className={styles.container}>
                <Box className={styles.formWrapper}>
                    <Logo className={styles.logo} />
                    {props.children}
                </Box>
            </Box>
        </Box>
    );
};

OnBoarding.propTypes = {
    children: PropTypes.node.isRequired,
};

export default OnBoarding;
