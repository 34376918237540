import { makeStyles } from '@material-ui/core/styles';

export const useColorChooserStyle = makeStyles(() => ({
    textField: {
        fontFamily: 'Rubik',
        pointerEvents: 'none',
        textTransform: 'uppercase',
        height: '42px',
        borderRadius: '0px 4px 4px 0px',
    },

    container: {
        position: 'relative',
        display: 'flex',
    },

    colorPreviewBox: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        // borderRadius: "4px",
        borderRadius: '4px 0px 0px 4px',
        width: '46px',
        height: '40px',
        flexShrink: '0',
        // marginRight: "8px",
    },
}));
