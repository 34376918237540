import React from 'react';

import { Grid } from '@material-ui/core';
import Checkbox from 'components/Checkbox';
import PropTypes from 'prop-types';

import classes from './enabledProducts.module.css';

import NoImageIcon from 'asset/img/no-image-icon.png';

const EnabledProducts = ({ enabledProducts }) => {
    return (
        <Grid container spacing={4}>
            {enabledProducts?.map((product) => {
                return (
                    <Grid item key={product.id} xs={12} sm={6} md={4}>
                        <div
                            className={classes.card}
                            style={{
                                background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 69.26%, rgba(0, 0, 0, 0.4) 100%), url(${
                                    product.images.length ? product.images[0].src : NoImageIcon
                                })`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className={classes.checkbox}>
                                <Checkbox disabled={true} checked={true} />
                            </div>
                            <div className={classes.cardHeading}>{product.name}</div>
                        </div>
                    </Grid>
                );
            })}
        </Grid>
    );
};

EnabledProducts.propTypes = {
    enabledProducts: PropTypes.array,
};

export default EnabledProducts;
