export const extractRedirect = (searchParams) => {
    const params = new URLSearchParams(searchParams);
    let redirect = null;

    if (params.has('redirect')) {
        redirect = params.get('redirect');
        params.delete('redirect');

        if (redirect.charAt(0) !== '/') redirect = '/' + redirect;
    }

    return { params: params.toString(), redirect };
};
