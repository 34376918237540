import { makeStyles } from '@material-ui/core/styles';

export const useVerifyIdentityStyles = makeStyles((theme) => ({
    contentWrapper: {
        padding: '0px 14px 0px 18px',
    },

    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '24px',
    },

    rowIcon: {
        paddingRight: '14px',
    },

    rowTxt: {
        fontFamily: theme.fontFamily,
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#374151',
    },

    rowCheckbox: {
        padding: '0px',
    },

    checked: {
        color: theme.primaryColor,
        '& svg': {
            color: theme.primaryColor,
        },
    },

    terms: {
        paddingTop: '20px',
        paddingBottom: '80px',
    },

    submitBtnWrapper: {
        paddingBottom: '105px',
    },

    link: {
        color: theme.primaryColor,
        fontWeight: '500',
    },

    weblink: {
        fontWeight: 500,
        textDecoration: 'underline',
        color: theme.primaryColor,
    },
}));
