const { makeStyles } = require('@material-ui/core');

const useListItemStyles = makeStyles((theme) => ({
    listItem: {
        padding: '8px 18px',
        borderRadius: '20px',
        marginBottom: ' 14px',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#111827',

        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#154683',
            color: '#fff',
        },
    },
}));

export default useListItemStyles;
