import actionType from 'redux/actions/actionConstant';

const initialState = {
    usersData: undefined,
    userLog: [],
    pagination: undefined,
    adminJwtToken: undefined,
    sendLinkViaEmail: undefined,
    cacheFilterValues: undefined,
    isEmailPreviewUsed: false,
    mvrAttemptDetails: undefined,

    //todo: move this product analytics cache to specific reducer when more event track from portal
    userProfileAdminActivity: {},
    usersGeoCodeAddress: {},
};

export default function users(state = initialState, action) {
    switch (action.type) {
        case actionType.USER_FILTER_DATA_SUCCESS:
        case actionType.TOTAL_USERS_SUCCESS:
            return {
                ...state,
                usersData: action.payload,
            };

        case actionType.USER_LOG_SUCCESS:
            return {
                ...state,
                userLog: action.payload.endpoints_called,
            };

        case actionType.SET_PREVIOUS_PAGINATION:
            return {
                ...state,
                pagination: {
                    lastLoadedPage: action.payload.page,
                    lastRowsPerPage: action.payload.rowsPerPage,
                },
            };

        case actionType.RESET_PREVIOUS_PAGINATION:
            return {
                ...state,
                pagination: undefined,
            };

        case actionType.ADMIN_JWT_TOKEN_SUCCESS:
            return {
                ...state,
                adminJwtToken: action.payload,
            };

        case actionType.SEND_LINK_EMAIL_SUCCESS:
            return {
                ...state,
                sendLinkViaEmail: action.payload,
            };

        case actionType.CACHE_FILTER_VALUES:
            return {
                ...state,
                cacheFilterValues: action.payload,
            };

        case actionType.RESET_FILTER_VALUES:
            return {
                ...state,
                cacheFilterValues: undefined,
            };

        case actionType.SET_IS_EMAIL_PREVIEW_USED:
            return {
                ...state,
                isEmailPreviewUsed: true,
            };

        case actionType.UPDATE_MVR_ATTEMPT_DETAILS:
            return {
                ...state,
                mvrAttemptDetails: action.payload,
            };

        case actionType.MODIFY_USER_PROFILE_ADMIN_ENGAGEMENT:
            return {
                ...state,
                userProfileAdminActivity: action.payload,
            };

        case actionType.CACHE_USERS_GEOCODE_ADDRESS:
            return {
                ...state,
                usersGeoCodeAddress: action.payload,
            };

        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                usersData: undefined,
                userLog: [],
                pagination: undefined,
                adminJwtToken: undefined,
                sendLinkViaEmail: undefined,
                cacheFilterValues: undefined,
                usersGeoCodeAddress: {},
            };
        default:
            return state;
    }
}
