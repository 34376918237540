import actionType from 'redux/actions/actionConstant';

const initialState = {
    tcmUserStatus: undefined,
    tcmUserRecords: undefined,
    tcmUserSearch: undefined,
    watchlistRecords: undefined,
    tcmPaginationValues: undefined,
};

export default function tcm(state = initialState, action) {
    switch (action.type) {
        case actionType.GET_TCM_USER_STATUS_SUCCESS:
            return {
                ...state,
                tcmUserStatus: action.payload,
            };

        case actionType.GET_TCM_USER_RECORD_SUCCESS:
            return {
                ...state,
                tcmUserRecords: action.payload,
            };

        case actionType.TCM_USER_SEARCH_SUCCESS:
            return {
                ...state,
                tcmUserSearch: action.payload,
            };

        case actionType.TCM_WATCHLIST_RECORDS_SUCCESS:
            return {
                ...state,
                watchlistRecords: action.payload,
            };

        case actionType.CLEAR_TCM_SEARCH_RECORDS:
            return {
                ...state,
                tcmUserSearch: undefined,
            };

        case actionType.STORE_TCM_PAGINATION_VALUES:
            return {
                ...state,
                tcmPaginationValues: action.payload,
            };

        case actionType.RESET_TCM_PAGINATION_VALUES:
            return {
                ...state,
                tcmPaginationValues: undefined,
            };

        case actionType.RESET_ALL_REDUCERS:
            return {
                ...initialState,
                tcmUserStatus: undefined,
                tcmUserRecords: undefined,
                tcmUserSearch: undefined,
                watchlistRecords: undefined,
                tcmPaginationValues: undefined,
            };
        default:
            return state;
    }
}
