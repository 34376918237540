import React, { useEffect } from 'react';

import { MenuItem, Select, Typography, Box } from '@material-ui/core';
import Checkbox from 'components/Checkbox/Checkbox';
import PropTypes from 'prop-types';

import usePassCriteriaStyles from './styles';

import { ReactComponent as InfoIcon } from 'asset/IconsPack/hx_info-circle.svg';
import { ReactComponent as DropDown } from 'asset/icons/pagination_dropdown.svg';

import { getGuestyPassCriteria, passCriteriaOptionsInfo } from '../../constants';

const ENABLE_PASS_CRITERIA =
    'Pass criteria depends on your workflow settings. Only the checks you configure will appear in the pass criteria options.';

function SelectPassCriteria({ formik, workflow, setUnsavedChanges }) {
    const [selectedInfo, setSelectedInfo] = React.useState('');

    const classes = usePassCriteriaStyles();

    const GUESTY_PASS_CRITERIA = getGuestyPassCriteria(workflow);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        const result = typeof value === 'string' ? value.split(',') : value;
        const falseRemoved = result.filter((item) => item !== false);
        setUnsavedChanges(true);
        formik.setFieldValue('passCriteria', falseRemoved);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const defaultMenuProps = {
        PaperProps: {
            style: {
                width: anchorEl ? anchorEl.clientWidth : 0,
                overflowY: 'auto',
                marginTop: '3px',
            },
            className: classes.passCriteriaDropdown,
        },
        anchorEl: anchorEl,
        keepMounted: true,
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    };

    const menuProps = {
        ...defaultMenuProps,

        PaperProps: {
            ...defaultMenuProps.PaperProps,
        },
        anchorOrigin: {
            ...defaultMenuProps.anchorOrigin,
        },
        transformOrigin: {
            ...defaultMenuProps.transformOrigin,
        },
    };

    useEffect(() => {
        const passCriteria = getGuestyPassCriteria(workflow);

        const newValue = passCriteria.filter((item) => !item.disabled).map((item) => item.value);
        formik.setFieldValue('passCriteria', newValue);
        // eslint-disable-next-line
    }, [workflow]);

    return (
        <Select
            name="passCriteria"
            open={Boolean(anchorEl)}
            className={classes.select}
            value={formik?.values?.passCriteria}
            MenuProps={menuProps}
            onChange={handleChange}
            placeholder="Select Pass Criteria"
            IconComponent={DropDown}
            onClose={handleClose}
            onOpen={handleOpen}
            multiple
            renderValue={(selected) => {
                return selected
                    .reduce((acc, value) => {
                        const criteria = GUESTY_PASS_CRITERIA.find(
                            (c) => c.value === value && !c.disabled
                        );
                        if (criteria?.label) {
                            acc.push(criteria.label);
                        }
                        return acc;
                    }, [])
                    .join(', ');
            }}
        >
            {GUESTY_PASS_CRITERIA?.map((option) => (
                <MenuItem
                    key={option.value}
                    value={!option?.disabled && option.value}
                    className={classes.menuItem}
                    disabled={option?.disabled}
                    onMouseOver={() =>
                        setSelectedInfo(option?.disabled ? 'disabled' : option.value)
                    }
                >
                    <Checkbox
                        checked={formik.values.passCriteria.indexOf(option.value) > -1}
                        className={classes.checkbox}
                    />
                    {option.label}
                </MenuItem>
            ))}
            <Box className={classes.rightSection}>
                <Box className={classes.optionsPreviewBox}>
                    <InfoIcon className={classes.infoIcon} />
                    <Typography className={classes.previewText}>
                        {selectedInfo === 'disabled'
                            ? ENABLE_PASS_CRITERIA
                            : passCriteriaOptionsInfo[selectedInfo]}
                    </Typography>
                </Box>
            </Box>
        </Select>
    );
}

SelectPassCriteria.propTypes = {
    formik: PropTypes.object,
    workflow: PropTypes.object,
    setUnsavedChanges: PropTypes.func,
};

export default SelectPassCriteria;
