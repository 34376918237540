import React from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './index.module.css';

import ActiveIndicator from 'asset/img/active-indicator.gif';

const ActiveBadge = ({ className }) => {
    return (
        <Box className={clsx(classes.wrapper, className)}>
            <img className={classes.indicator} src={ActiveIndicator} alt="green-indicator" />
            <Typography className={classes.text}>Active</Typography>
        </Box>
    );
};

ActiveBadge.propTypes = {
    className: PropTypes.string,
};

export default ActiveBadge;
