import { combineReducers } from 'redux';
import { reduxPollingNamespace, reduxPollingReducer } from 'redux-polling';

import { generalSlice } from 'redux/slices/general';
import { integrationSlice } from 'redux/slices/integration';
import { onBoardingAPI } from 'redux/slices/onBoardingApi';
import { registerSlice } from 'redux/slices/register';

import { globalAPI } from '../slices/globalApi';
import pollApi from '../slices/polling';
import apiCall from './apiCall';
import auth from './auth';
import billing from './billing';
import dashboard from './dashboard';
import eep from './eep';
import integration from './integration';
import loader from './loader';
import search from './search';
import settings from './settings';
import tcm from './tcm';
import themeEditor from './themeEditor';
import toaster from './toaster';
import users from './users';
import workflow from './workflow';

const reducer = combineReducers({
    [reduxPollingNamespace]: reduxPollingReducer,
    auth,
    apiCall,
    integration,
    toaster,
    loader,
    themeEditor,
    settings,
    dashboard,
    billing,
    search,
    workflow,
    pollApi,
    tcm,
    [globalAPI.reducerPath]: globalAPI.reducer,
    users,
    eep,
    integrationV2: integrationSlice.reducer,
    general: generalSlice.reducer,
    companyRegistrationData: registerSlice.reducer,
    [onBoardingAPI.reducerPath]: onBoardingAPI.reducer,
});

export default reducer;
