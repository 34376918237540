import { useEffect, useCallback } from 'react';

import { useLazyFetchAllWorkflowQuery } from 'redux/slices/globalApi';

const useFetchWorkflow = () => {
    const [fetchAllWorkflowApi, { data }] = useLazyFetchAllWorkflowQuery();

    const fetchAllWorkflow = useCallback(async () => {
        try {
            const query = {
                page: 0,
                size: 50,
            };
            await fetchAllWorkflowApi(query).unwrap();
        } catch (err) {}
    }, [fetchAllWorkflowApi]);

    useEffect(() => {
        fetchAllWorkflow();
    }, [fetchAllWorkflow]);

    const defaultWorkflow = data?.workflows?.find((workflow) => workflow.is_default);

    return { data, defaultWorkflow, fetchAllWorkflow };
};

export default useFetchWorkflow;
