import React from 'react';

import { Box, Hidden, Typography } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Modal from 'components/ModalV2';
import PropTypes from 'prop-types';

import classes from './deleteBrandModals.module.css';

import MobileSelectBrand from '../MobileSelectBrand';
import SelectBrandDropdown from '../SelectBrandDropdown';

const DeleteInitialStepModal = ({
    open,
    onClose,
    onNext,
    brandName,
    options,
    selectedBrand,
    setSelectedBrand,
    isNextDisabled,
}) => {
    const onCloseExtended = () => {
        onClose();
        setSelectedBrand('');
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box className={classes.container}>
                <FormHeader title="Step 1 - Confirmation" handleClose={onClose} />
                <Typography className={classes.text}>
                    Before deleting <strong>"{brandName}"</strong> you need to move your listings
                    into another brand.
                </Typography>
                <Hidden xsDown>
                    <SelectBrandDropdown
                        value={selectedBrand}
                        setValue={setSelectedBrand}
                        options={options}
                    />
                </Hidden>
                <Hidden smUp>
                    <MobileSelectBrand
                        brands={options}
                        selectedBrand={selectedBrand}
                        setSelectedBrand={setSelectedBrand}
                    />
                </Hidden>
                <FormFooter
                    formType="onClick"
                    primaryBtnHandler={onNext}
                    secondaryBtnHandler={onCloseExtended}
                    secondaryBtnText="Cancel"
                    btnText="Next"
                    isPrimaryBtnDisabled={isNextDisabled}
                />
            </Box>
        </Modal>
    );
};

DeleteInitialStepModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    brandName: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    selectedBrand: PropTypes.string,
    setSelectedBrand: PropTypes.func,
    isNextDisabled: PropTypes.bool,
};

export default DeleteInitialStepModal;
