import { useEffect } from 'react';
import { FormControl, MenuItem, Select, Grid } from '@material-ui/core';
import { useDropDownStyle } from '../../../styles/themeEditorStyles/commonStyle';
import { ReactComponent as DropDown } from 'asset/icons/pagination_dropdown.svg';

function Dropdown(props) {
    const classes = useDropDownStyle();
    const { name, value, onChange, options = [{}], variant = 'outlined', label, title } = props;

    useEffect(() => {
        if (!value) {
            onChange && onChange({ name, value: options[0].value });
        }
    });

    const handleChange = (evt) => {
        const { value } = evt.target;
        onChange && onChange({ name, value });
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={title === 'Font style' ? 4 : 6}>
                {title}
            </Grid>
            <Grid item xs={title === 'Font style' ? 8 : 6}>
                <FormControl
                    variant={variant}
                    style={{
                        width: title === 'Modal Border Radius' ? '50%' : '100%',
                        float: title === 'Modal Border Radius' && 'right',
                    }}
                    className={classes.formControl}
                >
                    <Select
                        value={value}
                        onChange={handleChange}
                        label={label}
                        className={classes.themeDropDown}
                        IconComponent={DropDown}
                        classes={{
                            outlined: classes.selectoutlined,
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default Dropdown;
