import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';

import classes from './processing.module.css';

import { ReactComponent as Spinner } from 'asset/img/spinner.svg';

const Processing = () => {
    return (
        <Box className={classes.wrapper}>
            <Button
                intent="secondary"
                className={classes.btn}
                startIcon={<Spinner className={classes.loader} />}
            >
                Processing
            </Button>
            <Typography className={classes.text}>
                In the meantime you can set up your Integration!
            </Typography>
        </Box>
    );
};

export default Processing;
