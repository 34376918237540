import { createSlice } from '@reduxjs/toolkit';

// for general setting that is not related to any view
const initialState = {
    openChatWidget: false,
    bearerToken: undefined,
    isVerifyEmailOptionVisible: true,
    verifyEmailTimer: null,
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setOpenChatWidget: (state, action) => {
            state.openChatWidget = action.payload;
        },
        storeBearerToken: (state, action) => {
            state.bearerToken = action.payload;
        },
        clearBearerToken: (state) => {
            state.bearerToken = undefined;
        },
        submitVerifyEmailTimer: (state, action) => {
            state.verifyEmailTimer = action.payload;
            state.isVerifyEmailOptionVisible = true;
        },
        hideVerifyEmailOption: (state) => {
            state.isVerifyEmailOptionVisible = false;
        },
        clearVerifyEmailOptionState: (state) => {
            state.verifyEmailTimer = null;
            state.isVerifyEmailOptionVisible = true;
        },
    },
});

export const {
    setOpenChatWidget,
    storeBearerToken,
    clearBearerToken,
    submitVerifyEmailTimer,
    hideVerifyEmailOption,
    clearVerifyEmailOptionState,
} = generalSlice.actions;

export default generalSlice.reducer;
