import actionType from 'redux/actions/actionConstant';

const initialState = {
    open: false,
    message: '',
    variant: '',
};

export default function toaster(state = initialState, action) {
    switch (action.type) {
        case actionType.OPEN_TOASTER:
            return {
                ...state,
                open: true,
                message: action.payload.message,
                variant: action.payload.variant,
            };
        case actionType.CLOSE_TOASTER:
            return {
                ...state,
                open: false,
                message: '',
            };
        default:
            return state;
    }
}
