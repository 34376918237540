import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const ButtonWrapper = ({
    type,
    btnText,
    variant,
    btnColor,
    style,
    className,
    disabled,
    handleOnClick,
    ...rest
}) => {
    return (
        <Button
            type={type}
            color={btnColor}
            disabled={disabled}
            style={style}
            variant={variant ? 'contained' : 'outlined'}
            onClick={handleOnClick}
            className={`${className}`}
            {...rest}
        >
            {btnText}
        </Button>
    );
};

ButtonWrapper.propTypes = {
    type: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    variant: PropTypes.bool,
    btnText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    btnColor: PropTypes.string,
    className: PropTypes.string,
    handleOnClick: PropTypes.func,
};

ButtonWrapper.defaultProps = {
    variant: true,
};

export default ButtonWrapper;
