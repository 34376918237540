import { datadogRum } from '@datadog/browser-rum';
import { ANALYTICS, APPLICATION, ENV } from 'config';

// @see https://github.com/DataDog/rum-react-integration-examples?tab=readme-ov-file
// @see https://www.datadoghq.com/blog/datadog-rum-react-components/
// @see https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm

const { DATADOG } = ANALYTICS;

export function initialize() {
    if (typeof window === 'undefined' || !DATADOG.RUM.ENABLED) {
        console.info('product analytics disabled');
        return;
    }

    datadogRum.init({
        applicationId: DATADOG.RUM.APPLICATION_ID,
        clientToken: DATADOG.RUM.CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'customer-portal',
        // Specify a version number to identify the deployed version of your application in Datadog
        version: `${APPLICATION.VERSION}-${ENV}`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100, // Disable's session replays
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        storeContextsAcrossPages: true,
        defaultPrivacyLevel: 'mask-user-input',
        env: ENV,
    });
}

export const setUser = ({ uuid, first_name, last_name, company_name, company_id }) => {
    if (!DATADOG.RUM.ENABLED) {
        return;
    }

    datadogRum.setUser({
        id: uuid,
        name: `${first_name} ${last_name}`,
        company: { name: company_name, uuid: company_id },
    });
};
export const setUserProperty = DATADOG.RUM.ENABLED ? datadogRum.setUserProperty : () => {};
