import React, { useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useMoreOptionStyles } from './styles';

import { ReactComponent as MoreVertIcon } from 'asset/IconsPack/hx_dots-vertical-filled.svg';

function MoreOption({ disabled, handleDeleteBrand }) {
    const classes = useMoreOptionStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const onClickOption = () => {
        setAnchorEl(null);
        handleDeleteBrand();
    };

    return (
        <>
            <IconButton
                className={classes.moreOptionBtn}
                aria-controls={openMenu ? 'edit-brand-option' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                disabled={disabled}
            >
                <MoreVertIcon className={classes.menuIcon} />
            </IconButton>
            <Menu
                id="edit-brand-option"
                className={classes.brandOptionDropdown}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={onClickOption} className={classes.menuItems}>
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
}

MoreOption.propTypes = {
    handleDeleteBrand: PropTypes.func,
    disabled: PropTypes.bool,
};

export default MoreOption;
