import React from 'react';

import { Box, Typography } from '@material-ui/core';

import classes from './loadingSpinner.module.css';

import { ReactComponent as Spinner } from 'asset/img/spinner.svg';

const LoadingSpinner = () => {
    return (
        <Box className={classes.container}>
            <Spinner className={classes.spinner} />
            <Typography className={classes.text}>Loading</Typography>
        </Box>
    );
};

export default LoadingSpinner;
