import actionType from 'redux/actions/actionConstant';

const initialState = {
    lastCall: 0,
    notifications: {
        notifiedLowBalance: undefined,
    },
    signDocument: {
        status: undefined,
        session: undefined,
        signedDate: undefined,
    },
    graphData: {},

    generatedUserPdfReports: {},
};

export default function apiCall(state = initialState, action) {
    switch (action.type) {
        case actionType.API_CALL_TIME_CHANGED:
            return {
                ...state,
                lastCall: action.payload.time,
            };
        case actionType.SIGN_DOCUMENT_STATUS_SUCCESS:
            return {
                ...state,
                signDocument: {
                    ...state.signDocument,
                    status: action.payload,
                },
            };

        case actionType.SIGN_DOCUMENT_SESSION_SUCCESS:
            return {
                ...state,
                signDocument: {
                    ...state.signDocument,
                    session: action.payload,
                },
            };

        case actionType.DOCUMENT_SIGNED_DATE:
            return {
                ...state,
                signDocument: {
                    ...state.signDocument,
                    signedDate: action.payload,
                },
            };

        case actionType.CLEAR_DOCUMENT_SIGNED_DATE:
            return {
                ...state,
                signDocument: {
                    ...state.signDocument,
                    signedDate: undefined,
                },
            };

        case actionType.NOTIFIED_LOW_BALANCE:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    notifiedLowBalance: true,
                },
            };

        case actionType.UPDATE_GENERATED_PDF_REPORTS:
            return {
                ...state,
                generatedUserPdfReports: action.payload,
            };

        case actionType.RESET_NOTIFIED_LOW_BALANCE:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    notifiedLowBalance: undefined,
                },
            };
        case actionType.GET_GRAPH_STATISTICS_SUCCESS:
            return {
                ...state,
                graphData: action.payload,
            };
        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                graphData: {},
                usersData: undefined,
                userLog: [],
                lastCall: 0,
                pagination: undefined,
                notifications: {
                    notifiedLowBalance: undefined,
                },
                signDocument: {
                    status: undefined,
                    session: undefined,
                },
            };
        default:
            return state;
    }
}
