import React from 'react';

import Chip from 'components/V2/Chip';
import PropTypes from 'prop-types';

import classes from './searchBrandAndListings.module.css';

import { ReactComponent as CrossIcon } from 'asset/IconsPack/at_x-close.svg';

const Tag = ({ content, onDelete }) => {
    return (
        <Chip
            content={content}
            clickable={false}
            deleteIcon={<CrossIcon />}
            onDelete={onDelete}
            classes={{
                deletable: classes.tagWrapper,
                label: classes.tagLabel,
                deleteIcon: classes.deleteIcon,
            }}
        />
    );
};

Tag.propTypes = {
    content: PropTypes.string,
    onDelete: PropTypes.func,
};

export default Tag;
