import React, { forwardRef } from 'react';

import { Box } from '@material-ui/core';
import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import PropTypes from 'prop-types';
import ListingAlreadyExists from 'views/V2/Integration/components/ListingAlreadyExists';
import ListingsTable from 'views/V2/Integration/components/ListingsTable';
import NoResultFound from 'views/V2/Integration/components/NoResultFound';

import classes from './listingsContent.module.css';

const ListingsReusableContent = forwardRef(
    (
        {
            handleScroll,
            header,
            rows,
            isFetching,
            isLoading,
            addSelect,
            totalListingsCount,
            currentBrandListings,
            setIsMoveDisabled,
            selectedListings,
            scrollWrapperClass,
            ...rest
        },
        ref
    ) => (
        <Box className={classes.contentWrapper}>
            {!currentBrandListings ? (
                <div
                    className={clsx(classes.tableWrapper, scrollWrapperClass)}
                    ref={ref}
                    onScroll={handleScroll}
                >
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {rows?.length > 0 ? (
                                <ListingsTable
                                    totalListingsCount={totalListingsCount}
                                    header={header}
                                    rows={rows}
                                    isFetching={isFetching}
                                    showCheckbox={addSelect}
                                    setIsMoveDisabled={setIsMoveDisabled}
                                    selectedListings={selectedListings}
                                    {...rest}
                                />
                            ) : (
                                <NoResultFound />
                            )}
                        </>
                    )}
                </div>
            ) : (
                <Box className={classes.indicatorWrapper}>
                    {rows.length > 0 ? (
                        <ListingAlreadyExists
                            listingName={currentBrandListings === 1 ? rows[0]?.name : ''}
                            brandName={rows[0]?.currentBrand}
                        />
                    ) : (
                        <Box className={classes.tableWrapper}>
                            <NoResultFound />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
);

ListingsReusableContent.propTypes = {
    handleScroll: PropTypes.func,
    header: PropTypes.array,
    rows: PropTypes.array,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    addSelect: PropTypes.bool,
    totalListingsCount: PropTypes.number,
    currentBrandListings: PropTypes.number,
    setIsMoveDisabled: PropTypes.func,
    selectedListings: PropTypes.array,
    scrollWrapperClass: PropTypes.string,
};

export default ListingsReusableContent;
