import { Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useThemeButtonStyle } from '../../../styles/themeEditorStyles/themeButton';
import { buttonThemeSet } from 'helper/constant';

const ThemeButton = ({ onChange, name, ...rest }) => {
    const classes = useThemeButtonStyle();

    const handleClick = (value) => {
        onChange && onChange({ name, value });
    };

    const selectedButton = (buttonNumber) => {
        const currentBtn =
            JSON.stringify(rest.value) === JSON.stringify(buttonThemeSet[`button_${buttonNumber}`]);
        return currentBtn ? 'btnBoxSelected' : '';
    };

    return (
        <div className="setWrapper">
            <Grid className={classes.btnSetContainer}>
                <Typography className={classes.set}>Set 1:</Typography>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(1)])}>
                    <Button
                        variant="contained"
                        className={clsx(classes.commonBtn, classes.containedSet_1)}
                        onClick={() => handleClick(buttonThemeSet.button_1)}
                    >
                        Button
                    </Button>
                </Grid>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(2)])}>
                    <Button
                        variant="outlined"
                        className={clsx(classes.commonBtn, classes.outlinedSet_1)}
                        onClick={() => handleClick(buttonThemeSet.button_2)}
                    >
                        Button
                    </Button>
                </Grid>
            </Grid>
            <Grid className={classes.btnSetContainer}>
                <Typography className={classes.set}>Set 2:</Typography>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(3)])}>
                    <Button
                        variant="contained"
                        className={clsx(classes.commonBtn, classes.containedSet_2)}
                        onClick={() => handleClick(buttonThemeSet.button_3)}
                    >
                        Button
                    </Button>
                </Grid>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(4)])}>
                    <Button
                        variant="outlined"
                        className={clsx(classes.commonBtn, classes.outlinedSet_2)}
                        onClick={() => handleClick(buttonThemeSet.button_4)}
                    >
                        Button
                    </Button>
                </Grid>
            </Grid>
            <Grid className={classes.btnSetContainer}>
                <Typography className={classes.set}>Set 3:</Typography>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(5)])}>
                    <Button
                        variant="contained"
                        className={clsx(classes.commonBtn, classes.containedSet_3)}
                        onClick={() => handleClick(buttonThemeSet.button_5)}
                    >
                        Button
                    </Button>
                </Grid>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(6)])}>
                    <Button
                        variant="outlined"
                        className={clsx(classes.commonBtn, classes.outlinedSet_3)}
                        onClick={() => handleClick(buttonThemeSet.button_6)}
                    >
                        Button
                    </Button>
                </Grid>
            </Grid>
            <Grid className={classes.btnSetContainer}>
                <Typography className={classes.set}>Set 4:</Typography>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(7)])}>
                    <Button
                        variant="contained"
                        className={clsx(classes.commonBtn, classes.containedSet_4)}
                        onClick={() => handleClick(buttonThemeSet.button_7)}
                    >
                        Button
                    </Button>
                </Grid>
                <Grid className={clsx(classes.btnBox, classes[selectedButton(8)])}>
                    <Button
                        variant="outlined"
                        className={clsx(classes.commonBtn, classes.outlinedSet_4)}
                        onClick={() => handleClick(buttonThemeSet.button_8)}
                    >
                        Button
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default ThemeButton;
