import superAgent from 'superagent';
import config from 'config';
import { fromPromise } from 'neverthrow';

const { API } = config;

const ContentType = {
    json: 'application/json',
};

export const pollingApi = ({ method, apiUrl, body, token, tokenType }) => {
    return fromPromise(
        superAgent(method, `${API.BASE_URL}/${apiUrl}`)
            .set('Content-Type', ContentType.json)
            .set(tokenType, token)
            .send(body),
        (error) => error
    );
};
