import React from 'react';

import { Box, Grid, ThemeProvider } from '@material-ui/core';
import {
    ContinueVerificationScreen,
    VerificationOptionsScreen,
    VerifyIdentityScreen,
} from 'components/MedallionPreview';
import useMuiTheme from 'hooks/useMuiTheme';
import PropTypes from 'prop-types';

import styles from './workflowThemePreview.module.css';

function WorkflowThemePreview({ workflowData, previewScreens }) {
    const theme = useMuiTheme(workflowData?.verify_ui_config?.theme);
    const themeLogo = workflowData?.verify_ui_config;

    return (
        <Box className={styles.previewBox}>
            <ThemeProvider theme={theme}>
                <Grid className={styles.mainSection} style={{ background: theme.overlayColor }}>
                    <Grid>
                        <VerifyIdentityScreen
                            button={theme.button}
                            themeLogo={themeLogo}
                            customScreenStyle={styles.previewScreens}
                        />
                    </Grid>
                    <Grid>
                        <VerificationOptionsScreen
                            themeLogo={themeLogo}
                            theme={theme}
                            customScreenStyle={styles.previewScreens}
                        />
                    </Grid>
                    {previewScreens === 3 && (
                        <Grid>
                            <ContinueVerificationScreen
                                theme={theme}
                                button={theme.button}
                                textInputVariant={theme.textInputVariant}
                                themeLogo={themeLogo}
                                customScreenStyle={styles.previewScreens}
                            />
                        </Grid>
                    )}
                </Grid>
            </ThemeProvider>
        </Box>
    );
}

WorkflowThemePreview.propTypes = {
    previewScreens: PropTypes.number,
    workflowData: PropTypes.object,
};

export default WorkflowThemePreview;
