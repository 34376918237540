import React from 'react';

import { Button, Checkbox, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import MedallionHeader from 'components/MedallionPreview/components/MedallionHeader';
import { useCommonScreesStyles } from 'components/MedallionPreview/styles';
import PropTypes from 'prop-types';

import { useVerifyIdentityStyles } from './styles';

import { ReactComponent as Clock } from 'asset/icons/theme_clock.svg';
import { ReactComponent as Lock } from 'asset/icons/theme_lock.svg';
import { ReactComponent as Shield } from 'asset/icons/theme_shield.svg';

function VerifyIdentityScreen({ themeLogo, customScreenStyle, ...props }) {
    const classes = useVerifyIdentityStyles();
    const styles = useCommonScreesStyles();

    return (
        <Box className={clsx(customScreenStyle, styles.cardWrapper)}>
            <MedallionHeader themeLogo={themeLogo} title="Identity Authentication" />

            <Box className={classes.contentWrapper}>
                <Box className={classes.rowContainer}>
                    <Box className={classes.rowIcon}>
                        <Lock />
                    </Box>
                    <Box className={classes.rowTxt}>
                        We use machine learning, computer vision, and AI for a forensic identity
                        authentication.
                    </Box>
                </Box>
            </Box>
            <Box className={classes.contentWrapper}>
                <Box className={classes.rowContainer}>
                    <Box className={classes.rowIcon}>
                        <Shield />
                    </Box>
                    <Box className={classes.rowTxt}>
                        Your information will only be used by{' '}
                        <a
                            href={'https://authenticate.com/'}
                            className={classes.weblink}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Authenticate.com
                        </a>{' '}
                        for verification purposes.
                    </Box>
                </Box>
            </Box>
            <Box className={classes.contentWrapper}>
                <Box className={classes.rowContainer}>
                    <Box className={classes.rowIcon}>
                        <Clock />
                    </Box>
                    <Box className={classes.rowTxt}>
                        This process takes only a couple of minutes.
                    </Box>
                </Box>
            </Box>
            <Box className={clsx(classes.contentWrapper, classes.terms)}>
                <Box className={classes.rowContainer}>
                    <Box className={classes.rowIcon}>
                        <Checkbox
                            classes={{
                                checked: classes.checked,
                            }}
                            className={classes.rowCheckbox}
                        />
                    </Box>
                    <Typography className={classes.rowTxt}>
                        I agree to{' '}
                        <a
                            href="https://authenticate.com/"
                            className={classes.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Authenticate
                        </a>
                        's{' '}
                        <a
                            href="https://authenticate.com/terms-of-use/"
                            className={classes.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms of Use
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://authenticate.com/privacy-policy/"
                            className={classes.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy.
                        </a>
                    </Typography>
                </Box>
            </Box>

            <Box className={clsx(classes.contentWrapper, classes.submitBtnWrapper)}>
                <Button
                    variant={props.button?.variant || 'contained'}
                    classes={{
                        contained: styles.contained,
                        outlined: styles.outlined,
                    }}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
}

VerifyIdentityScreen.propTypes = {
    title: PropTypes.string,
    themeLogo: PropTypes.any,
    button: PropTypes.object,
    customScreenStyle: PropTypes.string,
};

export default VerifyIdentityScreen;
