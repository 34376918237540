import apiCall from './apiRequest';
import endPoints from './endpoints';

export function activeApi(data, token) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.active,
        payload: data,
        authToken: token,
    });
}

export function settingApi(data, token) {
    return apiCall({
        method: 'get',
        endpoint: `${endPoints.setting}/${data.id}/settings`,
        payload: data,
        authToken: token,
    });
}

export function settingGetApi(data, token) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.settingGet,
        payload: data,
        authToken: token,
    });
}

export function getPaymentStatusApi(token, companyAccessCode) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.getRutterPaymentStatus,
        authToken: token,
        payload: null,
        query: `companyUUID=${companyAccessCode}`,
    });
}

export function setPaymentStatusApi(token, payload) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.setRutterPaymentStatus,
        authToken: token,
        payload,
    });
}

export function settingPostApi(data, token) {
    const { key, ...rest } = data;
    return apiCall({
        method: 'post',
        endpoint: `${endPoints.setting}/${key.id}/settings`,
        payload: rest,
        authToken: token,
    });
}

export function getProductsAPI(data, token) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.getProducts,
        authToken: token,
        query: data,
    });
}

export function getEnabledProductsAPI(data, token) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.getEnabledProducts,
        authToken: token,
        query: data,
    });
}

export function setEnabledProductsAPI(data, token) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.setEnabledProducts,
        payload: data,
        authToken: token,
    });
}
