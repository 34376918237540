export const workflowStyles = (theme) => ({
    root: {
        width: '100%',

        '&&& $input': {
            padding: '0',
        },

        '& input::placeholder': {
            fontFamily: 'Rubik',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#98A2B3',
        },
    },

    inputRoot: {
        fontFamily: 'Rubik',
        fontSize: '16px',
        lineHeight: '24px',
        gap: '8px',
        opacity: '0px',
        color: '#101828',
        borderRadius: '4px 4px 0px 0px',

        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            paddingLeft: '0',
        },

        '&[class*="MuiOutlinedInput-root"]': {
            padding: '10px 14px',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #ddd',
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #1BA1DB',
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #0B9AD8',
            boxShadow: '0px 0px 4px rgba(27, 161, 219, 0.5)',
        },
    },

    popupIndicator: {
        width: '20px',
        height: '20px',
        color: '#98A2B3',

        '&:hover': {
            background: 'none',
        },
    },

    endAdornment: {
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            top: '6px',
        },
    },

    paper: {
        position: 'relative',
    },

    option: {
        width: '100%',
        background: '#fff',
        fontFamily: 'Rubik',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#1D2939',
        padding: '5px 12px',

        '&:hover, &[aria-selected="true"]': {
            background: '#EFF8FF',
            fontWeight: '500',
        },
    },
});
