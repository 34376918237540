import {
    adminRole,
    adminTypes,
    breakPoints,
    voidDocumentStatus,
    adminAccountStatus,
    checksInfoUpdated,
    STRIPE_ERROR,
    CONSENT_MESSAGES,
    usaStates,
    RANGE_DATE_PICKER_MESSAGES,
    VERIFICATION_STATUS,
    VERIFICATION_STATUS_TYPES,
    VERIFICATION_TYPE_LABELS,
    ADMIN_ENGAGEMENT_TYPES,
    USER_SESSION_EXPIRE_MESSAGE,
    USER_RISK_SCORE,
    USER_RISK_INSIGHT,
    fakeUserInfo,
} from 'helper/constant';
import _ from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import routeEnpoints from 'route/endpoints';
import { DATE_PICKER_DURATION } from 'views/Home/components/constants';

import { dateRangeInputRegex } from './values';

const { COMPANY_ADMIN, COMPANY_VIEWER, COMPANY_OWNER } = adminTypes;

const clearAccessToken = (itemName = 'accessToken') => {
    localStorage.removeItem(itemName);
};

const setAccessToken = (token, itemName = 'accessToken') => {
    localStorage.setItem(itemName, JSON.stringify(token));
};

const getAccessToken = (itemName = 'accessToken') => JSON.parse(localStorage.getItem(itemName));

const getInitials = (string) => {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

const witnInOneMonth = (startDate, endDate) => {
    let nextDate = new Date(startDate);
    nextDate.setMonth(nextDate.getMonth() + 1);
    if (moment(nextDate).format('YYYY-MM-DD') >= moment(endDate).format('YYYY-MM-DD')) {
        return true;
    } else {
        return false;
    }
};
const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;
    });
};

const toCamelCase = (snake_case_object) => {
    var camelCaseObject = {};
    _.forEach(snake_case_object, function (value, key) {
        if (_.isArray(value)) {
            value = value.map((v) => {
                return toCamelCase(v);
            });
        } else if (_.isObject(value)) {
            value = toCamelCase(value);
        }
        camelCaseObject[_.camelCase(key)] = value;
    });
    return camelCaseObject;
};

const oneDayAgo = (lastDate) => {
    if (lastDate <= 0) return 0;
    const dates = new Date().getTime() - lastDate;
    const hoursBetweenDates = dates / (60 * 60 * 1000);

    return parseInt(hoursBetweenDates);
};

const handleEmptyValues = (values) => {
    return values === '' ? null : values;
};

const getTimeLineColor = (status) => {
    let dot_color, connector_color;

    switch (status) {
        case 'COMPLETED':
        case 'IN_PROGRESS':
        case 'NOT_APPLICABLE':
        case 'KBA_PENDING':
        case 'ID_PENDING':
        case 'BANK_PENDING':
            dot_color = 'active';
            connector_color = 'active';
            break;
        case 'ERROR':
            dot_color = 'failed';
            connector_color = 'connector_default';
            break;
        default:
            dot_color = 'dot_default';
            connector_color = 'connector_default';
    }

    return { dot_color, connector_color };
};

// Function for date formating in the required manner
export const formatDate = (date) => moment(date).format('MMM DD, YYYY [at] h:mm a');

const getIdVerificationTitle = ({ govt_id, kba, bank } = {}) => {
    let govt_label = '';
    let kba_label = '';
    let bank_label = '';
    let kba_separator = '';
    let bank_seprator = '';

    const enabledChecks = {};

    if (govt_id?.enabled) enabledChecks.govt_id = govt_id;
    if (kba?.enabled) enabledChecks.kba = kba;
    if (bank?.enabled) enabledChecks.bank = bank;

    const allMandatory = Object.values(enabledChecks).every((item) => item.mandatory === true);

    const separator = allMandatory ? ' and ' : ' or ';

    const areOtherChecksPresent = Object.keys(enabledChecks).length !== 1;

    // Labels
    govt_label = enabledChecks?.govt_id
        ? `Government ID${govt_id?.metadata?.livenessCheck?.enabled ? ' + Selfie' : ''}`
        : '';
    // Separator
    kba_separator = enabledChecks?.govt_id && areOtherChecksPresent ? separator : '';
    bank_seprator = enabledChecks?.bank && areOtherChecksPresent ? separator : '';

    kba_label = enabledChecks?.kba ? 'KBA' : '';
    bank_label = enabledChecks?.bank ? 'Financial Account Ownership' : '';

    if (govt_label !== '' && bank_label !== '' && kba_label === '') {
        return `${govt_label}${kba_separator}${bank_label}`.trim();
    }

    return `${govt_label}${kba_separator}${kba_label}${bank_seprator}${bank_label}`.trim();
};

const attachDateToStatus = (status, date) => {
    if (['Completed', 'Not Applicable'].includes(status)) {
        return date ? `Completed on ${formatDate(date)}` : '';
    }
    return status;
};

const extractWorkflowOverviewDetails = ({ workflow_config, workflow_status }, resultData) => {
    let timeLineItems = [];
    const workflowStartDate =
        workflow_status?.started_on && `Started on ${formatDate(workflow_status.started_on)}`;
    const disabledModes = ['PENDING', 'NOT_APPLICABLE'];
    const isAssigned = !!workflow_status?.started_on;
    const workflowError = workflow_status?.error;
    const workflowDetails = workflow_config?.workflow;
    const workFlowExtCheckDetails = workflow_config?.additional_checks_obj;
    if (!isAssigned) {
        return { isAssigned };
    }

    const convertStatus = {
        COMPLETED: 'Completed',
        ERROR: 'Failed',
        IN_PROGRESS: 'In Progress',
        PENDING: 'Not Started',
        NOT_APPLICABLE: 'Not Applicable',
        KBA_PENDING: 'In Progress - KBA verification pending.',
        ID_PENDING: 'In Progress - Government ID verification is pending.',
        BANK_PENDING: 'In Progress - Financial Account Ownership verification is pending.',
    };

    const generateEepCheckDetails = (workFlowExtCheckDetails, additional_checks) => {
        const eepCheckDetails = [];

        const checkTypeMap = {
            education: 'Education',
            employment: 'Employment',
            professional_license: 'Professional License',
        };

        for (const checkType in checkTypeMap) {
            const check = additional_checks[checkType];
            const workflowCheck = workFlowExtCheckDetails[checkType];

            if (workflowCheck?.enabled && check) {
                eepCheckDetails.push({
                    ...getTimeLineColor(check.status),
                    title: checkTypeMap[checkType],
                    checkType,
                    subTitle: attachDateToStatus(convertStatus[check.status], check.completed_at),
                    isStarted: !disabledModes.includes(check.status),
                });
            }
        }
        return eepCheckDetails;
    };

    const additionalChecksTitles = {
        authoritative_db: 'Authoritative Database Check',
        has_criminal_record: 'Criminal Check',
        seven_year_criminal_report: '7 Year Criminal Check',
        education: 'Education',
        employment: 'Employment',
        professional_license: 'Professional License',
        motor_vehicle_records: 'Motor Vehicle Record Check ',
    };

    const idDetails = workflow_status.id_verifications;
    const isSelfieSelected =
        workflow_config?.id_verifications_obj?.govt_id?.metadata?.livenessCheck?.enabled === true;
    const isSelfieSuccess =
        isSelfieSelected &&
        (resultData?.verifyUI?.passport?.face_match_score >= 70 ||
            resultData?.verifyUI?.scannerUser?.face_match_score >= 70);

    const allVerificationPending =
        idDetails?.status === 'PENDING' &&
        idDetails?.govt_id.status === 'PENDING' &&
        idDetails?.kba.status === 'PENDING' &&
        idDetails?.bank.status === 'PENDING';

    const anyCheckCompleted =
        idDetails?.status === 'COMPLETED' ||
        idDetails?.govt_id.status === 'COMPLETED' ||
        idDetails?.kba.status === 'COMPLETED' ||
        idDetails?.bank.status === 'COMPLETED';

    const anyChecksPending = [];
    const workflowConfigTest = workflow_config.id_verifications_obj;

    if (!allVerificationPending) {
        const verificationTypes = ['govt_id', 'kba', 'bank'];

        for (const type of verificationTypes) {
            const status = idDetails?.[type]?.status;
            const enabled = workflowConfigTest?.[type]?.enabled;

            if (status === 'PENDING' && enabled === true) {
                anyChecksPending.push(type);
            }
        }
    }

    let pendingCondition = '';

    if (
        !allVerificationPending &&
        anyChecksPending?.length > 0 &&
        idDetails?.status !== 'COMPLETED'
    ) {
        const isOrArePendingChecks = anyChecksPending?.length > 1 ? 'are ' : 'is ';
        pendingCondition = anyChecksPending
            .map((check) => {
                switch (check) {
                    case 'govt_id':
                        if (isSelfieSelected && !isSelfieSuccess)
                            return 'Government ID + Selfie verification';
                        return 'Government ID verification';
                    case 'kba':
                        return 'KBA verification';
                    case 'bank':
                        return 'Financial Account Ownership verification';
                    default:
                        return '';
                }
            })
            .filter((check) => check !== '')
            .join(', ');

        const inProgressText = `In Progress - ${pendingCondition} ${isOrArePendingChecks} pending.`;
        pendingCondition = inProgressText;
    }

    let pendingStatus = '';
    if (allVerificationPending) {
        pendingStatus = convertStatus['PENDING'];
    } else if (!allVerificationPending && anyCheckCompleted && idDetails?.status !== 'COMPLETED') {
        pendingStatus = convertStatus['IN_PROGRESS'];
    } else {
        pendingStatus = convertStatus[idDetails?.status];
    }

    const getStatusColor = idDetails?.status;

    // document verifications
    const idVerification = {
        ...getTimeLineColor(getStatusColor),
        title: getIdVerificationTitle(workflow_config.id_verifications_obj),
        subTitle:
            pendingCondition !== ''
                ? pendingCondition
                : attachDateToStatus(
                      pendingStatus,
                      idDetails?.govt_id.completed_at ||
                          idDetails?.kba.completed_at ||
                          idDetails?.bank.completed_at
                  ),
        isStarted: !disabledModes.includes(idDetails?.status),
    };
    timeLineItems.push(idVerification);

    // additional checks
    const additionalChecks = workflow_status.additional_checks || {};

    const checksToProcessSeparately = ['education', 'employment', 'professional_license'];
    // Generate workflow objects for EEP checks
    const eepCheckDetails = generateEepCheckDetails(workFlowExtCheckDetails, additionalChecks);

    Object.entries(additionalChecks).forEach(([check, checkDetails]) => {
        const checkEnabled = workflow_config.additional_checks_obj?.[check]?.enabled;

        // If the current check is an EEP check
        if (checksToProcessSeparately.includes(check)) {
            // Find the check object via checkType key in the object list from eepCheckDetails
            const matchingCheckDetail = eepCheckDetails.find(
                (currEepCheck) => currEepCheck.checkType === check
            );

            if (matchingCheckDetail) {
                timeLineItems.push(matchingCheckDetail);
                return;
            }
        }

        // revert last timeline connector color
        if (disabledModes.includes(checkDetails?.status) && timeLineItems?.length > 0) {
            const lastTimeLine = typeof timeLineItems.at === 'function' ? timeLineItems.at(-1) : {};
            const updatedTimeLines = timeLineItems.map((item) =>
                item.title === lastTimeLine?.title
                    ? {
                          ...item,
                          connector_color: checkEnabled ? 'connector_default' : 'connector_disable',
                      }
                    : item
            );
            timeLineItems = updatedTimeLines;
        }

        const authDbCheckEnabled = workflow_config.additional_checks_obj?.authoritative_db?.enabled;

        const status =
            check === 'authoritative_db' &&
            checkDetails.status === 'NOT_APPLICABLE' &&
            authDbCheckEnabled
                ? 'ERROR'
                : checkDetails.status;

        //verifying if user have uncertain criminal record
        const hasCriminalRecord =
            workflow_config?.additional_checks_obj?.has_criminal_record?.enabled;
        const authoritativeDbCompleted =
            workflow_status?.additional_checks?.authoritative_db?.status === 'COMPLETED';
        const criminalRecordNotApplicable =
            workflow_status?.additional_checks?.has_criminal_record?.status === 'NOT_APPLICABLE';

        const uncertainRecord =
            hasCriminalRecord && authoritativeDbCompleted && criminalRecordNotApplicable;

        const isSevenYearCheck = check === 'seven_year_criminal_report';
        const isCriminalRecordCheck = check === 'has_criminal_record';

        const completedAt =
            uncertainRecord || isCriminalRecordCheck
                ? additionalChecks?.['authoritative_db']?.completed_at
                : checkDetails?.completed_at;

        let isStarted = !disabledModes.includes(status);
        const isAddOn = !checkEnabled;
        const disabled7YearCheckAddOn = uncertainRecord && isSevenYearCheck;

        const hasCriminalCheckRun = resultData?.data?.backgroundCheck?.hasCriminalRecord !== null;
        if (
            isCriminalRecordCheck &&
            criminalRecordNotApplicable &&
            authoritativeDbCompleted &&
            hasCriminalCheckRun
        ) {
            isStarted = true;
        }

        const checkObj = {
            ...getTimeLineColor(status),
            title: additionalChecksTitles[check],
            subTitle: attachDateToStatus(convertStatus[status], completedAt),
            isStarted,
            isAddOn,
            disabled7YearCheckAddOn,
        };

        timeLineItems.push(checkObj);
    });

    let isSSnVerified = resultData?.data?.SSNVerification?.isSSNVerified;

    if (additionalChecks?.['authoritative_db']?.status === 'COMPLETED' && isSSnVerified) {
        timeLineItems.push({
            ...getTimeLineColor('COMPLETED'),
            title: 'SSN Verification',
            subTitle: attachDateToStatus(
                convertStatus['COMPLETED'],
                additionalChecks?.['authoritative_db']?.completed_at
            ),
            isStarted: true,
            isAddOn: true,
        });
    }

    return {
        workflowStartDate,
        timeLineItems,
        isAssigned,
        workflowError,
        workflowDetails,
        getStatusColor,
    };
};

function transformBavData(paginatedAccountDetails) {
    const fieldMappings = {
        first_name: 'First Name',
        first_name_match: 'First Name Match',
        last_name: 'Last Name',
        last_name_match: 'Last Name Match',
        middle_name: 'Middle Name',
        middle_name_match: 'Middle Name Match',
    };

    const transformedData = [];

    for (const originalItem of paginatedAccountDetails) {
        const transformedItem = {};

        for (const originalFieldName in fieldMappings) {
            const desiredFieldName = fieldMappings[originalFieldName];

            if (originalItem[originalFieldName] === '') {
                const matchField = originalFieldName + '_match';
                transformedItem[desiredFieldName] = 'NA';
                transformedItem[fieldMappings[matchField]] = 'NA';
            } else if (transformedItem[desiredFieldName] !== 'NA') {
                transformedItem[desiredFieldName] = originalItem[originalFieldName];
            }
        }

        if (originalItem?.phones !== null) {
            transformedItem.phones = [...originalItem.phones];
        } else {
            originalItem.phones = [{ number: 'NA', type: 'NA' }];
        }

        transformedData.push(transformedItem);
    }
    return transformedData;
}

const { xs, md, lg, xl, xxl } = breakPoints;

const getTotalTitleChar = (width) => {
    if (width < xxl && width > xl) {
        return 80;
    } else if (width < xl && width > lg) {
        return 45;
    } else if (width < lg && width > md) {
        return 25;
    } else if (width < md && width > xs) {
        return 15;
    } else {
        return 25;
    }
};

const getTotalDescChar = (width) => {
    if (width < xxl && width > xl) {
        return 120;
    } else if (width < xl && width > lg) {
        return 90;
    } else if (width < lg && width > md) {
        return 70;
    } else if (width < md && width > xs) {
        return 40;
    } else {
        return 35;
    }
};

const canShowSignNudge = (documentStatus, companyStatus, signedDate) => {
    if (!documentStatus || !companyStatus) {
        return false;
    }

    if (documentStatus === 'approved' && signedDate) {
        return true;
    }

    const allowedStatuses = ['initiated', 'awaiting_user', 'awaiting_admin'];
    return allowedStatuses.includes(documentStatus);
};

const documentSigningSteps = (documentData, companyData, loggedInAdminRole) => {
    const { status, signedDate } = documentData || {};
    const isItAllowedAdmin = checkAdminAccess(loggedInAdminRole, [COMPANY_OWNER]);
    const topUpTitle = isItAllowedAdmin
        ? 'To activate your account, you will need to add a payment method, and top-up your account with a minimum of $100.'
        : 'To activate your account, ask the company owner to add a card and top-up your account with a minimum of $100.';

    const companyStatus = companyData?.status;
    const documentStatus = ['rejected', 'revoked'].includes(companyStatus)
        ? companyStatus
        : ['initiated', 'awaiting_user', 'awaiting_admin', 'approved'].includes(
              status?.documentStatus
          )
        ? status?.documentStatus
        : companyData?.meta_data?.agreement_status;

    const stepTitles = {
        1: {
            initiated: 'Review agreement',
            awaiting_user: 'Review agreement',
            awaiting_admin: 'Your account is being reviewed',
            approved:
                'Your account has been reviewed & a signed copy of the agreement has been sent to your email address',
        },
        2: {
            pending: 'Add payment method & top-up your account',
            approved: 'Payment method has been added & your account has been topped-up',
        },
        3: {
            pending: 'Get your API credentials',
            approved: 'Get your API credentials',
        },
    };
    const stepContents = {
        1: {
            initiated:
                'To activate your account, you will need to review & sign the customer agreement.',
            awaiting_user:
                'To activate your account, you will need to review & sign the customer agreement.',
            awaiting_admin:
                'Thanks for signing the agreement. You’ll receive an email copy once our team has reviewed your account & counter-signed the agreement—which should take up to 24 hours.',
        },
        2: {
            pending: topUpTitle,
        },
    };
    const stepStatus = {
        initiated: 'default',
        awaiting_user: 'default',
        awaiting_admin: 'pending',
        approved: 'completed',
        pending: 'locked',
    };

    const documentSigned = documentStatus === 'approved' && companyStatus === 'pending';

    const canShow = canShowSignNudge(documentStatus, companyStatus, signedDate);

    const statusDisableReviewBtn = [...voidDocumentStatus, 'document.uploaded'];

    const stepsList = [
        {
            stepCount: 1,
            status: stepStatus[documentStatus],
            title: stepTitles[1]?.[documentStatus] || '',
            content: stepContents[1]?.[documentStatus] || '',
            link: ['awaiting_user', 'initiated'].includes(documentStatus) ? '/signDocument' : '',
            linkText: 'Review agreement',
            isExternalLink: true,
            disabled: statusDisableReviewBtn.includes(status?.pandaDocStatus),
        },
        {
            stepCount: 2,
            status: documentSigned ? 'default' : stepStatus[companyStatus],
            title: stepTitles[2]?.[companyStatus] || '',
            content: documentSigned ? stepContents[2]?.[companyStatus] : '',
            link: documentSigned ? '/billing/dashboard' : '',
            linkText: 'View Billing',
            toolTip:
                documentStatus !== 'approved' &&
                'To add payment method & top-up your account, you will need to complete the customer agreement signing process',
            disabled: !isItAllowedAdmin,
        },
        {
            stepCount: 3,
            status: stepStatus[companyStatus],
            title: stepTitles[3]?.[companyStatus] || '',
            content: '',
            link: companyStatus === 'approved' ? '/settings' : '',
            linkText: 'View API credentials',
            toolTip:
                companyStatus === 'pending' &&
                'To get your API credentials, you will need to add a payment method & top-up your account',
        },
    ];

    return { stepsList, canShow };
};

const removeStringNull = (value) => {
    return `${value}`.includes('null') ? null : value;
};

const findTotalPrice = (listOfValues) => {
    return listOfValues.reduce(
        (acc, val) => (parseInt(val) ? acc + parseFloat(val) : acc),
        0 //initial value
    );
};

const validateExtendedChecks = (props) => {
    const { balance = 0, totalPrice = 0, countiesInProgressChecked = [], counties = [] } = props;

    if (balance < totalPrice) {
        return {
            type: 'low balance',
            error: true,
            balance,
        };
    }

    //verifying counties already executed checks
    const condition = (obj1, obj2) =>
        obj1?.CountyCriminalPrice?.uuid === obj2?.countyUUID && obj1?.status === 'PENDING';

    const validateCounties = _.filter(countiesInProgressChecked, (obj1) =>
        _.find(counties, (obj2) => condition(obj1, obj2))
    );

    if (validateCounties.length > 0) {
        const uniqueCounties = _.uniqBy(validateCounties, 'CountyCriminalPrice.uuid');
        return {
            type: 'county exists',
            error: true,
            counties: uniqueCounties,
            className: 'county_exist_error',
        };
    }

    return { error: false };
};

const extendedChecksDetails = (listOfChecks = []) => {
    const checkTypes = {
        CIVIL_COUNTY: 'county civil check',
        CRIMINAL: 'county criminal check',
        CRIMINAL_FEDERAL: 'federal criminal check',
        CIVIL_FEDERAL: 'federal civil check',
    };

    const extendedChecks = { canShow: false, checks: {} };
    let eachIteration = listOfChecks;

    Object.keys(checkTypes).forEach((type) => {
        const specificChecks = [];
        const checkName = checkTypes[type];

        eachIteration = eachIteration.filter((check) => {
            if (check?.type === type) {
                specificChecks.push(check);
            }
            return check?.type !== type;
        });

        if (specificChecks.length > 0) {
            extendedChecks['checks'][checkName] = ['CIVIL_COUNTY', 'CRIMINAL'].includes(type)
                ? _.groupBy(
                      specificChecks,
                      (obj) => `${obj?.jurisdiction_county}, ${obj?.jurisdiction_state}`
                  )
                : specificChecks;
            extendedChecks['canShow'] = true;
        }
    });

    return extendedChecks;
};

const getPrintableValue = (value) => {
    return value || '';
};

const getPrintableValueFedralCounty = (value) =>
    value !== null && value !== '' && value !== undefined ? value : 'NA';

function getYesOrNo(isTrue) {
    return isTrue ? 'Yes' : 'No';
}

const getAdminDetails = (company, admin) => {
    let fullName = '';

    if (!admin) return { fullName: company?.contact_name, email: company?.contact_email };

    const { first_name, last_name, email } = admin;
    fullName = `${first_name || ''} ${last_name || ''}`.trim() || email.split('@')[0];

    return {
        fullName,
        firstName: first_name,
        lastName: last_name,
        email,
    };
};

const getMemberModifyFormInitialState = (formType, member, cachedFormValue) => {
    if (formType === 'createMember') {
        const { first_name, last_name, email, type } = cachedFormValue || {};

        return {
            firstName: first_name,
            lastName: last_name,
            role: type,
            email: email,
        };
    }

    const { first_name, last_name, email, type, verified, uuid } = member;

    if (formType === 'updateMember') {
        const update = {
            firstName: first_name,
            lastName: last_name,
            userAccessCode: uuid,
        };

        //can't change email address of verified member
        if (!verified) {
            update['email'] = email;
        }
        return update;
    }

    if (formType === 'changeRole') {
        return {
            role: type,
            userAccessCode: uuid,
            firstName: first_name,
            lastName: last_name,
        };
    }
};

const getConfirmMessageForRBAC = (member, confirmType) => {
    const { first_name, last_name, email, type, previousRole } = member;

    if (confirmType === 'resendInvitation') {
        return `Are you sure you want to resend invitation to ${first_name} ${last_name} (${email})?`;
    }

    if (confirmType === 'reActivate') {
        return `Are you sure you want to re-activate member ${first_name} ${last_name} (${email})?`;
    }

    if (confirmType === 'createMember') {
        return `Are sure you want to add ${first_name} ${last_name} (${email}) as an ${adminRole[type]}?`;
    }

    if (confirmType === 'changeRole') {
        return `Are sure you want to change ${first_name} ${last_name} role from ${adminRole[previousRole]} to ${adminRole[type]}?`;
    }

    if (confirmType === 'suspend') {
        return `Are sure you want to suspend  ${first_name} ${last_name} (${email})?`;
    }
};

const { ACTIVE, PENDING, SUSPENDED } = adminAccountStatus;

const returnAdminAccountStatus = (verified, deletedAt) => {
    if (deletedAt) {
        return SUSPENDED;
    } else if (verified) {
        return ACTIVE;
    } else {
        return PENDING;
    }
};

const checkAdminAccess = (loggedInAdmin, allowedAdmins = []) => {
    const role = adminRole[loggedInAdmin];

    return allowedAdmins.includes(role);
};

const isRouteRestricted = (route, authData = {}) => {
    const { type, company } = authData;

    const { meta_data } = company || {};

    const agreementSigned = meta_data ? meta_data.agreement_status === 'approved' : true;
    const loggedInAdminOrViewer = checkAdminAccess(type, [COMPANY_ADMIN, COMPANY_VIEWER]);

    if (route === 'Billing' && !agreementSigned) return true;

    if (route === 'Billing' && loggedInAdminOrViewer) return true;
};

const processFullNameWithNull = (firstName, lastName) => {
    if (firstName) {
        if (lastName) {
            return `${firstName} ${lastName}`;
        } else {
            return firstName;
        }
    } else if (lastName) {
        return lastName;
    } else return null;
};

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-us').format(number);
};

const intializeValues = (data) => {
    if (data?.with_records !== undefined || data?.without_records !== undefined) {
        return {
            with_records: data?.with_records || 0,
            without_records: data?.without_records || 0,
        };
    } else if (data?.uncertain !== undefined || data?.verified !== undefined) {
        return {
            uncertain: data?.uncertain || 0,
            verified: data?.verified || 0,
        };
    } else {
        return {
            uncertain: 0,
            verified: 0,
        };
    }
};

// workflow function to return text with and/or options
const getIdentityOptions = (formik, option) => {
    const identityValues = {
        'Government ID': formik.values.govt_id,
        KBA: formik.values.kba,
        'Financial Account Ownership': formik.values.bank,
    };

    // function for render and/or with enabled options
    const selectedTitles = Object.keys(identityValues)
        .filter((title) => identityValues[title] === true)
        .join(` ${option} `);

    // function to check is more than one verification is enabled
    const totalChecks = Object.values(identityValues).filter((value) => value === true).length;
    const moreThanOneEnabled = totalChecks > 1;

    return { selectedTitles, moreThanOneEnabled };
};

const verificationConfiguration = (workflowOverview) => {
    const idVerification = workflowOverview?.workflow_config?.id_verifications_obj;
    const enabledVerifications = [];
    const mandatoryVerifications = [];

    for (const key in idVerification) {
        const check = idVerification[key];
        if (check.enabled) {
            enabledVerifications.push(check.verification_type);
            if (check.mandatory) {
                mandatoryVerifications.push(check.verification_type);
            }
        }
    }

    const allOrAnyMethod =
        mandatoryVerifications.length === enabledVerifications.length ? 'AND' : 'OR';

    return enabledVerifications.length === 1 ? 'SINGLE' : allOrAnyMethod;
};

const areAllIdentityChecksPending = (workflow_config, workflow_status) => {
    if (workflow_config) {
        // selecting all the configured identity checks for an user.
        let selectedChecks = Object.keys(workflow_config)?.filter(
            (key) => key !== 'verification_option' && key !== 'additional_checks_obj'
        );
        return selectedChecks.every((check) => workflow_status[check]?.status === 'PENDING');
    }
};

const getVerificationMetrics = (workflowIdVerificationObj, getTestResultData) => {
    let allowedVerifications = 0;
    let completedVerifications = 0;
    let totalRemainAttempts = 0;

    const kbaConfig = workflowIdVerificationObj?.kba;
    const bavConfig = workflowIdVerificationObj?.bank;
    const govtIdConfig = workflowIdVerificationObj?.govt_id;

    const { identityProof, bankAccountOwnerMatchResult, verifyUI, identityAttempts } =
        getTestResultData ?? {};

    const {
        kba: kbaAttempts = 0,
        financial_account_login: bavAttempts = 0,
        government_id: idvAttempts = 0,
        passport: passportAttempts = 0,
    } = identityAttempts ?? {};

    if (kbaConfig) {
        allowedVerifications++;

        if (identityProof?.status) {
            completedVerifications++;
        }

        const allowedAttempts = kbaConfig.attempts;
        const takenAttempts = kbaAttempts;
        const remainAttempts = Math.abs(allowedAttempts - takenAttempts);

        totalRemainAttempts += remainAttempts;
    }

    if (bavConfig) {
        allowedVerifications++;

        if (bankAccountOwnerMatchResult?.is_owner_matched) {
            completedVerifications++;
        }

        const allowedAttempts = bavConfig.attempts;
        const takenAttempts = bavAttempts ?? bankAccountOwnerMatchResult?.attempts;

        const remainAttempts = Math.abs(allowedAttempts - takenAttempts);

        totalRemainAttempts += remainAttempts;
    }

    if (govtIdConfig) {
        allowedVerifications++;

        const { attempts: allowedAttempts } = govtIdConfig;

        const idvProcessed = parseInt(verifyUI?.scannedUser?.confidence_rating) >= 75;
        const passportProcessed =
            verifyUI?.passport?.result === 'complete' &&
            verifyUI?.passport?.authentication_result === 'Passed';

        if (idvProcessed || passportProcessed) {
            completedVerifications++;
        }

        const govtIdTakenAttempts = idvAttempts + passportAttempts;

        const remainAttempts = Math.abs(allowedAttempts - govtIdTakenAttempts);

        totalRemainAttempts += remainAttempts;
    }

    const pendingVerifications = allowedVerifications - completedVerifications;

    return {
        allowedVerifications,
        completedVerifications,
        totalRemainAttempts,
        pendingVerifications: pendingVerifications > 0 ? pendingVerifications : 0,
    };
};

const getFullName = (values) => {
    const { firstName, middleName, lastName } = values;
    const fullNameArray = [firstName, middleName, lastName].filter(Boolean); // Filter out empty values
    return fullNameArray.join(' ');
};

const totalChecksPercentage = (categoryValues, category, categorySum) => {
    if (categoryValues.length === 0 || categorySum[category] === 0) {
        return 0;
    }

    const currSum = categoryValues?.reduce((acc, curr) => acc + curr, 0);
    return Math.floor((currSum / categorySum[category]) * 100);
};

const getOrganizedData = (data, categorySum = {}) => {
    const docsVerifiedArray = [];
    const docsUncertainArray = [];
    const quizVerifiedArray = [];
    const quizUncertainArray = [];
    const financialAccountVerifiedArray = [];
    const financialAccountUncertainArray = [];

    for (const day in data?.statistics) {
        const dayData = data.statistics[day];

        // Push values to respective arrays
        docsVerifiedArray.push(dayData.docs.verified);
        docsUncertainArray.push(dayData.docs.uncertain);

        quizVerifiedArray.push(dayData.quiz.verified);
        quizUncertainArray.push(dayData.quiz.uncertain);

        financialAccountVerifiedArray.push(dayData.financialAccount.verified);
        financialAccountUncertainArray.push(dayData.financialAccount.uncertain);

        for (const category in dayData) {
            // Check if the category exists in the categorySum object
            if (!categorySum[category]) {
                categorySum[category] = 0; // Initialize the category sum if not present
            }

            // Calculate the sum of verified and uncertain for the current category
            const categoryTotal = dayData[category].verified + dayData[category].uncertain;

            // Add the categoryTotal to the existing sum in the categorySum object
            categorySum[category] += categoryTotal;
        }
    }

    // Creating an array of arrays
    const organizedData = [
        docsVerifiedArray,
        docsUncertainArray,
        quizVerifiedArray,
        quizUncertainArray,
        financialAccountVerifiedArray,
        financialAccountUncertainArray,
    ];
    return organizedData;
};

const getTopLabels = (data) => {
    const uniqueLabels = new Set();

    Object.keys(data?.statistics)?.forEach((duration) => {
        const timePeriod = data?.statistics[duration];
        const checkLabels = Object?.keys(timePeriod);

        checkLabels?.forEach((check) => {
            uniqueLabels.add(`${check} Verified`);
            uniqueLabels.add(`${check} Uncertain`);
        });
    });

    return [...uniqueLabels];
};
function isDateFormat(key) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(key);
}
function isDateRangeFormat(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}\s*-\s*\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
}

function formatWeekRange(date) {
    const startDate = new Date(date);
    const endDate = new Date(date);

    endDate.setDate(startDate.getDate() + 6);

    const options = { day: '2-digit', month: 'short' };
    const weekStartDate = startDate
        .toLocaleDateString('en-US', options)
        .replace(/(\d{2}) (\w{3})/, '$2 $1');
    const originalStartDate = weekStartDate.split(' ').reverse().join(' ');

    const weekEndDate = endDate
        .toLocaleDateString('en-US', options)
        .replace(/(\d{2}) (\w{3})/, '$2 $1');
    const originalEndDate = weekEndDate.split(' ').reverse().join(' ');
    return `${originalStartDate} - ${originalEndDate}`;
}

function formatDateRange(dateRange) {
    const [startDateString, endDateString] = dateRange.split(' - ');

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const options = { day: '2-digit', month: 'short' };

    const rangeStartDate = startDate
        .toLocaleDateString('en-US', options)
        .split(' ')
        .reverse()
        .join(' ');
    const rangeEndDate = endDate
        .toLocaleDateString('en-US', options)
        .split(' ')
        .reverse()
        .join(' ');

    return `${rangeStartDate} - ${rangeEndDate}`;
}

function stackedBarGraphConfig(datasets, data) {
    const graphLabelsFormat = _.mapKeys(data?.statistics, (value, key) => {
        if (isDateFormat(key)) {
            return formatWeekRange(key);
        } else if (isDateRangeFormat(key)) {
            return formatDateRange(key);
        } else {
            return _.capitalize(key);
        }
    });

    const chartData = {
        // X axis Lables
        labels: Object.keys(graphLabelsFormat),
        datasets: datasets,
    };

    const config = {
        type: 'bar',
        data: chartData,
        options: {
            plugins: {
                title: {
                    display: false,
                    text: 'Identity Verification Checks',
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        boxWidth: 12,
                        fontColor: '#474747',
                        fontFamily: 'Rubik',
                        fontSize: '30px',
                    },
                },
                tooltip: {
                    backgroundColor: '#262626',
                    padding: 8,
                    boxPadding: 4,
                    titleFont: {
                        weight: 'bold',
                        size: '14px',
                    },

                    callbacks: {
                        title: function (tooltipItem) {
                            const dataIndex = tooltipItem[0]?.dataIndex;
                            return tooltipItem[0]?.dataset?.data[dataIndex];
                        },
                        label: function (context) {
                            return context.dataset.label;
                        },
                    },
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                },
                y: {
                    stacked: true,
                },
            },
            barThickness: 30,
            inflateAmount: 0,
        },
    };

    return { chartData: chartData, config: config };
}

const homePageData = (
    meta_verify_docs,
    meta_verify_id,
    county_criminal_check,
    county_civil_check,
    federal_criminal_check,
    federal_civil_check,
    criminal_check,
    seven_year_report,
    document_verified,
    identity_verified,
    financial_account_verification
) => {
    return [
        {
            title: 'Identity Verifications',
            totalCount: Number(
                meta_verify_docs.uncertain +
                    meta_verify_docs.verified +
                    meta_verify_id.uncertain +
                    meta_verify_id.verified
            ),
            records: [
                {
                    label: 'Uncertain document proof',
                    count: meta_verify_docs.uncertain,
                    icon: 'warning',
                    percentage:
                        (meta_verify_docs.uncertain /
                            Number(
                                meta_verify_docs.uncertain +
                                    meta_verify_docs.verified +
                                    meta_verify_id.uncertain +
                                    meta_verify_id.verified
                            )) *
                        100,
                },
                {
                    label: 'Verified document proof',
                    count: meta_verify_docs.verified,
                    icon: 'success',
                    percentage:
                        (meta_verify_docs.verified /
                            Number(
                                meta_verify_docs.uncertain +
                                    meta_verify_docs.verified +
                                    meta_verify_id.uncertain +
                                    meta_verify_id.verified
                            )) *
                        100,
                },
                {
                    label: 'Uncertain identity',
                    count: meta_verify_id.uncertain,
                    icon: 'warning',
                    percentage:
                        (+meta_verify_id.uncertain /
                            Number(
                                meta_verify_docs.uncertain +
                                    meta_verify_docs.verified +
                                    meta_verify_id.uncertain +
                                    meta_verify_id.verified
                            )) *
                        100,
                },
                {
                    label: 'Verified identity',
                    count: meta_verify_id.verified,
                    icon: 'success',
                    percentage:
                        (+meta_verify_id.verified /
                            Number(
                                meta_verify_docs.uncertain +
                                    meta_verify_docs.verified +
                                    meta_verify_id.uncertain +
                                    meta_verify_id.verified
                            )) *
                        100,
                },
            ],
        },
        {
            title: 'Criminal check',
            totalCount: Number(criminal_check.with_records + criminal_check.without_records),
            records: [
                {
                    label: 'With records found',
                    count: criminal_check.with_records,
                    icon: 'warning',
                    percentage:
                        (+criminal_check.with_records /
                            Number(criminal_check.with_records + criminal_check.without_records)) *
                        100,
                },
                {
                    label: 'No records found',
                    count: criminal_check.without_records,
                    icon: 'success',
                    percentage:
                        (+criminal_check.without_records /
                            Number(criminal_check.with_records + criminal_check.without_records)) *
                        100,
                },
            ],
        },
        {
            title: 'Document Verified',
            totalCount: Number(document_verified?.uncertain + document_verified?.verified),
            records: [
                {
                    label: 'Uncertain document proof',
                    count: document_verified?.uncertain,
                    icon: 'warning',
                    percentage:
                        (+document_verified?.uncertain /
                            Number(document_verified?.uncertain + document_verified?.verified)) *
                        100,
                },
                {
                    label: 'Verified document proof',
                    count: document_verified?.verified,
                    icon: 'success',
                    percentage:
                        (+document_verified?.verified /
                            Number(document_verified?.uncertain + document_verified?.verified)) *
                        100,
                },
            ],
        },
        {
            title: 'Identitities Verified',
            totalCount: Number(identity_verified?.uncertain + identity_verified?.verified),
            records: [
                {
                    label: 'Uncertain identity',
                    count: identity_verified?.uncertain,
                    icon: 'warning',
                    percentage:
                        (+identity_verified?.uncertain /
                            Number(identity_verified?.uncertain + identity_verified?.verified)) *
                        100,
                },
                {
                    label: 'Verified identity',
                    count: identity_verified?.verified,
                    icon: 'success',
                    percentage:
                        (+identity_verified?.verified /
                            Number(identity_verified?.uncertain + identity_verified?.verified)) *
                        100,
                },
            ],
        },
        {
            title: 'Financial Accounts Details',
            totalCount: Number(
                financial_account_verification?.uncertain + financial_account_verification?.verified
            ),
            records: [
                {
                    label: 'Uncertain identity',
                    count: financial_account_verification?.uncertain,
                    icon: 'warning',
                    percentage:
                        (+financial_account_verification?.uncertain /
                            Number(
                                financial_account_verification?.uncertain +
                                    financial_account_verification?.verified
                            )) *
                        100,
                },
                {
                    label: 'Verified identity',
                    count: financial_account_verification?.verified,
                    icon: 'success',
                    percentage:
                        (+financial_account_verification?.verified /
                            Number(
                                financial_account_verification?.uncertain +
                                    financial_account_verification?.verified
                            )) *
                        100,
                },
            ],
        },
        {
            title: '7 year criminal activity check ',
            totalCount: Number(seven_year_report.with_records + seven_year_report.without_records),
            records: [
                {
                    label: 'With records found',
                    count: seven_year_report.with_records,
                    icon: 'warning',
                    percentage:
                        (+seven_year_report.with_records /
                            Number(
                                seven_year_report.with_records + seven_year_report.without_records
                            )) *
                        100,
                },
                {
                    label: 'No records found',
                    count: seven_year_report.without_records,
                    icon: 'success',
                    percentage:
                        (+seven_year_report.without_records /
                            Number(
                                seven_year_report.with_records + seven_year_report.without_records
                            )) *
                        100,
                },
            ],
        },
        {
            title: 'County criminal check',
            totalCount: Number(
                county_criminal_check.with_records + county_criminal_check.without_records
            ),
            records: [
                {
                    label: 'With records found',
                    count: county_criminal_check.with_records,
                    icon: 'warning',
                    percentage:
                        (+county_criminal_check.with_records /
                            Number(
                                county_criminal_check.with_records +
                                    county_criminal_check.without_records
                            )) *
                        100,
                },
                {
                    label: 'No records found',
                    count: county_criminal_check.without_records,
                    icon: 'success',
                    percentage:
                        (+county_criminal_check.without_records /
                            Number(
                                county_criminal_check.with_records +
                                    county_criminal_check.without_records
                            )) *
                        100,
                },
            ],
        },
        {
            title: 'County civil check ',
            totalCount: Number(
                county_civil_check.with_records + county_civil_check.without_records
            ),
            records: [
                {
                    label: 'With records found',
                    count: county_civil_check.with_records,
                    icon: 'warning',
                    percentage:
                        (+county_civil_check.with_records /
                            Number(
                                county_civil_check.with_records + county_civil_check.without_records
                            )) *
                        100,
                },
                {
                    label: 'No records found',
                    count: county_civil_check.without_records,
                    icon: 'success',
                    percentage:
                        (+county_civil_check.without_records /
                            Number(
                                county_civil_check.with_records + county_civil_check.without_records
                            )) *
                        100,
                },
            ],
        },
        {
            title: 'Federal criminal check',
            totalCount: Number(
                federal_criminal_check.with_records + federal_criminal_check.without_records
            ),
            records: [
                {
                    label: 'With records found',
                    count: federal_criminal_check.with_records,
                    icon: 'warning',
                    percentage:
                        (+federal_criminal_check.with_records /
                            Number(
                                federal_criminal_check.with_records +
                                    federal_criminal_check.without_records
                            )) *
                        100,
                },
                {
                    label: 'No records found',
                    count: federal_criminal_check.without_records,
                    icon: 'success',
                    percentage:
                        (+federal_criminal_check.without_records /
                            Number(
                                federal_criminal_check.with_records +
                                    federal_criminal_check.without_records
                            )) *
                        100,
                },
            ],
        },
        {
            title: 'Federal civil check ',
            totalCount: Number(
                federal_civil_check.with_records + federal_civil_check.without_records
            ),
            records: [
                {
                    label: 'With records found',
                    count: federal_civil_check.with_records,
                    icon: 'warning',
                    percentage:
                        (+federal_civil_check.with_records /
                            Number(
                                federal_civil_check.with_records +
                                    federal_civil_check.without_records
                            )) *
                        100,
                },
                {
                    label: 'No records found',
                    count: federal_civil_check.without_records,
                    icon: 'success',
                    percentage:
                        (+federal_civil_check.without_records /
                            Number(
                                federal_civil_check.with_records +
                                    federal_civil_check.without_records
                            )) *
                        100,
                },
            ],
        },
    ];
};

const buildApiUrl = (baseURL, filter_type = 'LAST_7_DAYS', start_date, end_date) => {
    const encodedFilter = encodeURIComponent(filter_type);

    if (filter_type === 'CUSTOM') {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        const sevenDaysAgoIso = sevenDaysAgo.toISOString();

        start_date = start_date === end_date ? sevenDaysAgoIso : start_date;
        end_date = start_date === end_date ? new Date().toISOString() : end_date;

        return `${baseURL}?filter_type=${encodedFilter}&start_date=${start_date}&end_date=${end_date}`;
    }
    return `${baseURL}?filter_type=${encodedFilter}`;
};

const calculateDateRange = (duration) => {
    const endDate = new Date();
    let startDate;

    switch (duration) {
        case DATE_PICKER_DURATION?.LAST_7_DAYS:
            startDate = new Date();
            startDate.setDate(endDate.getDate() - 7);
            break;

        case DATE_PICKER_DURATION?.LAST_4_WEEKS:
            startDate = new Date();
            startDate.setDate(endDate.getDate() - 28);
            break;

        case DATE_PICKER_DURATION?.LAST_3_MONTHS:
            startDate = new Date();
            startDate.setMonth(endDate.getMonth() - 3);
            break;

        default:
            startDate = new Date();
            startDate.setDate(endDate.getDate() - 7);
            break;
    }

    const formattedStartDate = startDate.toISOString();
    const formattedEndDate = endDate.toISOString();

    return {
        formatStartDate: formattedStartDate,
        formatEndDate: formattedEndDate,
    };
};

// Sum of the data according to the category
const getCategoryValues = (graphData) => {
    const overallSums = {
        docs: { verified: 0, uncertain: 0 },
        quiz: { verified: 0, uncertain: 0 },
        financialAccount: { verified: 0, uncertain: 0 },
    };

    // Iterate over days or whatever duration keys you will get from the backend
    for (const dayKey in graphData?.statistics) {
        const day = graphData?.statistics[dayKey];

        // Iterating over the categories like (docs, quiz, financialAccount)
        for (const categoryKey in overallSums) {
            const category = day[categoryKey] || { verified: 0, uncertain: 0 };

            // Updating the sums for each sub-category (verified, uncertain)
            for (const subCategoryKey in overallSums[categoryKey]) {
                const value = category[subCategoryKey];
                overallSums[categoryKey][subCategoryKey] += value;
            }
        }
    }
    const categoryMap = convertCategoryMapToPercentageMap(overallSums);
    return categoryMap;
};

const totalChecksRunOverall = (categoryMap) => {
    const totalSum = Object.values(categoryMap).reduce((acc, category) => {
        acc += category;
        return acc;
    }, 0);
    return totalSum.toLocaleString('en-US');
};

const convertCategoryMapToPercentageMap = (categoryMap) => {
    const percentageMap = {};

    Object.keys(categoryMap).forEach((category) => {
        const formattedCategory = category
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/Financial/i, 'Bank')
            .replace(/Account/i, 'Accounts')
            .replace(/\b\w/g, (match) => match.toUpperCase());

        const verifiedKey = `${formattedCategory} Verified`;
        const unverifiedKey = `${formattedCategory} Unverified`;

        percentageMap[verifiedKey] = categoryMap[category].verified || 0;
        percentageMap[unverifiedKey] = categoryMap[category].uncertain || 0;
    });

    return percentageMap;
};

function calendarDates(date) {
    const currDate = new Date(date);
    const day = currDate.getDate().toString().padStart(2, '0');
    const month = (currDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currDate.getFullYear();
    return `${month}/${day}/${year}`;
}

function isSevendaysDifference(startDate, endDate) {
    const startingDate = new Date(startDate);
    const endingDate = new Date(endDate);

    const timeDifference = Math.abs(endingDate.getTime() - startingDate.getTime());

    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference >= 7;
}

const extractMinutes = (timeStamp) => {
    const firstColonIndex = timeStamp?.indexOf(':');
    if (timeStamp && firstColonIndex !== -1) {
        const minutes = timeStamp?.slice(firstColonIndex + 1, firstColonIndex + 3);

        return parseInt(minutes, 10);
    }
};

function getStateDurationText(duration, dateRangeString = null) {
    if (duration === 'LAST_7_DAYS') {
        return 'Last 7 days';
    } else if (duration === 'LAST_4_WEEKS') {
        return 'Last 4 weeks';
    } else if (duration === 'LAST_3_MONTHS') {
        return 'Last 3 months';
    } else {
        return dateRangeString;
    }
}

const getModifiedCheckType = (checkType) => {
    const updatedText = checksInfoUpdated[checkType] || checkType;
    return checkType.includes('Bank Accounts')
        ? updatedText.replace('financial account ownership', 'financial account..')
        : updatedText;
};

const calculateTimeDifference = (timeObject) => {
    const now = moment();
    const duration = moment.duration(now.diff(timeObject));

    const minutes = duration.get('minutes');
    const seconds = duration.get('seconds');

    return { minutes, seconds };
};

// Function to format date as 'dd MMM yyyy'
const formatBtnDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
};

const graphDataFormatDates = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12, 12, 12, 12);
    return adjustedDate.toISOString();
};

const updationtimeMessage = (minutesDifference) => {
    if (minutesDifference < 1) {
        return 'Last updated less than a minute ago';
    } else if (minutesDifference > 1 && minutesDifference < 2) {
        return 'Last updated 1 minute ago';
    } else {
        return `Last updated ${minutesDifference} minutes ago.`;
    }
};

const isEndDateValid = (startDate, endDate) => {
    return new Date(startDate).getTime() < new Date(endDate).getTime();
};

const isDateInputFormatValid = (input) => {
    return dateRangeInputRegex.test(input);
};

const convertToFirstCapital = (text) => {
    const words = text.split(' ');

    const convertedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return convertedWords.join(' ');
};

const formatCountyHistoryDate = (dateObject) => {
    const dateString = `${dateObject?.Year}-${dateObject?.Month}-${dateObject?.Day ?? '01'}`;

    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        ...(dateObject?.Day && { day: 'numeric' }),
        year: 'numeric',
    }).format(date);
};

const assignUniqueIdToListOfObject = (list = []) => {
    if (!list) return list;

    const itemLists = list.map((item) => {
        const properties = { ...item };

        if (!properties?.id) {
            properties['id'] = nanoid(7);
        }

        return properties;
    });

    return itemLists;
};

const scrollToSpecificView = ({ viewId, property = { behavior: 'smooth' } }) => {
    const view = document.getElementById(viewId);

    if (view) {
        view.scrollIntoView(property);
    }
};

const getBalanceDetails = (currentCredit = {}) => {
    const { credit, dues } = currentCredit;

    let totalBalance = credit ? parseInt(credit?.balance) : 0;

    if (dues) {
        totalBalance = totalBalance - parseInt(dues.currentMonth) - parseInt(dues.previousMonth);
    }

    return {
        totalBalance,
        currentMonthDue: dues?.currentMonth ?? 0,
        previousMonthDue: dues?.previousMonth ?? 0,
    };
};

const getFieldTypeAndMessageForStripeErrorCode = (errorCode) => {
    const {
        CARD_DECLINED,
        EXPIRED_CARD,
        INCORRECT_CVC,
        INCORRECT_NUMBER,
        INVALID_CVC,
        INVALID_EXPIRY_MONTH,
        INVALID_EXPIRY_YEAR,
        INVALID_NUMBER,
        NO_ACCOUNT,
    } = STRIPE_ERROR.CODES;

    const errorMessage = {
        fieldType: '',
        message: STRIPE_ERROR.MESSAGES[errorCode],
    };

    if ([CARD_DECLINED, EXPIRED_CARD, NO_ACCOUNT].includes(errorCode)) {
        errorMessage.fieldType = 'default';
    }

    if ([INCORRECT_CVC, INVALID_CVC].includes(errorCode)) {
        errorMessage.fieldType = 'cardCvc';
    }

    if ([INCORRECT_NUMBER, INVALID_NUMBER].includes(errorCode)) {
        errorMessage.fieldType = 'cardNumber';
    }

    if ([INVALID_EXPIRY_MONTH, INVALID_EXPIRY_YEAR].includes(errorCode)) {
        errorMessage.fieldType = 'cardExpiry';
    }

    return errorMessage;
};

const checkAuthTokenExpiry = ({
    history,
    tokenExpireDate,
    redirectOnExpire = routeEnpoints.logout,
}) => {
    if (!tokenExpireDate) return false;
    const expireHours = Math.abs(oneDayAgo(new Date(tokenExpireDate).getTime()));
    if (expireHours <= 0)
        return history.push({
            pathname: redirectOnExpire,
            state: { message: USER_SESSION_EXPIRE_MESSAGE },
        });
};

const extractDomain = (value) => {
    let url = value;

    try {
        // Add protocol if missing to make parsing easier
        if (!url.startsWith('http')) {
            url = 'https://' + url;
        }
        // Parse the URL
        const parsedUrl = new URL(url);
        // Return the hostname
        return `${parsedUrl.hostname}`.toLowerCase();
    } catch (e) {
        console.error(`Error parsing URL: ${url}`, e);
        return null;
    }
};

const getSingleConsentMessage = (consent) => {
    switch (consent) {
        case 'background_check_disclosure_accepted':
            return CONSENT_MESSAGES.SINGLE_BACKGROUND_CHECK;
        case 'fcra_purpose':
            return CONSENT_MESSAGES.SINGLE_FCRA_PURPOSE;
        case 'glb_purpose_and_dppa_purpose':
            return CONSENT_MESSAGES.SINGLE_GLB_DPPA_PURPOSES;
        default:
            return null;
    }
};

const getDoubleConsentMessage = (missingConsents) => {
    const [consent1, consent2] = missingConsents;

    const conditions = [
        {
            check: ['fcra_purpose', 'background_check_disclosure_accepted'],
            message: CONSENT_MESSAGES.PERMISSION_AND_CONSENT,
        },
        {
            check: ['glb_purpose_and_dppa_purpose', 'background_check_disclosure_accepted'],
            message: CONSENT_MESSAGES.PERMISSIONS_AND_CONSENT,
        },
        {
            check: ['fcra_purpose', 'glb_purpose_and_dppa_purpose'],
            message: CONSENT_MESSAGES.SINGLE_GLB_DPPA_PURPOSES,
        },
    ];

    for (const { check, message } of conditions) {
        if (check.includes(consent1) && check.includes(consent2)) {
            return message;
        }
    }

    return null;
};

const getConsentMsg = (missingConsents) => {
    switch (missingConsents.length) {
        case 1:
            return getSingleConsentMessage(missingConsents[0]);
        case 2:
            return getDoubleConsentMessage(missingConsents) || CONSENT_MESSAGES.MULTIPLE_CONSENTS;
        default:
            if (missingConsents[0] === 'background_check_disclosure_accepted') {
                return CONSENT_MESSAGES.CONSENT_AND_PERMISSIONS;
            } else {
                return CONSENT_MESSAGES.PERMISSIONS_AND_CONSENT;
            }
    }
};

const getFormattedUsaStates = () => {
    return usaStates.map((state) => ({
        label: `${state.name.charAt(0) + state.name.slice(1).toLowerCase()} (${
            state.abbreviation
        })`,
        value: state.abbreviation,
    }));
};

const getWeekdayFirstLetter = (locale, date) => {
    const options = { weekday: 'short' };
    const weekday = new Intl.DateTimeFormat(locale, options).format(date);
    return weekday.charAt(0);
};

const convertToMMDDYYYY = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);

        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    }
};

const convertToDDMMYYYY = (dateString) => {
    let [month, day, year] = dateString.split('-');

    return `${day}-${month}-${year}`;
};

const validateDatePattern = (dateStr) => {
    const pattern = /^(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-\d{4}$/;
    return pattern.test(dateStr) ? null : 'Invalid date.';
};

const parseDate = (dateStr) => {
    const [month, day, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
};

const validateIssueDate = (date, today, expiryDate) => {
    if (date > today) {
        return 'Issued Date cannot be a future date.';
    }
    if (expiryDate) {
        const eDate = parseDate(expiryDate);
        if (date.getTime() === eDate.getTime()) {
            return 'Issued Date cannot be same as Expiry Date.';
        }
        if (date > eDate) {
            return 'Issued Date cannot be after Expiry Date.';
        }
    }
    return null;
};

const validateExpiryDate = (date, issuedDate) => {
    if (issuedDate) {
        const iDate = parseDate(issuedDate);
        if (date.getTime() === iDate.getTime()) {
            return 'Expiry Date cannot be same as Issued Date.';
        }
        if (date < iDate) {
            return 'Expiry Date cannot be before Issued Date.';
        }
    }
    return null;
};

const validateDLDates = (dateStr, issuedDate, expiryDate, isExpiryDate) => {
    let error = validateDatePattern(dateStr);
    if (error) return error;

    const date = parseDate(dateStr);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (!isExpiryDate) {
        error = validateIssueDate(date, today, expiryDate);
    } else {
        error = validateExpiryDate(date, issuedDate);
    }

    return error || '';
};

const toSentenceCase = (str) => {
    if (str.length === 1) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
        const wordsArr = str.split(' ');
        return wordsArr
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
};

const getParsedCurrentLicenseData = (data) => {
    const createLabelValue = (label, value) => ({
        label,
        value: value || 'NA',
    });

    const parsedData = [
        createLabelValue('License Number', data.license_number),
        createLabelValue('Verification', String(data.license_number_verified).toUpperCase()),
        createLabelValue('Issued Date', data.issue_date),
        createLabelValue(
            'Issued Date Verification',
            String(data.issue_date_verified).toUpperCase()
        ),
        createLabelValue('Expiry Date', data.expiration_date),
        createLabelValue(
            'Expiry Date Verification',
            String(data.expiration_date_verified).toUpperCase()
        ),
        createLabelValue('Class Description', data.class_description),
        createLabelValue(
            'Class Description Verification',
            String(data.class_description_verified).toUpperCase()
        ),
        createLabelValue('Class Code', data.class_code),
        createLabelValue('Class Code Verification', String(data.class_code_verified).toUpperCase()),
        createLabelValue('Has Driving Training', data.has_driver_training),
        createLabelValue('Status', data.status.toUpperCase()),
    ];

    return parsedData;
};

const getResultTextColor = (value) => {
    switch (value.toLowerCase()) {
        case 'true':
        case 'valid':
        case 'yes':
            return 'green';
        case 'false':
        case 'revoked':
        case 'no':
            return 'red';
        default:
            return '';
    }
};

const getParsedPersonalDetails = (data) => {
    const createLabelValue = (label, value) => ({
        label,
        value: value || 'NA',
    });

    const parsedData = [
        createLabelValue('Clear', data?.is_clear === 'Y' ? 'YES' : 'NO'),
        createLabelValue('Valid', data?.valid === 'Y' ? 'YES' : 'NO'),
        createLabelValue('Record Type', toSentenceCase(data.record_type)),
        createLabelValue('First Name', toSentenceCase(data.first_name)),
        createLabelValue('Last Name', toSentenceCase(data.last_name)),
        createLabelValue('Date Of Birth', data?.birth_date),
        createLabelValue(
            'State',
            `${toSentenceCase(
                usaStates.find((state) => state.abbreviation === data.state)?.name
            )} (${data.state})`
        ),
        createLabelValue('Product', data.product),
        createLabelValue('Class Code', data.class_code),
        createLabelValue('Issued Date', data.issue_date),
        createLabelValue('Expiry Date', data.expiration_date),
    ];

    return parsedData;
};

const getParsedViolations = (data) => {
    const createLabelValue = (label, value) =>
        value !== undefined && value !== null ? { label, value } : null;

    const createDateStr = (dateObj) => {
        const month = dateObj?.month >= 10 ? dateObj?.month : `0${dateObj?.month}`;
        const day = dateObj?.day >= 10 ? dateObj?.day : `0${dateObj?.day}`;

        return `${dateObj?.year}-${month}-${day}`;
    };

    const parsedData = data.map((item) => {
        const {
            common,
            descriptionlist: { descriptionitem },
            violation,
        } = item;

        return [
            createLabelValue(
                'State',
                common?.state
                    ? `${toSentenceCase(common.state.full)} (${common.state.abbrev})`
                    : null
            ),
            createLabelValue('Date', common?.date ? createDateStr(common?.date) : null),
            createLabelValue('Location', common?.location),
            createLabelValue('Is Commercial', common?.iscommercial === 'N' ? 'NO' : 'YES'),
            createLabelValue('Docket Number', common?.docketnumber),
            createLabelValue('Large Description', descriptionitem?.adrlargedescription),
            createLabelValue('Small Description', descriptionitem?.adrsmalldescription),
            createLabelValue('State Description', descriptionitem?.statedescription),
            createLabelValue('Acd', descriptionitem?.acd),
            createLabelValue('Avd1', descriptionitem?.avd1),
            createLabelValue('Avd2', descriptionitem?.avd2),
            createLabelValue(
                'Conviction Date',
                violation?.convictiondate ? createDateStr(violation?.convictiondate) : null
            ),
            createLabelValue('Court Type', violation?.courttype),
            createLabelValue('Fine Amount', violation?.fineamount),
        ].filter((item) => item !== null);
    });

    return parsedData;
};

const renderStatusColor = (status, fieldName) => {
    const getColor = (status, isReversed) => {
        switch (status) {
            case 'No':
                return isReversed ? '#027A48' : '#B42318';
            case 'Yes':
                return isReversed ? '#B42318' : '#027A48';
            case 'NA':
                return '#565656';
            default:
                return '#565656';
        }
    };

    switch (fieldName) {
        case 'Criminal Record':
            return getColor(status, true);
        case 'Identity Verified':
        default:
            return getColor(status, false);
    }
};

function checkDateRange(startDate, endDate) {
    // check maximum one year selection
    const twoYears = 1000 * 60 * 60 * 24 * 365 * 2;

    return endDate.getTime() - startDate.getTime() < twoYears;
}

function validateFilterDateRange(startDate, endDate) {
    const sDatePatternError = validateDatePattern(startDate);
    const eDatePatternError = validateDatePattern(endDate);

    if (sDatePatternError || eDatePatternError) {
        return RANGE_DATE_PICKER_MESSAGES.INVALID_RANGE;
    }

    const sDate = new Date(startDate);
    const eDate = new Date(endDate);

    if (eDate < sDate) {
        return RANGE_DATE_PICKER_MESSAGES.INVALID_RANGE;
    }

    // check if end date is a future date
    if (eDate > new Date()) {
        return RANGE_DATE_PICKER_MESSAGES.FUTURE_DATE;
    }

    if (!checkDateRange(sDate, eDate)) {
        return RANGE_DATE_PICKER_MESSAGES.MAX_LIMIT;
    }

    return null;
}

function modifyFilterLabelsToHighlight(key) {
    const labelMap = {
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        dateOfBirth: 'Date of Birth',
        userAccessCode: 'User Code',
        email: 'Email Address',
        reservationId: 'Reservation ID',
        platform: 'Platform',
        start_date: 'Start Date',
        end_date: 'End Date',
        pageSize: 'Page Size',
        order: 'Sort By',
    };

    return labelMap[key] || key;
}

const getVerificationStatus = (status) =>
    VERIFICATION_STATUS[status] || VERIFICATION_STATUS.NOT_STARTED;

const getDateBeforeEighteenYears = () => moment().subtract(18, 'years').format('MM-DD-YYYY');

const validateDateOfBirth = (dob) => {
    if (!dob) {
        return null;
    }

    const dobPatternError = validateDatePattern(dob);

    if (dobPatternError) {
        return 'Invalid Date.';
    }

    const today = moment();
    const dobDate = moment(dob, 'MM-DD-YYYY'); // Adjust the format if needed

    if (!dobDate.isValid()) {
        return 'Invalid Date.';
    }

    const age = today.diff(dobDate, 'years');

    if (age < 18) {
        return 'Selection beyond current date is disabled.';
    }

    return null;
};
const renderVerificationStatusTooltipLabels = (key) => VERIFICATION_TYPE_LABELS[key] || key;

const renderVerificationTooltipValues = (items, useOrFormatting = false) => {
    if (!items || items.length === 0) return '';

    const labels = items.map(renderVerificationStatusTooltipLabels);

    if (useOrFormatting) {
        return labels.reduce((acc, item, index, array) => {
            if (index === 0) return item;
            if (index === array.length - 1) return `${acc} or ${item}`;
            return `${acc}, ${item}`;
        }, '');
    }

    return labels.join(', ');
};

const serializeReportText = (value, key) => {
    let serializedText = value;

    let label = `${key}`.toLowerCase();

    const invalidValues = new Set(['undefined', 'null', '', 'invalid']);

    if (label.includes('date')) {
        const formattedDate = moment(value).format('MM-DD-YYYY');

        serializedText = formattedDate !== 'Invalid date' ? formattedDate : value;
    }

    if (invalidValues.has(`${value}`.toLowerCase())) {
        serializedText = 'NA';
    }

    return serializedText;
};

const getVerificationStatusRank = (status) => {
    const rank = {
        [VERIFICATION_STATUS_TYPES.COMPLETED]: 1,
        [VERIFICATION_STATUS_TYPES.ATTEMPTED]: 2,
        [VERIFICATION_STATUS_TYPES.ERROR]: 3,
        [VERIFICATION_STATUS_TYPES.RE_INITIALIZED]: 4,
        [VERIFICATION_STATUS_TYPES.NOT_STARTED]: 5,
    };
    return rank[status] || 5;
};

const getIdVerificationRank = (data) => {
    if (
        data === null ||
        (data?.identity_verified === null &&
            data?.kba_verified === null &&
            data?.ssn_verified === null &&
            data?.bank_identity_verified === null)
    ) {
        return 3;
    }

    const isVerified =
        data.identity_verified ||
        data.kba_verified ||
        data.ssn_verified ||
        data.bank_identity_verified;

    return isVerified ? 1 : 2;
};

const getCriminalRecordRank = (data) => {
    if (data === null || (data.records_exist === null && data.watchlist_records_exist === null))
        return 3;

    const hasRecords = data.records_exist || data.watchlist_records_exist;

    return hasRecords ? 1 : 2;
};

const getUserTableSortFunction = (column) => {
    switch (column) {
        case 'identityVerified':
            return (user) => getIdVerificationRank(user?.identity);
        case 'criminalRecord':
            return (user) => getCriminalRecordRank(user?.criminal);
        case 'verificationStatus':
            return (user) => getVerificationStatusRank(user.verificationStatus.status);
        case 'fullName':
            return (user) => getFullName(user);
        case 'createdAt':
            return (user) => moment(user?.created_at).unix();
        default:
            return column;
    }
};

const getEngagementValue = ({ engagementType, lastEngagedValue, newValue }) => {
    const { WORKFLOW_ASSIGNED_TO_USER, USER_VERIFICATION_TAB_ACCESSED } = ADMIN_ENGAGEMENT_TYPES;

    //return an object that include preferred workflow id
    if (WORKFLOW_ASSIGNED_TO_USER === engagementType) {
        return newValue;
    }

    //Return an object that tracks the number of times
    //each verification (govt_id, kba, and bank) result accessed.
    if (USER_VERIFICATION_TAB_ACCESSED === engagementType) {
        return { ...(lastEngagedValue ?? {}), ...newValue };
    }

    const defaultValue = 1;

    const noOfTimesAccessed = lastEngagedValue + newValue;

    return isNaN(noOfTimesAccessed) ? defaultValue : noOfTimesAccessed;
};

const structureUserAdminActivities = ({ value, engagementType, uac, state }) => {
    const latestActivities = {
        lastTrackedTime: state?.lastTrackedTime,
        users: state?.users ?? {},
    };

    //last admin engagement on specific user
    const engagements = latestActivities?.users?.[uac]?.Engagements ?? {};

    const engagementValue = getEngagementValue({
        engagementType: engagementType,
        newValue: value,
        lastEngagedValue: engagements[engagementType],
    });

    //a new users object to avoid mutating the original user object
    latestActivities.users = {
        ...latestActivities.users,
        [uac]: {
            ...latestActivities.users[uac], // avoid mutating the specific user object
            Engagements: {
                ...engagements,
                [engagementType]: engagementValue,
            },
        },
    };

    return latestActivities;
};

const trackVerificationResultTabEngagement = ({
    tabType,
    uac,
    adminEngagements,
    cacheAdminEngagement,
}) => {
    const { USER_VERIFICATION_TAB_ACCESSED } = ADMIN_ENGAGEMENT_TYPES;

    const engagements = adminEngagements?.users?.[uac]?.Engagements;

    const lastEngagement = engagements?.[USER_VERIFICATION_TAB_ACCESSED]?.[tabType] ?? 0;

    cacheAdminEngagement({
        type: USER_VERIFICATION_TAB_ACCESSED,
        value: { [tabType]: lastEngagement + 1 },
        uac: uac,
        adminEngagements: adminEngagements,
    });
};

const filterNonEmptyFieldsToHighlight = (values) => {
    const fieldsToExcludeForHighlight = ['companyAccessCode', 'offset', 'limit'];

    const filteredValues = Object.entries(values)
        .filter(([key, value]) => value && !fieldsToExcludeForHighlight.includes(key))
        .map(([key, value]) => ({
            label: modifyFilterLabelsToHighlight(key),
            value,
        }));

    const fieldsCount = Object.keys(filteredValues).length;

    return { filteredValues, fieldsCount };
};

const getSsnNudgeStatus = (isSSNVerified, ssnAvailable, userIdentityVerified) => {
    if (isSSNVerified) return 'success';
    if (ssnAvailable && !userIdentityVerified) {
        return isSSNVerified === null ? 'jwt_nudge' : 'failure';
    }
    return null;
};

const getSsnNudgeHeading = (isSSNVerified, ssnAvailable, userIdentityVerified) => {
    if (isSSNVerified || (ssnAvailable && !userIdentityVerified)) {
        return 'Identity Verification';
    }
    return 'User Identity';
};

const getSsnNudgeDescription = (isSSNVerified, ssnAvailable, userIdentityVerified) => {
    if (isSSNVerified) return 'Identity Verified Through SSN';
    if (ssnAvailable && isSSNVerified === null && !userIdentityVerified) {
        return "Verify a user's identity using their SSN.";
    }
    if (ssnAvailable && !isSSNVerified) return 'SSN could not be verified';
    return '';
};

export const truncateUserName = (name, char) => {
    if (name?.length > char && char > 5) {
        return name.slice(0, char - 3) + '...';
    }
    return name;
};

const getUserRiskProfile = (score) => {
    const { HIGH, LOW, MODERATE } = USER_RISK_SCORE.PROFILES;

    let profile = '';

    const convertedScore = Math.trunc(parseFloat(score) * 100);

    if (convertedScore <= 33) {
        profile = LOW;
    } else if (convertedScore >= 34 && convertedScore <= 66) {
        profile = MODERATE;
    } else if (convertedScore >= 67) {
        profile = HIGH;
    }

    return {
        profile,
        score: convertedScore,
        scoreInPercentage: `${convertedScore}%`,
    };
};

const _serializeDate = (timeStamp) =>
    timeStamp ? moment(timeStamp).format('MM/DD/YYYY hh:mm A') : 'NA';

const _serializeHashValue = ({ md5, sha1, sha256 }) => {
    if (!md5 && !sha1 && !sha256) return {};

    return { md5, sha1, sha256 };
};

const _serializeCommonEmailAndAdditionData = ({
    confidence,
    observations,
    firstSeenMs,
    lastSeenMs,
    md5,
    sha1,
    sha256,
}) => {
    const confidenceScore = Math.trunc(parseFloat(confidence) * 100);

    return {
        observations: observations ?? 'NA',
        confidence: !isNaN(confidenceScore) ? `${confidenceScore}%` : 'NA',
        'first seen': _serializeDate(firstSeenMs),
        'last seen': _serializeDate(lastSeenMs),
        hashes: _serializeHashValue({ md5, sha1, sha256 }),
    };
};

const _serializeEmails = (emails) => {
    const result = [];

    Object.entries(emails ?? {})?.forEach(([email, value]) => {
        const data = value[0] ?? {};

        const common = _serializeCommonEmailAndAdditionData(data);

        const matchType = data?.matchType;

        const individualEmailData = {
            id: nanoid(4),
            email: email,
        };

        if (typeof matchType === 'boolean') {
            individualEmailData['match type'] = `${matchType}`;
        }

        result.push({
            ...individualEmailData,
            ...common,
        });
    });

    return result;
};
const _serializeAdditionData = (additionalData) => {
    const result = [];

    additionalData?.forEach((additional) => {
        const common = _serializeCommonEmailAndAdditionData(additional);

        const data = {
            id: nanoid(4),
            ...common,
        };

        result.push(data);
    });

    return result;
};
const _serializePhone = (phones = []) => {
    const result = [];

    phones?.forEach((phone) => {
        const common = _serializeCommonEmailAndAdditionData(phone);

        const data = {
            id: nanoid(4),
            label: phone?.label ?? 'NA',
            value: phone?.value ?? 'NA',
            ...common,
        };

        result.push(data);
    });

    return result;
};
const _serializeSocialProfiles = (profiles) => {
    const result = [];

    if (profiles === 'Not Found') return result;

    Object.entries(profiles ?? {})?.forEach(([profile, url]) => {
        const profileType = USER_RISK_INSIGHT.SOCIAL_PROFILE.URL_MAP[profile];

        const userId = `${url}`.split('/')?.at(-1);

        const data = {
            type: profileType,
            userId: userId,
            profileLink: url,
        };

        result.push(data);
    });

    return result;
};
const serializeRiskInsightEmployment = (employments = []) => {
    let result = {};

    const convertToArray = _.isArray(employments) ? employments : [employments];

    if (convertToArray.includes('Not Found')) return result;

    const currentEmployment = convertToArray.filter((employment) => employment?.current);

    result = currentEmployment.length ? currentEmployment[0] : employments[0];

    return result;
};

const serializeUserRiskInsights = (insights) => {
    const result = {
        emails: _serializeEmails(insights?.emails),
        additionalData: _serializeAdditionData(insights?.additionalData),
        phones: _serializePhone(insights?.phones),
        employment: serializeRiskInsightEmployment(insights?.employment),
        socialProfiles: _serializeSocialProfiles(insights?.socialProfiles),
    };

    return result;
};

const isDummyUserPII = (pii) => {
    if (pii.email === fakeUserInfo.email) return true;
};

/*
        - This function handles the enter key press
        - If there are empty required field, on pressing enter key
        - it will take to that next required field
        - if all required fields are filled, it will submit the form
    */
const handleEnterKeyPressOnForms = (e, requiredFields, formik) => {
    if (e.key === 'Enter') {
        e.preventDefault();

        const nextField = requiredFields.find(
            ({ name }) => !formik.values[name] || formik.errors[name]
        );
        if (nextField?.ref) {
            nextField?.ref?.focus();
        } else {
            formik.handleSubmit();
        }
    }
};

export {
    clearAccessToken,
    setAccessToken,
    getAccessToken,
    getInitials,
    witnInOneMonth,
    getBase64,
    toCamelCase,
    oneDayAgo,
    handleEmptyValues,
    extractWorkflowOverviewDetails,
    getTotalTitleChar,
    getTotalDescChar,
    documentSigningSteps,
    removeStringNull,
    findTotalPrice,
    validateExtendedChecks,
    extendedChecksDetails,
    getPrintableValue,
    getYesOrNo,
    getAdminDetails,
    getPrintableValueFedralCounty,
    getMemberModifyFormInitialState,
    getConfirmMessageForRBAC,
    returnAdminAccountStatus,
    checkAdminAccess,
    isRouteRestricted,
    processFullNameWithNull,
    formatNumber,
    intializeValues,
    transformBavData,
    getIdentityOptions,
    verificationConfiguration,
    areAllIdentityChecksPending,
    getVerificationMetrics,
    getFullName,
    totalChecksPercentage,
    getOrganizedData,
    getTopLabels,
    stackedBarGraphConfig,
    buildApiUrl,
    homePageData,
    calculateDateRange,
    getCategoryValues,
    totalChecksRunOverall,
    calendarDates,
    isSevendaysDifference,
    extractMinutes,
    getStateDurationText,
    getModifiedCheckType,
    calculateTimeDifference,
    formatBtnDate,
    graphDataFormatDates,
    updationtimeMessage,
    isEndDateValid,
    isDateInputFormatValid,
    convertToFirstCapital,
    formatCountyHistoryDate,
    assignUniqueIdToListOfObject,
    scrollToSpecificView,
    getBalanceDetails,
    getFieldTypeAndMessageForStripeErrorCode,
    checkAuthTokenExpiry,
    getConsentMsg,
    extractDomain,
    getFormattedUsaStates,
    getWeekdayFirstLetter,
    convertToMMDDYYYY,
    validateDLDates,
    convertToDDMMYYYY,
    getParsedCurrentLicenseData,
    getResultTextColor,
    getParsedPersonalDetails,
    getParsedViolations,
    renderStatusColor,
    validateFilterDateRange,
    modifyFilterLabelsToHighlight,
    getVerificationStatus,
    validateDateOfBirth,
    getDateBeforeEighteenYears,
    renderVerificationTooltipValues,
    getUserTableSortFunction,
    serializeReportText,
    structureUserAdminActivities,
    trackVerificationResultTabEngagement,
    filterNonEmptyFieldsToHighlight,
    getSsnNudgeStatus,
    getSsnNudgeHeading,
    getSsnNudgeDescription,
    getUserRiskProfile,
    serializeUserRiskInsights,
    serializeRiskInsightEmployment,
    isDummyUserPII,
    handleEnterKeyPressOnForms,
};
