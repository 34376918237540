import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from 'components/V2/Button';
import PropTypes from 'prop-types';

import classes from './listingsModal.module.css';

const Footer = ({ infoText, primaryBtnProps, secondaryBtnProps }) => {
    return (
        <Box className={classes.footerWrapper}>
            <Box className={classes.footer}>
                <Typography className={classes.infoText}>{infoText}</Typography>
                <Box className={classes.footerBtnWrapper}>
                    <Button intent="secondary" {...secondaryBtnProps}>
                        {secondaryBtnProps.title}
                    </Button>
                    <Button intent="primary" {...primaryBtnProps}>
                        {primaryBtnProps.title}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

Footer.propTypes = {
    infoText: PropTypes.string,
    primaryBtnProps: PropTypes.object,
    secondaryBtnProps: PropTypes.object,
};

export default Footer;
