import React from 'react';

import PropTypes from 'prop-types';

import styles from '../flowChart.module.css';

import CustomBaseEdge from './CustomBase';

const AndEdge = ({ style, ...props }) => {
    const styleExtended = {
        stroke: '#9E77ED',
        strokeWidth: 2,
        ...style,
    };

    return (
        <CustomBaseEdge
            {...props}
            label="AND"
            labelClass={styles.andEdgeLabel}
            style={styleExtended}
        />
    );
};

AndEdge.propTypes = {
    style: PropTypes.object,
};

export default AndEdge;
