import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from '../flowChart.module.css';

import { ReactComponent as ShieldIcon } from 'asset/IconsPack/at_shield-tick.svg';

const RootNode = ({ data }) => (
    <Box className={styles.rootNode}>
        <ShieldIcon className={styles.shieldIcon} />
        <Typography className={styles.rootLabel}>{data.label}</Typography>
    </Box>
);

RootNode.propTypes = {
    data: PropTypes.object,
};

export default RootNode;
