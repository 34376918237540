import apiCall from './apiRequest';
import endPoints from './endpoints';

export function fetchAllWorkflowApi({ token, pageNumber, pageSize }) {
    return apiCall({
        method: 'get',
        endpoint: endPoints.workflowUrl,
        authToken: token,
        query: `pageNumber=${pageNumber}&pageSize=${pageSize}`,
    });
}

export function createWorkflowApi(data, token) {
    return apiCall({
        method: 'post',
        endpoint: endPoints.workflowUrl,
        payload: data,
        authToken: token,
    });
}

export function updateWorkflowApi(data, workflowNanoId, token) {
    return apiCall({
        method: 'post',
        endpoint: `${endPoints.workflowUrl}/${workflowNanoId}`,
        payload: data,
        authToken: token,
    });
}

export function fetchOneWorkflowApi({ workflowNanoId, token }) {
    return apiCall({
        method: 'get',
        endpoint: `${endPoints.workflowUrl}/${workflowNanoId}`,
        authToken: token,
    });
}

export function setDefaultWorkflowApi({ workflowNanoId, token }) {
    return apiCall({
        method: 'put',
        endpoint: `${endPoints.workflowUrl}/${workflowNanoId}/default`,
        authToken: token,
    });
}

export function deleteWorkflowApi({ workflowNanoId, token }) {
    return apiCall({
        method: 'delete',
        endpoint: `${endPoints.workflowUrl}/${workflowNanoId}`,
        authToken: token,
    });
}

export function updateWorkflowThemeLogoApi(data, workflowNanoId, token) {
    return apiCall({
        method: 'put',
        endpoint: `${endPoints.workflowUrl}/${workflowNanoId}/logo`,
        payload: data,
        authToken: token,
    });
}

export function deleteWorkflowThemeLogoApi({ workflowNanoId, token }) {
    return apiCall({
        method: 'delete',
        endpoint: `${endPoints.workflowUrl}/${workflowNanoId}/logo`,
        authToken: token,
    });
}
