/* eslint-disable react-hooks/exhaustive-deps */
import { createTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';

function useMuiTheme(props) {
    const initialTheme = createTheme({});
    const [theme, setTheme] = useState(initialTheme);

    useEffect(() => {
        setTheme({
            ...theme,
            ...props,
        });
    }, [props]);

    return theme;
}

export default useMuiTheme;
