import React from 'react';

import { Box, Typography } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Modal from 'components/ModalV2';
import PropTypes from 'prop-types';

import classes from './deleteBrandModals.module.css';

const DeleteFinalStepModal = ({ open, onClose, onConfirm, title, brandName }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box className={classes.container}>
                <FormHeader title={title} handleClose={onClose} />
                <Typography className={classes.text}>
                    Are you sure you want to delete <strong>{brandName}</strong>?
                </Typography>
                <FormFooter
                    formType="onClick"
                    primaryBtnHandler={onClose}
                    secondaryBtnHandler={onConfirm}
                    secondaryBtnText="Yes"
                    btnText="No"
                />
            </Box>
        </Modal>
    );
};

DeleteFinalStepModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    brandName: PropTypes.string,
};

export default DeleteFinalStepModal;
