import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import Input from 'components/Input/Input';
import Tooltip from 'components/Tooltip';
import Button from 'components/V2/Button';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import styles from './listings.module.css';

import { ReactComponent as SearchIcon } from 'asset/IconsPack/at_search-sm-filled.svg';

const Listings = React.forwardRef(
    (
        { listings, total, value, onChange, isLoading, onScroll, btnProps = {}, classes = {} },
        ref
    ) => {
        return (
            <Box className={styles.container}>
                <Box className={styles.header}>
                    <Typography className={styles.listingsCount}>Listings ({total})</Typography>
                    <Button
                        className={clsx(styles.headerBtn, btnProps?.className)}
                        onClick={btnProps?.onClick}
                        {...btnProps}
                    >
                        {btnProps?.text}
                    </Button>
                </Box>
                <Input
                    variant="outlined"
                    label=""
                    value={value}
                    name="searchInput"
                    className={clsx(styles.searchInput, classes?.searchInput)}
                    placeholder="Search Listings"
                    startAdornment={true}
                    overrideStyles={{ startAdornment: styles.searchIcon }}
                    StartAdornmentIcon={SearchIcon}
                    handleChange={onChange}
                    autoComplete="off"
                />
                <div className={styles.listWrapper} ref={ref} onScroll={onScroll}>
                    {listings.map((item) =>
                        isLoading ? (
                            <Skeleton
                                key={nanoid()}
                                variant="rect"
                                className={clsx(styles.itemPlaceholder, classes?.itemPlaceholder)}
                            />
                        ) : (
                            <Tooltip
                                key={nanoid()}
                                title={
                                    <>
                                        <Typography className={styles.tooltipName}>
                                            {item.name}
                                        </Typography>
                                        <Typography className={styles.tooltipAddress}>
                                            {item.address}
                                        </Typography>
                                    </>
                                }
                                placement="right"
                                arrow
                                classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
                            >
                                <Box className={clsx(styles.listItem, classes?.listItem)}>
                                    <Typography className={styles.listItemName}>
                                        {item.name}
                                    </Typography>
                                    <Typography className={styles.listItemAddress}>
                                        {item.address}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        )
                    )}
                </div>
            </Box>
        );
    }
);

Listings.propTypes = {
    listings: PropTypes.array,
    total: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    onScroll: PropTypes.func,
    btnProps: PropTypes.object,
    classes: PropTypes.object,
    wrapperClassName: PropTypes.string,
};

export default Listings;
