import React from 'react';

import { Box } from '@material-ui/core';
import { useCommonScreesStyles } from 'components/MedallionPreview/styles';
import PropTypes from 'prop-types';

import Medallion from 'asset/img/Medallion_Logo.svg';

function MedallionHeader({ themeLogo, title }) {
    const classes = useCommonScreesStyles();
    const logoSrc = themeLogo?.logo ? themeLogo?.logo : Medallion;

    return (
        <>
            <Box className={classes.logoWrapper}>
                <img src={logoSrc} alt="Company Logo" className={classes.headerLogo} />
            </Box>
            <Box className={classes.screenHeading}>{title}</Box>
        </>
    );
}

MedallionHeader.propTypes = {
    title: PropTypes.string,
    themeLogo: PropTypes.any,
};

export default MedallionHeader;
