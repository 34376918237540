import { useEffect } from 'react';

const HubSpotTrackerScript = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = 'hs-script-loader';
        script.src = '//js.hs-scripts.com/6431620.js';

        document.body.appendChild(script);

        return () => document.body.removeChild(script);
    }, []);
    return null;
};

export default HubSpotTrackerScript;
