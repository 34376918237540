import React, { useEffect } from 'react';
import config from 'config';
import Helmet from 'react-helmet';

/**
 * @see https://stackoverflow.com/questions/56849620/how-can-i-track-google-adwords-conversions-in-my-react-application
 * @description The above has been adapted for Google Tag Manager
 */

const GoogleConversionTracker = () => {
    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', config.ANALYTICS.GTM.ID);
            if (config.ANALYTICS.GTM.ENABLED) {
                window.gtag('event', 'internal-analytics', { action: 'heartbeat' });
            }
        }
    }, []);

    if (!config.ANALYTICS.GTM.ENABLED) return <></>;

    return (
        <>
            <Helmet>
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${config.ANALYTICS.GTM.ID}`}
                ></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    `}
                </script>
            </Helmet>
            <noscript>
                <iframe
                    title="GTM-IFrame"
                    src={`https://www.googletagmanager.com/ns.html?id=${config.ANALYTICS.GTM.ID}`}
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>
            </noscript>
        </>
    );
};

export default GoogleConversionTracker;

export const useConversionEvent =
    () =>
    ({ type = 'event', eventName = '', data = '' }) => {
        if (window.gtag && config.ANALYTICS.GTM.ENABLED) {
            window.gtag(type, eventName, data);
        }
    };
