import { ReactComponent as BillingFilled } from 'asset/IconsPack/at_currency-dollar-circle-filled.svg';
import { ReactComponent as Billing } from 'asset/IconsPack/at_currency-dollar-circle.svg';
import { ReactComponent as HomeFilled } from 'asset/IconsPack/at_grid-01-filled.svg';
import { ReactComponent as Home } from 'asset/IconsPack/at_grid-01.svg';
import { ReactComponent as MedallionFilled } from 'asset/IconsPack/at_shield-tick-filled.svg';
import { ReactComponent as Medallion } from 'asset/IconsPack/at_shield-tick.svg';
import { ReactComponent as SettingsFilled } from 'asset/IconsPack/at_tool-02-filled.svg';
import { ReactComponent as Settings } from 'asset/IconsPack/at_tool-02.svg';
import { ReactComponent as UserFilled } from 'asset/IconsPack/at_user-01-filled.svg';
import { ReactComponent as User } from 'asset/IconsPack/at_user-01.svg';
import { ReactComponent as Integrations } from 'asset/icons/integration.svg';
import { ReactComponent as IntegrationsFilled } from 'asset/icons/integration_filled.svg';
import { ReactComponent as Tcm } from 'asset/icons/tcm_icon.svg';
import { ReactComponent as TcmFilled } from 'asset/icons/tcm_icon_filled.svg';

export const navigationItems = [
    {
        id: 0,
        label: 'Home',
        path: '/dashboard',
        icons: {
            default: <Home />,
            selected: <HomeFilled />,
        },
    },
    {
        id: 1,
        label: 'Users',
        path: '/users',
        icons: {
            default: <User />,
            selected: <UserFilled />,
        },
    },
    {
        id: 2,
        label: 'Billing',
        path: '/billing/dashboard',
        icons: {
            default: <Billing />,
            selected: <BillingFilled />,
        },
    },
    {
        id: 3,
        label: 'Medallion™',
        path: '/workflows',
        icons: {
            default: <Medallion />,
            selected: <MedallionFilled />,
        },
    },
    {
        id: 4,
        label: 'True Continuous Monitoring™',
        path: '/tcm',
        icons: {
            default: <Tcm />,
            selected: <TcmFilled />,
        },
    },
    {
        id: 5,
        label: 'Integrations',
        path: '/integrations',
        icons: {
            default: <Integrations />,
            selected: <IntegrationsFilled />,
        },
    },
    {
        id: 6,
        label: 'Settings',
        path: '/settings',
        icons: {
            default: <Settings />,
            selected: <SettingsFilled />,
        },
    },
];
