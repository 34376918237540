import actionConstants from './actionConstant';

export function setFieldValue({ name = '', value = '' }) {
    return { type: actionConstants.SET_FIELD_VALUE, name, value };
}

export function resetFieldValue() {
    return { type: actionConstants.RESET_FIELD_VALUE };
}

export function fetchPreviousThemeState(data) {
    return { type: actionConstants.FETCH_PREVIOUS_THEME_STATE, payload: { ...data } };
}
