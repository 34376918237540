import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { TextField, withStyles, Box, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import styles from './selectWorkflow.module.css';
import { workflowStyles } from './styles';

import { ReactComponent as ChevronDown } from 'asset/IconsPack/at_chevron-down-filled.svg';
import { ReactComponent as InfoIcon } from 'asset/IconsPack/hx_info-circle.svg';

import WorkflowPreview from '../../integrations/Guesty/components/WorkflowPreview';

function SelectWorkflow({ classes, formik, allWorkflow, setUnsavedChanges }) {
    const [workflowId, setWorkflowId] = useState(formik.values.preferredWorkflowId);

    useEffect(() => {
        if (formik.values.preferredWorkflowId) {
            setWorkflowId(formik.values.preferredWorkflowId);
        }
    }, [formik.values.preferredWorkflowId]);

    const allWorkflowList = useMemo(() => {
        return (
            allWorkflow?.workflows.map((workflow) => ({
                label: workflow.name,
                value: workflow.nanoid,
            })) || []
        );
    }, [allWorkflow]);

    const workflowValue =
        allWorkflowList.find((workflow) => workflow.value === formik.values.preferredWorkflowId) ||
        null;

    const handleChange = (newValue) => {
        formik.setFieldValue('preferredWorkflowId', newValue ? newValue.value : '');
        setUnsavedChanges(true);
    };

    const getCurrentWorkflow = useCallback(() => {
        return workflowId
            ? allWorkflow?.workflows?.find((workflow) => workflow.nanoid === workflowId)
            : null;
    }, [allWorkflow?.workflows, workflowId]);

    const handleMouseOver = useCallback(
        (value) => {
            if (value !== workflowId) {
                setWorkflowId(value);
            }
        },
        [workflowId]
    );

    const renderOption = useCallback(
        (props) => {
            return (
                <>
                    <Box
                        key={props.value}
                        {...props}
                        onMouseOver={() => handleMouseOver(props.value)}
                    >
                        {props.label}
                    </Box>
                    <Box className={styles.rightSection}>
                        <Box className={styles.previewHeader}>
                            <InfoIcon className={styles.infoIcon} />
                            <Typography className={styles.previewHeading}>Workflow Info</Typography>
                        </Box>
                        <WorkflowPreview
                            className={styles.previewOverride}
                            workflowData={getCurrentWorkflow()}
                        />
                    </Box>
                </>
            );
        },
        [getCurrentWorkflow, handleMouseOver]
    );

    return (
        <Autocomplete
            name="preferredWorkflowId"
            classes={classes}
            ListboxProps={{ style: { minHeight: '350px' } }}
            popupIcon={<ChevronDown />}
            value={workflowValue}
            options={allWorkflowList}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, anotherOption) => option.value === anotherOption.value}
            onChange={(_, newValue) => handleChange(newValue)}
            renderOption={renderOption}
            renderInput={(params) => (
                <TextField {...params} placeholder="Select Workflow" variant="outlined" />
            )}
        />
    );
}

SelectWorkflow.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.string,
    formik: PropTypes.object,
    allWorkflow: PropTypes.object,
    setUnsavedChanges: PropTypes.func,
};

export default withStyles(workflowStyles)(SelectWorkflow);
