import React from 'react';

import { Box } from '@material-ui/core';
import FormFooter from 'components/FormComponents/FormFooter';
import FormHeader from 'components/FormComponents/FormHeader';
import Input from 'components/Input';
import Modal from 'components/ModalV2';
import PropTypes from 'prop-types';

import styles from './updateBrandName.module.css';

const UpdateBrandName = ({
    open,
    isEditBrand,
    brandName,
    handleChange,
    error,
    helperText,
    onConfirm,
    isDisabled,
    onClose,
}) => {
    return (
        <Modal open={open} onClose={onClose} disableBackdropClick={true}>
            <Box className={styles.updateBrandNameContainer}>
                <FormHeader
                    title={isEditBrand ? 'Edit Brand Name' : 'Create Brand'}
                    handleClose={onClose}
                />
                <Box className={styles.inputWrapper}>
                    <Input
                        value={brandName}
                        variant="outlined"
                        label="Brand Name *"
                        labelClass={styles.inputLabel}
                        type="text"
                        name="brandName"
                        className={styles.inputField}
                        handleChange={handleChange}
                        error={error}
                        helperText={helperText}
                        autoComplete="off"
                    />
                </Box>
                <FormFooter
                    formType="onClick"
                    secondaryBtnHandler={onClose}
                    primaryBtnHandler={onConfirm}
                    btnText={isEditBrand ? 'Save' : 'Create'}
                    isPrimaryBtnDisabled={isDisabled}
                />
            </Box>
        </Modal>
    );
};

UpdateBrandName.propTypes = {
    open: PropTypes.bool,
    isEditBrand: PropTypes.bool,
    brandName: PropTypes.string,
    handleChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    onConfirm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onClose: PropTypes.func,
};

export default UpdateBrandName;
