import { useState, useEffect } from 'react';

const useListingSelection = (items, handleDisableConfirmAction) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [isChecked, setIsChecked] = useState();
    const [disableSelection, setDisableSelection] = useState(false);

    useEffect(() => {
        //disable move action if nothing is selected
        handleDisableConfirmAction(selectedItems.length === 0);

        // update value if use de-selects all the listings
        if (selectedItems.length === 0 || selectedItems.length < items.length) {
            setIsChecked(false);
            setIsSelectAll(false);
        } else if (selectedItems.length === items.length) {
            setIsChecked(true);
        }

        if (selectedItems.length >= 500) {
            setDisableSelection(true);
        } else {
            setDisableSelection(false);
        }
    }, [selectedItems, items, handleDisableConfirmAction]);

    const handleSelectAll = () => {
        setSelectedItems(items);
        setIsChecked(true);
        setIsSelectAll(true);
    };

    const onChange = () => {
        const newValue = !isChecked;
        if (!newValue) {
            setIsSelectAll(false);
        }
        setIsChecked(newValue);
        setSelectedItems(newValue ? items : []);
    };

    const handleClearSelection = () => {
        setIsSelectAll(false);
        setSelectedItems([]);
        setDisableSelection(false);
    };

    const handleToggleItemSelection = (item) => {
        setSelectedItems((prevSelected) => {
            const checkSelectionStatus = prevSelected.some(
                (selectedItem) => selectedItem.id === item.id
            );
            if (checkSelectionStatus) {
                return prevSelected.filter((selectedItem) => selectedItem.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    return {
        selectedItems,
        isSelectAll,
        isChecked,
        disableSelection,
        setIsSelectAll,
        setIsChecked,
        handleSelectAll,
        handleToggleItemSelection,
        handleClearSelection,
        onChange,
        setSelectedItems,
    };
};

export default useListingSelection;
