import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { extractRedirect } from './utils';
import { refreshCompanyAction, fetchAdminInformationAction } from 'redux/actions/auth';
import { checkAuthTokenExpiry } from 'helper/utility';
import routeEnpoints from './endpoints';
import PropTypes from 'prop-types';

export const PrivateRoute = ({
    component: Component,
    auth,
    refreshCompanyAction,
    fetchAdminInformationAction,
    tokenExpireDate,
    ...rest
}) => {
    let searchParams = useLocation();
    const history = useHistory();

    const { redirect, params } = extractRedirect(searchParams.search);

    let redirectTo = '/' + searchParams.search;
    const isLogoutRoute = searchParams.pathname === routeEnpoints.logout;

    if (auth && redirect) {
        redirectTo = redirect + '?' + params;
    } else if (!auth && !isLogoutRoute) {
        // logout is also a private route but that should not be set as a redirect query
        redirectTo = '/?redirect=' + searchParams.pathname + searchParams.search.replace('?', '&');
    }

    useEffect(() => {
        return checkAuthTokenExpiry({ history, tokenExpireDate });
    }, [tokenExpireDate, history]);

    useEffect(() => {
        //logout is also a private route, so don't fetch data on logout
        if (!isLogoutRoute && auth && rest.companyUUID && !redirect) {
            refreshCompanyAction({
                token: auth,
                companyUUID: rest.companyUUID,
            });
            fetchAdminInformationAction(auth);
        }
    }, [
        auth,
        rest.companyUUID,
        fetchAdminInformationAction,
        refreshCompanyAction,
        redirect,
        isLogoutRoute,
    ]);

    return (
        <Route
            {...rest}
            component={(props) =>
                //if your logged in and dont have to be redirected, render the component
                auth && !redirect ? (
                    <div>
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect to={redirectTo} />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    tokenExpireDate: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
    refreshCompanyAction,
    fetchAdminInformationAction,
})(PrivateRoute);

function mapStateToProps(state) {
    return {
        auth: state?.auth?.userData?.token,
        companyUUID: state.auth.userData && state.auth.userData.user.company.uuid,
        tokenExpireDate: state.auth?.userData?.user.token_expiration_date,
    };
}
