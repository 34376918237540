import { PASSWORD_POLICY } from 'helper/constant';
import * as yup from 'yup';

export const initialValues = {
    password: '',
    confirmPassword: '',
};

export const validationSchema = yup.object().shape({
    password: yup
        .string()
        .matches(PASSWORD_POLICY.REGEX, PASSWORD_POLICY.ERROR_MESSAGE)
        .required('Password is required'),
    confirmPassword: yup
        .string('Enter your password')
        .required('Confirm Password is required')
        .oneOf([yup.ref('password')], 'The password and confirmation password do not match'),
});
