import { useSelector } from 'react-redux';

import { adminTypes } from 'helper/constant';
import { checkAdminAccess } from 'helper/utility';

import { selectLoggedInUser } from 'redux/selectors/auth';

const useAccessRole = () => {
    const loggedInUser = useSelector(selectLoggedInUser);

    const canUserUpdate = checkAdminAccess(loggedInUser?.type, [
        adminTypes.COMPANY_OWNER,
        adminTypes.COMPANY_ADMIN,
    ]);

    return { canUserUpdate };
};

export default useAccessRole;
