const API = {
    BASE_URL: 'https://api-v3-stage.authenticating.com',
    HERE_GEOCODE_URL: 'https://geocode.search.hereapi.com/v1/geocode',
};

const APPLICATION = {
    VERSION: process.env.REACT_APP_PORTAL_VERSION,
};

const DEV_TOOLS = {
    logError: true,
    enableReduxDevTools: true,
};

const ANALYTICS = {
    GTM: {
        ID: 'GTM-5HXZLH7',
        ENABLED: false,
    },
    DATADOG: {
        RUM: {
            APPLICATION_ID: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
            CLIENT_TOKEN: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
            ENABLED: process.env.REACT_APP_DATADOG_RUM_ENABLED === 'true',
        },
    },
};

module.exports = {
    ENV: 'staging',
    MIN_TOP_UP_AMOUNT: 20,
    API,
    DEV_TOOLS,
    ANALYTICS,
    APPLICATION,
};
