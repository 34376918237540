import apiCall from './apiRequest';
import endPoints from './endpoints';

export function getStatusAPI(data) {
    const state = window.store.getState();
    const token = state && state.auth.userData.token;
    const rutterId =
        state && state.integration.active.find((settings) => settings.name === 'E-Commerce');
    return apiCall({
        method: 'get',
        endpoint: `${endPoints.isRutterProductsReady}/${rutterId.id}/settings/isReady`,
        authToken: token,
        query: { companyUUID: state?.auth?.userData?.user?.company?.uuid },
    });
}
