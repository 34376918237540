import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Grid } from '@material-ui/core';
import useAccessRole from 'hooks/useAccessRole';
import EditBrandHeader from 'views/V2/Integration/components/EditBrandHeader';
import useBrandListings from 'views/V2/Integration/hooks/useBrandListings';
import useBrandNameForm from 'views/V2/Integration/hooks/useBrandNameForm';
import useCustomFormik from 'views/V2/Integration/hooks/useCustomFormik';
import useFetchWorkflow from 'views/V2/Integration/hooks/useFetchWorkflow';

import styles from './editDefaultBrand.module.css';

import EditBrandForm from '../EditBrandForm';
import MoveListings from '../MoveListings';
import ReusableBrandListings from '../ReusableBrandListings';
import UnsavedBrandWarning from '../UnsavedBrandWarning';
import UpdateBrandName from '../UpdateBrandName';

function EditDefaultBrand(props) {
    const { uuid } = useParams();

    const [openMoveModal, setOpenMoveModal] = useState(false);
    const [brandNameModal, setBrandNameModal] = useState(false);
    const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { formik, brandData } = useCustomFormik(uuid, setUnsavedChanges);

    const { defaultWorkflow } = useFetchWorkflow();
    const { brandNameForm } = useBrandNameForm(
        defaultWorkflow,
        brandData,
        setBrandNameModal,
        false
    );
    const { listingRef, listings, onChange, handleScroll, searchTerm, totalCount, isFetching } =
        useBrandListings(uuid);

    const { canUserUpdate } = useAccessRole();

    const chatbotConfig = window?.HubSpotConversations;

    const onOpenModal = () => {
        setOpenMoveModal(true);
        if (chatbotConfig) {
            chatbotConfig.widget.remove();
        }
    };

    const onCloseModal = () => {
        setOpenMoveModal(false);
        if (chatbotConfig) {
            chatbotConfig.widget.refresh();
        }
    };

    const onCloseNameModal = () => {
        setBrandNameModal(false);
        brandNameForm.resetForm();
    };

    return (
        <>
            <Grid>
                <form onSubmit={formik.handleSubmit}>
                    <EditBrandHeader
                        title="Edit Brand"
                        {...props}
                        isDefaultBrand={brandData?.isDefault}
                        unsavedChanges={unsavedChanges}
                        setUnsavedChanges={setUnsavedChangesModal}
                        defaultBrandScreen={true}
                        canUserUpdate={canUserUpdate}
                    />
                    <Box className={styles.editBrandLayout}>
                        <Box className={styles.editBrandContainer}>
                            <EditBrandForm
                                brandUUID={uuid}
                                brandData={brandData}
                                formik={formik}
                                listings={listings}
                                openBrandNameModal={setBrandNameModal}
                                setIsNewBrand={() => {}}
                                canUserUpdate={canUserUpdate}
                                setUnsavedChanges={setUnsavedChanges}
                            />
                        </Box>
                        <Box className={styles.listingContainer}>
                            <ReusableBrandListings
                                noListings={listings.length === 0 && searchTerm === ''}
                                listings={listings}
                                listingRef={listingRef}
                                onOpenModal={onOpenModal}
                                canUserUpdate={canUserUpdate}
                                totalCount={totalCount ?? 0}
                                onScroll={handleScroll}
                                value={searchTerm}
                                onChange={onChange}
                                isLoading={isFetching}
                            />
                        </Box>
                    </Box>
                </form>
            </Grid>
            <MoveListings
                brandName={brandData?.brandName}
                brandUuid={uuid}
                open={openMoveModal}
                onClose={onCloseModal}
                onCancel={() => {}}
            />
            <UnsavedBrandWarning
                open={unsavedChangesModal}
                setOpen={setUnsavedChangesModal}
                resetForm={formik.resetForm}
                setUnsavedChanges={setUnsavedChanges}
            />
            <UpdateBrandName
                open={brandNameModal}
                setOpen={setBrandNameModal}
                isEditBrand={true}
                brandName={brandNameForm.values.brandName}
                handleChange={brandNameForm.handleChange}
                error={brandNameForm.touched.brandName && Boolean(brandNameForm.errors.brandName)}
                helperText={brandNameForm.touched.brandName && brandNameForm.errors.brandName}
                onConfirm={brandNameForm.handleSubmit}
                isDisabled={!brandNameForm.dirty || brandNameForm.values?.brandName?.trim() === ''}
                onClose={onCloseNameModal}
            />
        </>
    );
}

export default EditDefaultBrand;
