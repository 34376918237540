import React, { useState, useEffect } from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';

import classes from './redirectForPayment.module.css';

const RedirectForPayment = ({ redirectUrl, open = true }) => {
    const [counter, setCounter] = useState(8);

    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter((c) => c - 1), 1000);
        }

        if (counter === 0 && redirectUrl) {
            window.location.assign(redirectUrl);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [counter, redirectUrl]);

    return (
        <Backdrop className={classes.backdrop} sx={{ color: '#fff' }} open={open}>
            <div className={classes.container}>
                <p className={classes.text}>
                    {'We are redirecting you to Shopify to start your subscription'}
                </p>
                <p className={classes.countdown}>{counter}</p>
            </div>
        </Backdrop>
    );
};

RedirectForPayment.propTypes = {
    redirectUrl: PropTypes.string,
    open: PropTypes.bool,
};

export default RedirectForPayment;
