import React from 'react';

import { IconButton, InputAdornment, Typography, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';

import { styles } from './style';

import InputHelperText from './InputHelperText';

const Input = ({
    label,
    variant,
    value,
    select,
    dataList,
    error,
    name,
    type,
    handleChange,
    placeholder,
    disabled,
    helperText,
    labelClass,
    icon,
    classes,
    className,
    startAdornment,
    StartAdornmentIcon,
    endAdornment,
    EndAdornmentIcon,
    emdAdormentIconClass,
    endAdormentClick,
    iconCompoent,
    isRequired,
    showCharLimit = false,
    maxCharLimit,
    charLimitClass,
    isErrIconResponsive,
    hideErrIconOnDesktop = true,
    tooltipContent,
    overrideStyles,
    autoFocus = false,
    ...restprops
}) => {
    const calcCharCount = maxCharLimit - (value?.length || 0);
    return (
        <>
            <Typography className={clsx(classes.labelBaseClass, labelClass)}>
                {label}
                {isRequired && <span className={classes.asterisk}>*</span>}
                {tooltipContent && (
                    <Tooltip
                        title={tooltipContent}
                        ariaLabel="valid-id"
                        className={classes.inputToolTip}
                        placement="right"
                        arrow
                    />
                )}
            </Typography>
            {select ? (
                <TextField
                    {...restprops}
                    fullWidth
                    select
                    value={value}
                    name={name}
                    type={type}
                    error={error}
                    placeholder={placeholder}
                    helperText={helperText}
                    variant={variant}
                    id={name}
                    disabled={disabled}
                    onChange={handleChange}
                    className={classes.textField}
                    margin="normal"
                    FormHelperTextProps={{
                        component: () => (
                            <InputHelperText
                                maxCharLimit={maxCharLimit}
                                isErrIconResponsive={isErrIconResponsive}
                                calcCharCount={calcCharCount}
                                helperText={helperText}
                                showCharLimit={showCharLimit}
                                hideErrIconOnDesktop={hideErrIconOnDesktop}
                            />
                        ),
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        },
                    }}
                    SelectProps={{
                        IconComponent: iconCompoent,
                        classes: {
                            select: classes.select,
                            iconOutlined: classes.selectIconOutlined,
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: clsx(classes.cssOutlinedInput, className),
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                            error: classes.error,
                            disabled: classes.disabled,
                            input: classes.inputPlaceholder,
                        },
                        ...restprops.InputProps,
                    }}
                >
                    {dataList.map((data) => {
                        return (
                            <MenuItem
                                key={data.value}
                                value={data.value}
                                className={classes.menuItem}
                            >
                                {data.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            ) : (
                <TextField
                    {...restprops}
                    fullWidth
                    value={value}
                    name={name}
                    type={type}
                    error={error}
                    placeholder={placeholder}
                    helperText={helperText}
                    variant={variant}
                    id={name}
                    disabled={disabled}
                    onChange={handleChange}
                    className={classes.textField}
                    margin="normal"
                    inputProps={{ maxLength: maxCharLimit + 1 }}
                    autoFocus={autoFocus}
                    FormHelperTextProps={{
                        component: () => (
                            <InputHelperText
                                maxCharLimit={maxCharLimit}
                                isErrIconResponsive={isErrIconResponsive}
                                calcCharCount={calcCharCount}
                                helperText={helperText}
                                showCharLimit={showCharLimit}
                                hideErrIconOnDesktop={hideErrIconOnDesktop}
                            />
                        ),
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        },
                    }}
                    InputProps={{
                        classes: {
                            root: clsx(classes.cssOutlinedInput, className),
                            focused: classes.cssFocused,
                            notchedOutline: variant !== 'filled' && classes.notchedOutline,
                            error: classes.error,
                            disabled: classes.disabled,
                            input: classes.inputPlaceholder,
                        },
                        startAdornment: startAdornment && (
                            <InputAdornment
                                className={
                                    overrideStyles?.startAdornment
                                        ? overrideStyles?.startAdornment
                                        : classes.startAdornment
                                }
                                position="start"
                            >
                                <StartAdornmentIcon />
                            </InputAdornment>
                        ),
                        endAdornment: endAdornment && (
                            <InputAdornment position="end">
                                <IconButton
                                    className={clsx(classes.endIcon, emdAdormentIconClass)}
                                    onClick={endAdormentClick}
                                >
                                    <EndAdornmentIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        ...restprops.InputProps,
                    }}
                />
            )}
            {showCharLimit && !error && (
                <Typography className={charLimitClass}>
                    {Math.max(calcCharCount, 0)}/{maxCharLimit}
                </Typography>
            )}
        </>
    );
};

Input.propTypes = {
    label: PropTypes.string,
    variant: PropTypes.string,
    value: PropTypes.string,
    select: PropTypes.bool,
    dataList: PropTypes.array,
    error: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    handleChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    labelClass: PropTypes.string,
    icon: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    startAdornment: PropTypes.bool,
    StartAdornmentIcon: PropTypes.node,
    endAdornment: PropTypes.bool,
    EndAdornmentIcon: PropTypes.node,
    emdAdormentIconClass: PropTypes.string,
    endAdormentClick: PropTypes.func,
    iconCompoent: PropTypes.node,
    isRequired: PropTypes.bool,
    showCharLimit: PropTypes.bool,
    maxCharLimit: PropTypes.number,
    charLimitClass: PropTypes.string,
    isErrIconResponsive: PropTypes.bool,
    hideErrIconOnDesktop: PropTypes.bool,
    tooltipContent: PropTypes.any,
    overrideStyles: PropTypes.object,
    autoFocus: PropTypes.bool,
};

Input.defaultProps = {
    disabled: false,
    isRequired: false,
    showCharLimit: false,
    isErrIconResponsive: false,
    autoFocus: false,
};

export default withStyles(styles)(Input);
