/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Button from 'components/Button';
import ButtonWrapper from 'components/Button/Button';
import Checkbox from 'components/Checkbox';
import HistoryBackArrow from 'components/HistoryBackArrow';
import PropTypes from 'prop-types';

import {
    getNextProductsAction,
    getPrevProductsAction,
    getProductsAction,
    resetProductScreenAction,
    setEnabledProductsAction,
} from 'redux/actions/integrations';

import classes from './rutterProducts.module.css';

import NoImageIcon from 'asset/img/no-image-icon.png';

const Products = ({
    history,
    uid,
    token,
    getProductsAction,
    getNextProductsAction,
    getPrevProductsAction,
    allProducts,
    nextCursor,
    activeAction,
    isNextDisabled,
    setEnabledProductsAction,
    resetProductScreenAction,
}) => {
    const [monitarProducts, setMonitarProducts] = useState([]);
    const [disabledProducts, setDisabledProducts] = useState([]);

    useEffect(() => {
        activeAction({ companyAccessCode: uid }, { loader: true }, token, handleResponse);
        return () => {
            resetProductScreenAction();
        };
    }, []);

    useEffect(() => {
        const enabledProducts = allProducts
            .map((product) => {
                return product.isEnabled ? product.id : null;
            })
            .filter((id) => id);
        setMonitarProducts([...enabledProducts]);
    }, [allProducts]);

    function handleResponse({ body, ...res }) {
        if (body[2].active) {
            getProductsAction({ companyUUID: uid }, { loader: true }, token);
        } else {
            history.replace('/integrations/e-commerce');
        }
    }
    return (
        <div>
            <HistoryBackArrow />
            <Grid container spacing={1} alignItems="center">
                <Grid item sm={3} xs={12}>
                    <div className={classes.heading}>E-Commerce Integrations</div>
                </Grid>
            </Grid>
            <div className={clsx(classes.subHeading, classes.headingSpace)}>
                Choose products to enable verification for.
            </div>
            <Grid container spacing={4}>
                {allProducts?.map((product, index) => {
                    return (
                        <Grid item key={product.id} xs={12} sm={6} md={4}>
                            <div
                                className={classes.card}
                                style={{
                                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 69.26%, rgba(0, 0, 0, 0.4) 100%), url(${
                                        product.images.length ? product.images[0].src : NoImageIcon
                                    })`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                <div className={classes.checkbox}>
                                    <Checkbox
                                        checked={
                                            !!monitarProducts.find((id) => {
                                                return id === product.id;
                                            })
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setMonitarProducts([
                                                    ...monitarProducts,
                                                    product.id,
                                                ]);
                                            } else {
                                                let enabledProducts = monitarProducts.filter(
                                                    (id) => {
                                                        return product.id !== id;
                                                    }
                                                );
                                                setMonitarProducts([...enabledProducts]);
                                                setDisabledProducts([
                                                    ...disabledProducts,
                                                    product.id,
                                                ]);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={classes.cardHeading}>{product.name}</div>
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
            <div className={classes.PaginationWrapper}>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <ButtonWrapper
                        btnText="Prev"
                        variant={false}
                        className={classes.paginationBtn}
                        disabled={nextCursor?.length <= 1}
                        handleOnClick={() => {
                            getPrevProductsAction(
                                {
                                    companyUUID: uid,
                                    nextCursor: nextCursor[nextCursor.length - 3],
                                },
                                { loader: true },
                                token
                            );
                        }}
                    />
                    <ButtonWrapper
                        btnText="Next"
                        variant={false}
                        className={classes.paginationBtn}
                        disabled={isNextDisabled}
                        handleOnClick={() => {
                            getNextProductsAction(
                                {
                                    companyUUID: uid,
                                    nextCursor: nextCursor[nextCursor.length - 1],
                                },
                                { loader: true },
                                token
                            );
                        }}
                    />
                </Grid>
            </div>
            <Button
                btnText="Save"
                // disabled={true}
                className={classes.saveBtn}
                handleOnClick={() => {
                    setEnabledProductsAction(
                        {
                            companyAccessCode: uid,
                            enabled: {
                                productIds: [...monitarProducts],
                            },
                            disabled: {
                                productIds: [...disabledProducts],
                            },
                        },
                        { loader: true },
                        token
                    );
                }}
            />
        </div>
    );
};

Products.propTypes = {
    history: PropTypes.object,
    uid: PropTypes.string,
    token: PropTypes.string,
    getProductsAction: PropTypes.func,
    getNextProductsAction: PropTypes.func,
    getPrevProductsAction: PropTypes.func,
    allProducts: PropTypes.array,
    nextCursor: PropTypes.array,
    activeAction: PropTypes.func,
    isNextDisabled: PropTypes.bool,
    setEnabledProductsAction: PropTypes.func,
    resetProductScreenAction: PropTypes.func,
};

export default connect(mapStateToProps, {
    getProductsAction,
    getPrevProductsAction,
    getNextProductsAction,
    setEnabledProductsAction,
    resetProductScreenAction,
})(Products);

function mapStateToProps(state) {
    return {
        allProducts: state.integration?.products,
        nextCursor: state.integration?.nextCursor,
        isNextDisabled: state.integration?.isNextDisabled,
    };
}
