import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Box, List, ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Input from 'components/Input/Input';
import Button from 'components/V2/Button';
import useDebounce from 'hooks/useDebounce';
import PropTypes from 'prop-types';

import { useLazyListBrandsQuery } from 'redux/slices/globalApi';

import styles from './listBrands.module.css';
import useListItemStyles from './styles';

import { ReactComponent as AddIcon } from 'asset/IconsPack/at_plus-circle.svg';
import { ReactComponent as SearchIcon } from 'asset/IconsPack/at_search-sm-filled.svg';

function ListBrands({
    brandUUID,
    unsavedChanges,
    setUnsavedChanges,
    openBrandNameModal,
    setIsNewBrand,
    canUserUpdate,
}) {
    const history = useHistory();
    const [getBrandsList, { data }] = useLazyListBrandsQuery();
    const [searchBrand, setSearchBrand] = useState('');

    const classes = useListItemStyles();

    const debouncedSearchBrand = useDebounce(searchBrand, 400);

    const configureBrandRedirect = (brandUuid) => {
        if (unsavedChanges) {
            setUnsavedChanges(true);
        } else {
            history.push(`/integrations/guesty/brands/${brandUuid}`);
        }
    };

    const getAllBrandLists = useCallback(
        async (value) => {
            try {
                const query = {
                    page: 1,
                    size: 20,
                    filter: value,
                };
                await getBrandsList(query).unwrap();
            } catch (err) {}
        },
        [getBrandsList]
    );

    useEffect(() => {
        (async () => await getAllBrandLists(debouncedSearchBrand))();
    }, [debouncedSearchBrand, getAllBrandLists]);

    const handleBrandSearch = (e) => {
        setSearchBrand(() => {
            return e.target.value;
        });
    };

    const createNewBrand = () => {
        openBrandNameModal(true);
        setIsNewBrand(true);
    };

    return (
        <Grid className={styles.listBrandContainer}>
            <Input
                variant="outlined"
                label=""
                name="searchBrand"
                className={clsx(styles.searchInput)}
                placeholder="Search"
                startAdornment={true}
                StartAdornmentIcon={SearchIcon}
                overrideStyles={{ startAdornment: styles.searchIcon }}
                handleChange={handleBrandSearch}
            />
            {canUserUpdate && (
                <Button
                    intent="secondary"
                    size="md"
                    className={styles.addBrandButton}
                    startIcon={<AddIcon className={styles.addIcon} />}
                    onClick={createNewBrand}
                >
                    Create Brand
                </Button>
            )}
            <Box className={styles.allBrandContainer}>
                <List component="nav">
                    {data?.brands?.map((brand) => (
                        <ListItem
                            key={brand.uuid}
                            button
                            className={classes.listItem}
                            selected={brand.uuid === brandUUID}
                            onClick={() => configureBrandRedirect(brand.uuid)}
                        >
                            <Typography className={styles.listItemText}>
                                {brand.brandName}
                            </Typography>
                            {brand.isDefault && (
                                <Typography className={styles.defaultText}>Default</Typography>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Grid>
    );
}

ListBrands.propTypes = {
    brandUUID: PropTypes.string,
    unsavedChanges: PropTypes.bool,
    setUnsavedChanges: PropTypes.func,
    openBrandNameModal: PropTypes.bool,
    setIsNewBrand: PropTypes.func,
    canUserUpdate: PropTypes.bool,
};

export default ListBrands;
