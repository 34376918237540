import actionType from 'redux/actions/actionConstant';

const initialObject = {
    loaded: false,
    data: undefined,
};

const initialState = {
    // Verification
    education: undefined,
    employment: undefined,
    professionalLicense: undefined,
    // Result
    educationResult: initialObject,
    employmentResult: initialObject,
    professionalLicenseResult: initialObject,
};

export default function eep(state = initialState, action) {
    switch (action.type) {
        // Verification reducer
        case actionType.EDUCATION_VERIFICATION_SUCCESS:
            return {
                ...state,
                education: action.payload,
            };

        case actionType.EMPLOYMENT_VERIFICATION_SUCCESS:
            return {
                ...state,
                employment: action.payload,
            };

        case actionType.PROFESSIONAL_LICENSE_VERIFICATION_SUCCESS:
            return {
                ...state,
                professionalLicense: action.payload,
            };

        // Result
        case actionType.EDUCATION_VERIFICATION_RESULT_SUCCESS:
            return {
                ...state,
                educationResult: {
                    loaded: true,
                    data: action.payload,
                },
            };

        case actionType.EMPLOYMENT_VERIFICATION_RESULT_SUCCESS:
            return {
                ...state,
                employmentResult: {
                    loaded: true,
                    data: action.payload,
                },
            };

        case actionType.PROFESSIONAL_LICENSE_VERIFICATION_RESULT_SUCCESS:
            return {
                ...state,
                professionalLicenseResult: {
                    loaded: true,
                    data: action.payload,
                },
            };

        case actionType.CLEAR_EEP_RESULT_DATA:
        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                education: undefined,
                employment: undefined,
                professionalLicense: undefined,
                educationResult: initialObject,
                employmentResult: initialObject,
                professionalLicenseResult: initialObject,
            };
        default:
            return state;
    }
}
