import actionType from 'redux/actions/actionConstant';

const initialState = {
    active: undefined,
    settings: [],
    settingsValues: undefined,
    products: [],
    nextCursor: [],
    isNextDisabled: false,
    enabledProducts: [],
};

const setAPI = ({ data = null, completed = false, triggered = false, error = null }) => ({
    triggered,
    completed,
    error,
    data,
});

export default function integration(state = initialState, action) {
    switch (action.type) {
        case actionType.ACTIVE_SUCCESS:
            return {
                ...state,
                active: action.payload,
            };
        // already saved values of integration settings
        case actionType.SETTING_GET_SUCCESS:
            const settingsValues = action.payload.map((setting) => {
                return {
                    name: setting.integration_setting.name,
                    value: setting.value,
                };
            });
            return {
                ...state,
                settingsValues,
            };
        //setting keys for dynamically making setting post request
        case actionType.SETTING_SUCCESS:
            const settings = action.payload.map((setting) => {
                return {
                    integration_id: setting.integration_id,
                    integration_setting_id: setting.id,
                    name: setting.name,
                };
            });
            return {
                ...state,
                settings: [...settings],
            };
        case actionType.GET_PREV_PRODUCTS_SUCCESS:
            state.nextCursor.pop();
            return {
                ...state,
                products: action.payload.products,
                nextCursor: [...state.nextCursor],
                isNextDisabled: false,
            };
        case actionType.GET_NEXT_PRODUCTS_SUCCESS:
            const nextCursor = action.payload.nextCursor;
            const products = action.payload.products.length
                ? action.payload.products
                : [...state.products];

            if (nextCursor) {
                return {
                    ...state,
                    products,
                    nextCursor: [...state.nextCursor, action.payload.nextCursor],
                };
            }

            return {
                ...state,
                products,
                isNextDisabled: true,
                nextCursor: [...state.nextCursor, null],
            };

        case actionType.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload.products,
                nextCursor: [...state.nextCursor, action.payload.nextCursor],
            };
        case actionType.GET_ENABLED_PRODUCTS_SUCCESS:
            return {
                ...state,
                enabledProducts: [...action.payload.products],
            };

        case actionType.RESET_PRODUCTS_SCREEN:
            return {
                ...state,
                products: [],
                nextCursor: [],
                isNextDisabled: false,
                enabledProducts: [],
            };

        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                active: undefined,
                settings: [],
                settingsValues: undefined,
                products: [],
                nextCursor: [],
                isNextDisabled: false,
                enabledProducts: [],
                payment: null,
                paymentConfirmation: {
                    api: setAPI({}),
                },
            };
        case actionType.RUTTER.PAYMENTS.STATUS.SUCCESS:
            return { ...state, payment: action.payload };
        case actionType.RUTTER.PAYMENTS.CONFIRM.FAILURE:
            return {
                ...state,
                paymentConfirmation: {
                    api: setAPI({
                        ...state.paymentConfirmation?.api,
                        completed: true,
                        triggered: true,
                        error: action.payload,
                    }),
                },
            };
        case actionType.RUTTER.PAYMENTS.CONFIRM.INPROGRESS:
            return {
                ...state,
                paymentConfirmation: {
                    api: setAPI({
                        ...state.paymentConfirmation?.api,
                        completed: false,
                        triggered: true,
                    }),
                },
            };
        case actionType.RUTTER.PAYMENTS.CONFIRM.SUCCESS:
            return {
                ...state,
                paymentConfirmation: {
                    api: setAPI({
                        ...state.paymentConfirmation?.api,
                        data: action.payload,
                        completed: true,
                        triggered: true,
                    }),
                },
            };
        default:
            return state;
    }
}
