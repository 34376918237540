import React, { useCallback } from 'react';
import ReactFlow, {
    Controls,
    Background,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
} from 'reactflow';
// !! do not change the order flowChart.css will be placed at bottom
import 'reactflow/dist/style.css';

import PropTypes from 'prop-types';

import './flowChart.css';

import AndEdge from './Edges/And';
import OrEdge from './Edges/Or';
import RootNode from './Nodes/Root';
import StatusNode from './Nodes/Status';
import VerificationMethodNode from './Nodes/VerificationMethod';

const nodeTypes = {
    root: RootNode,
    method: VerificationMethodNode,
    status: StatusNode,
};

const edgeTypes = {
    and: AndEdge,
    or: OrEdge,
};

function FlowChart({ nodes, edges, setNodes, setEdges, maxScreen }) {
    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const onConnect = useCallback(
        (connection) => {
            setEdges((eds) => addEdge(connection, eds));
        },
        [setEdges]
    );

    const onInit = (instance) => {
        const rootNode = nodes.find((node) => node.id === 'root');
        if (rootNode) {
            instance.setCenter(
                rootNode.position.x + 150,
                rootNode.position.y + (maxScreen ? 600 : 200),
                {
                    zoom: 0.5,
                }
            );
        }
    };

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodesDraggable={false}
            defaultEdgeOptions={{ type: 'step' }}
            fitView
            onInit={onInit}
        >
            <Background />
            <Controls showFitView={false} showInteractive={false} position="bottom-right" />
        </ReactFlow>
    );
}

FlowChart.propTypes = {
    nodes: PropTypes.array,
    edges: PropTypes.array,
    setNodes: PropTypes.func,
    setEdges: PropTypes.func,
    maxScreen: PropTypes.bool,
};

export default FlowChart;
