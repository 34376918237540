import { countryList } from 'helper/countries';
import { nanoid } from 'nanoid';
import * as yup from 'yup';

import {
    childNodeMapper,
    ENABLED_ADDITIONAL_CHECKS,
    GROUP_NODE_WIDTH,
    parentNodeMapper,
    VERIFICATION_LABEL,
    VERIFICATIONS_VALUES,
} from '../constants';

/**
 * @param {Array} settings An array of objects, where each object has a settings metadata
 * @param {Array} settingValues An array of objects, where each object has the current value of a setting
 * @returns Object
 */
export const mergeSettingsAndValues = (settings, settingValues) => {
    const mergedSettings = settings.map((setting) => {
        let existingSetting = null;

        if (Array.isArray(settingValues)) {
            existingSetting = settingValues.find(
                (settingValue) => settingValue?.name === setting.name
            );
        }
        return { ...setting, value: existingSetting ? existingSetting.value : null };
    });

    return mergedSettings;
};

export const getDefaultEnhancedChecksState = () => ({
    sevenYearCheck: false,
    verifyId: false,
});

export const getEnhancedCheckStateFromSetting = (enabledAdditionalCheckSetting = []) => {
    const state = getDefaultEnhancedChecksState();

    if (Array.isArray(enabledAdditionalCheckSetting)) {
        if (enabledAdditionalCheckSetting.includes(ENABLED_ADDITIONAL_CHECKS.VALUES.verifyId)) {
            state.verifyId = true;
        }
        if (
            enabledAdditionalCheckSetting.includes(ENABLED_ADDITIONAL_CHECKS.VALUES.sevenYearCheck)
        ) {
            state.sevenYearCheck = true;
        }
    }

    return state;
};

export const guestyActivateFormValidationSchema = () =>
    yup.object({
        token: yup.string().required('Integration token required.'),
    });

export const _findSettingValueByName = (existingSettings) => (name) => {
    let item = existingSettings.find((setting) => setting.name === name);
    return item ? item.value : null;
};

export const prepareListingsTableHeader = (labels) => {
    return labels.map((label) => ({
        id: nanoid(),
        label,
    }));
};

const getCountryName = (code) => {
    const country = countryList.find((country) => country.value === code);
    return country ? country.label : code;
};

export const returnEnabledCountries = (countries) => {
    if (!countries) return null;

    if (countries === 'all') {
        return 'All countries';
    } else {
        const countryNames = countries.map(getCountryName);
        return countryNames.join(', ');
    }
};

export const getVerificationConfig = (identityProof) => {
    if (!identityProof) return null;

    // return the verification configuration AND, OR
    const isMandatory = identityProof
        ?.filter((verification) => {
            if (verification.enabled === undefined) {
                return verification;
            } else {
                return verification.enabled;
            }
        })
        ?.every((verification) => verification.mandatory === true);
    const verificationConfig = isMandatory ? 'AND' : 'OR';

    return verificationConfig;
};

export const formatIdentityProofData = (identityProof) => {
    if (!identityProof) return null;

    // return the enabled verification with attempts
    const verificationList = identityProof
        .filter((verification) => {
            if (verification.enabled === undefined) {
                return verification;
            } else {
                return verification.enabled;
            }
        })
        .map((verification) => {
            let formattedText;
            const type = VERIFICATION_LABEL[verification.verification_type];
            const livenessCheck = verification?.metadata?.livenessCheck;

            if (verification.verification_type === VERIFICATIONS_VALUES.GOVT_ID) {
                formattedText = `${type} - ${verification.attempts}`;

                if (livenessCheck?.enabled) {
                    formattedText += `, Selfie - ${livenessCheck.attempts}`;
                }
            } else {
                formattedText = `${type} - ${verification.attempts}`;
            }

            return formattedText;
        })
        .filter(Boolean);

    const verificationConfig = getVerificationConfig(identityProof);

    return { verificationList, verificationConfig };
};

export const formatAdditionalChecksData = (additionalCheck) => {
    if (!additionalCheck) return null;

    const enabledAdditionalChecks = additionalCheck
        ?.filter((check) => {
            if (check.enabled === undefined) {
                return check;
            } else {
                return check.enabled;
            }
        })
        ?.map((check) => VERIFICATION_LABEL[check.check_type]);

    return enabledAdditionalChecks?.join(', ');
};

export const calculatePositionX = (
    i,
    childCount,
    childWidth,
    GROUP_NODE_WIDTH,
    totalChildrenWidth
) => {
    const SPACING = 100;

    const calculateForOneChild = () => GROUP_NODE_WIDTH / 2 - childWidth / 2;

    const calculateForTwoChildren = () =>
        i === 0 ? -(totalChildrenWidth / 2 - SPACING / 2) : totalChildrenWidth / 2;

    const calculateForThreeChildren = () => {
        if (i === 0) return -totalChildrenWidth / 2;
        if (i === 1) return GROUP_NODE_WIDTH / 2 - childWidth / 2;
        return totalChildrenWidth / 2 + SPACING;
    };

    const calculateForFourChildren = () => {
        if (i === 0) return -(totalChildrenWidth - GROUP_NODE_WIDTH);
        if (i === 1) return -(GROUP_NODE_WIDTH / 2 + SPACING);
        if (i === 2) return GROUP_NODE_WIDTH / 2 + SPACING;
        return totalChildrenWidth - GROUP_NODE_WIDTH;
    };

    switch (childCount) {
        case 1:
            return calculateForOneChild();
        case 2:
            return calculateForTwoChildren();
        case 3:
            return calculateForThreeChildren();
        case 4:
            return calculateForFourChildren();
        default:
            return 0;
    }
};

export const createGroupNode = (node, index) => {
    const groupId = `group-${node}`;

    return {
        id: groupId,
        position: { x: 0, y: 200 + index * 350 },
        style: {
            width: GROUP_NODE_WIDTH,
            height: 250,
            backgroundColor: 'transparent',
            border: 'none',
        },
        selectable: false,
    };
};

export const createParentNode = (node, groupId) => {
    const parentId = `parent-${node}`;

    return {
        id: parentId,
        type: 'method',
        data: parentNodeMapper[node],
        position: { x: GROUP_NODE_WIDTH / 2 - parentNodeMapper[node].width / 2, y: 0 },
        parentId: groupId,
    };
};

export const createChildNodes = (node, groupId, childId, data) => {
    const childNodes = parentNodeMapper[node].children.filter((child) => data.includes(child));
    const totalChildrenWidth = childNodes.reduce(
        (total, child) => total + childNodeMapper[child].width,
        0
    );

    return childNodes.map((childNode, i) => {
        const positionX = calculatePositionX(
            i,
            childNodes.length,
            childNodeMapper[childNode].width,
            GROUP_NODE_WIDTH,
            totalChildrenWidth
        );

        return {
            id: `${childId}${i + 1}`,
            type: 'status',
            data: childNodeMapper[childNode].data,
            position: { x: positionX, y: 160 },
            parentId: groupId,
        };
    });
};

export const createEdgesBetweenNodes = (node, parentId, children) => {
    return children.reduce((edges, child, i) => {
        const baseEdge = {
            id: `parent-${node}-to-child-${node}-${i + 1}`,
            source: parentId,
            target: child.id,
            type: 'step',
            style: { stroke: '#9E77ED', strokeWidth: 2 },
        };
        edges.push(baseEdge);

        // add edge between consecutive children
        if (i > 0) {
            const newEdge = {
                id: `child-${node}-${i}-to-child-${node}-${i + 1}`,
                source: children[i - 1].id,
                target: children[i].id,
                sourceHandle: 'right',
                targetHandle: 'left',
                type: 'or',
            };
            edges.push(newEdge);
        }

        return edges;
    }, []);
};
