import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import Modal from 'components/ModalV2';
import Button from 'components/V2/Button';
import { navigationPreviewCards } from 'helper/constant';
import PropTypes from 'prop-types';

import { showNavigationPreview } from 'redux/actions/settings';

import styles from './previewNavbar.module.css';

import GIF_3 from 'asset/img/preview-screen3.gif';

function PreviewNavbar({ showNavigationPreview, navigationPreview }) {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const [currentScreen, setCurrentScreen] = useState(1);

    useEffect(() => {
        if (isTablet) {
            setCurrentScreen(2);
        }
    }, [isTablet]);

    useEffect(() => {
        if (!navigationPreview) {
            setOpen(true);
        }
    }, [navigationPreview, setOpen, showNavigationPreview]);

    const handleNext = () => {
        if (currentScreen === 2) {
            setOpen(false);
            showNavigationPreview();
        } else {
            setCurrentScreen(2);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            disableBackdropClick
            className={styles.previewNavbarModal}
        >
            <Box className={styles.previewNavbarContainer}>
                <Box className={styles.previewHeader}>
                    <img
                        src={isTablet ? GIF_3 : navigationPreviewCards[currentScreen].img}
                        alt="feature preview GIF"
                        className={styles.previewAnimation}
                    />
                </Box>
                {!isTablet && (
                    <Box className={styles.navigationDots}>
                        <Box
                            className={clsx(styles.dots, currentScreen === 1 && styles.activeDots)}
                        ></Box>
                        <Box
                            className={clsx(styles.dots, currentScreen === 2 && styles.activeDots)}
                        ></Box>
                    </Box>
                )}
                <Box className={styles.navigationBody}>
                    <Typography className={styles.cardHeading}>
                        {navigationPreviewCards[currentScreen].heading}
                    </Typography>
                    <Typography className={styles.cardDisc}>
                        {navigationPreviewCards[currentScreen].desc}
                    </Typography>
                </Box>
            </Box>
            <Box className={styles.btnGroup}>
                {currentScreen === 2 && !isTablet && (
                    <Button
                        intent="secondary"
                        size="lg"
                        onClick={() => setCurrentScreen(1)}
                        className={styles.previewBtn}
                    >
                        Previous
                    </Button>
                )}
                <Button
                    intent="primary"
                    size="lg"
                    onClick={handleNext}
                    className={styles.previewBtn}
                >
                    {currentScreen === 1 ? 'Next' : 'Close'}
                </Button>
            </Box>
        </Modal>
    );
}

PreviewNavbar.propTypes = {
    navigationPreview: PropTypes.bool,
    showNavigationPreview: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        navigationPreview: state.settings?.navigationPreview,
    };
}

export default connect(mapStateToProps, { showNavigationPreview })(PreviewNavbar);
