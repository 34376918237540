import React from 'react';

import PropTypes from 'prop-types';

import DesktopSidebar from './components/DesktopSidebar';
import SmallScreenSidebar from './components/SmallScreenSidebar';

function Sidebar({ isMobile, isTablet, openSidebar, handleToggler, firstName }) {
    return (
        <>
            {!(isMobile || isTablet) && <DesktopSidebar />}
            <SmallScreenSidebar
                openSidebar={openSidebar}
                handleToggler={handleToggler}
                firstName={firstName}
                isMobile={isMobile}
            />
        </>
    );
}

Sidebar.propTypes = {
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
    openSidebar: PropTypes.bool,
    handleToggler: PropTypes.func,
    firstName: PropTypes.string,
};

export default Sidebar;
