import React, { useState, useRef, useEffect } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import {
    formatIdentityProofData,
    formatAdditionalChecksData,
    returnEnabledCountries,
} from 'views/V2/Integration/utils';

import styles from './workflowPreview.module.css';

function WorkflowPreview({ workflowData, className }) {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const identityProof = formatIdentityProofData(workflowData?.id_verifications);
    const additionalChecks = formatAdditionalChecksData(workflowData?.additional_checks);
    const allowedCountries = returnEnabledCountries(
        workflowData?.workflow?.enabled_countries || workflowData?.enabled_countries
    );

    const containerRef = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (containerRef.current) {
                const isOverflowing =
                    containerRef.current.scrollWidth > containerRef.current.clientWidth;
                setIsOverflowing(isOverflowing);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [allowedCountries]);

    return (
        <Box className={clsx(className, styles.workflowPreviewContainer)}>
            <Box className={styles.workflowConfigBox}>
                <Typography className={styles.configHeading}>Countries Allowed:</Typography>
                <Box className={styles.moreWrapper}>
                    <p
                        className={clsx(styles.configDesc, styles.countryListText)}
                        ref={containerRef}
                    >
                        {allowedCountries}
                    </p>
                    {isOverflowing && (
                        <Tooltip
                            title={isOverflowing ? allowedCountries : ''}
                            arrow
                            placement="bottom"
                        >
                            <span> more...</span>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Box className={styles.workflowConfigBox}>
                <Typography className={styles.configHeading}>Identity Proof: </Typography>
                <Typography className={styles.configDesc}>
                    {identityProof?.verificationList.map((checks, index) => {
                        return (
                            <span key={checks}>
                                {index !== 0 && (
                                    <strong> {identityProof?.verificationConfig} </strong>
                                )}
                                {checks}
                            </span>
                        );
                    })}
                </Typography>
            </Box>
            {additionalChecks?.length > 1 && (
                <Box className={styles.workflowConfigBox}>
                    <Typography className={styles.configHeading}>Additional Checks: </Typography>
                    <Typography className={styles.configDesc}>{additionalChecks}</Typography>
                </Box>
            )}
        </Box>
    );
}

WorkflowPreview.propTypes = {
    workflowData: PropTypes.object,
    className: PropTypes.string,
};

export default WorkflowPreview;
