import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { Box, Divider, Typography } from '@material-ui/core';
import Input from 'components/Input';
import Button from 'components/V2/Button';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import {
    setForgetPasswordAction,
    setInvitePasswordAction,
    verifyTokenAction,
} from 'redux/actions/auth';
import { openToast } from 'redux/actions/toaster';

import styles from './reNewPassword.module.css';

import { ReactComponent as EyeOn } from 'asset/IconsPack/hx_eye-filled.svg';
import { ReactComponent as EyeOff } from 'asset/IconsPack/hx_eye-off-filled.svg';

import { initialValues, validationSchema } from './utils';

const ReNewPassword = ({
    type,
    verifyTokenAction,
    match,
    setForgetPasswordAction,
    forgetPasswordData,
    openToast,
    setInvitePasswordAction,
}) => {
    const history = useHistory();
    const [passwordType, setPasswordType] = useState({
        password: true,
        confirmPassword: true,
    });

    const throwMessageAndRedirect = () => {
        history.push('/');
        openToast({
            variant: 'default',
            message: 'Password has changed.',
        });
    };
    const handleFormSubmit = (values) => {
        if (type === 'forgot-password') {
            setForgetPasswordAction(
                {
                    password: values.password,
                    passwordConfirm: values.confirmPassword,
                    resetToken: forgetPasswordData.pw_reset_token,
                    uuid: forgetPasswordData.uuid,
                },
                {
                    loader: true,
                },
                () => {
                    throwMessageAndRedirect();
                }
            );
        } else if (type === 'invite-password') {
            setInvitePasswordAction(
                {
                    password: values.password,
                    confirm_password: values.confirmPassword,
                    invite_token: match.params.token,
                },
                {
                    loader: true,
                },
                () => {
                    throwMessageAndRedirect();
                }
            );
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleFormSubmit,
    });

    const handlePasswordTypeChange = (field) => {
        setPasswordType({
            ...passwordType,
            [field]: !passwordType[field],
        });
    };

    useEffect(() => {
        if (type === 'forgot-password') {
            verifyTokenAction(match?.params?.token, { loader: true });
        }
    }, [match, type, verifyTokenAction]);

    return (
        <Box>
            <Typography component={'h2'} className={styles.title}>
                Create New Password
            </Typography>
            <Divider className={styles.divider} />
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <Box>
                    <Input
                        type={passwordType.password ? 'password' : 'text'}
                        variant="outlined"
                        label="Password"
                        name="password"
                        value={formik.values.password}
                        labelClass={styles.label}
                        className={styles.inputField}
                        placeholder="Enter password"
                        endAdornment={formik.values.password}
                        EndAdornmentIcon={passwordType.password ? EyeOff : EyeOn}
                        emdAdormentIconClass={styles.eyeIcon}
                        endAdormentClick={() => handlePasswordTypeChange('password')}
                        handleChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        autoFocus
                    />
                </Box>
                <Box>
                    <Input
                        type={passwordType.confirmPassword ? 'password' : 'text'}
                        variant="outlined"
                        label="Confirm New Password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        labelClass={styles.label}
                        className={styles.inputField}
                        placeholder="Enter confirm password"
                        endAdornment={formik.values.confirmPassword}
                        EndAdornmentIcon={passwordType.confirmPassword ? EyeOff : EyeOn}
                        emdAdormentIconClass={styles.eyeIcon}
                        endAdormentClick={() => handlePasswordTypeChange('confirmPassword')}
                        handleChange={formik.handleChange}
                        error={
                            formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)
                        }
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    />
                </Box>

                <Button intent="primary" size="lg" type="submit" className={styles.resetButton}>
                    Reset Password
                </Button>
            </form>
        </Box>
    );
};

ReNewPassword.propTypes = {
    type: PropTypes.string,
    verifyTokenAction: PropTypes.func,
    match: PropTypes.object,
    setForgetPasswordAction: PropTypes.func,
    forgetPasswordData: PropTypes.object,
    openToast: PropTypes.func,
    setInvitePasswordAction: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        forgetPasswordData: state.auth.forgetPasswordData?.user || {},
    };
}

export default connect(mapStateToProps, {
    verifyTokenAction,
    setForgetPasswordAction,
    openToast,
    setInvitePasswordAction,
})(withRouter(ReNewPassword));
