import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import endPoints from 'route/endpoints';

import { ReactComponent as BackIcon } from 'asset/icons/back_icon.svg';

const useStyles = makeStyles(() => ({
    backIcon: {
        padding: 0,

        '@media screen and (max-width: 600px)': {
            '& svg': {
                transform: 'scale(0.8)',
            },
        },
    },
}));

const HistoryBackArrow = ({ icon, className }) => {
    const history = useHistory();
    const classes = useStyles();

    const onIconBtnClick = () => {
        //check if this is the only route in the history
        if (history?.length === 1 || history?.action === 'POP') {
            if (history?.location?.pathname.includes('users')) {
                history.push(endPoints.users);
            } else if (history?.location?.pathname.includes('tcm')) {
                history.push(endPoints.tcm);
            } else {
                history.push(endPoints.default);
            }
        } else if (history?.location?.pathname.includes('guesty')) {
            history.push(endPoints.integration);
        } else {
            history.goBack();
        }
    };

    return (
        <IconButton onClick={onIconBtnClick} className={clsx(classes.backIcon, className)}>
            {icon || <BackIcon />}
        </IconButton>
    );
};

HistoryBackArrow.propTypes = {
    icon: PropTypes.node,
    className: PropTypes.string,
};

export default HistoryBackArrow;
