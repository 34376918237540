import { Grid } from '@material-ui/core';
import OnboardingLogo from 'asset/img/authenticate_rgb.svg';
import './Onboarding.scss';

const Onboarding = (props) => {
    return (
        <Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                className="onboardingImageWrapper"
            >
                <Grid item>
                    <img
                        src={OnboardingLogo}
                        alt="Authenticating Logo"
                        width="100%"
                        height="29px"
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="onboardingContentWarpper"
            >
                <Grid item className="onboardingBox">
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Onboarding;
