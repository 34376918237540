import React from 'react';
import { Handle, Position } from 'reactflow';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from '../flowChart.module.css';

import { ReactComponent as CheckVerifiedIcon } from 'asset/IconsPack/at_check-verified-01.svg';
import { ReactComponent as SlashCircleIcon } from 'asset/IconsPack/at_slash-circle-01.svg';
import { ReactComponent as AlertIcon } from 'asset/IconsPack/hx_alert-circle.svg';

const iconMapper = {
    verified: CheckVerifiedIcon,
    warning: AlertIcon,
    noData: SlashCircleIcon,
};

const StatusNode = ({ data }) => {
    const Icon = iconMapper[data.type];

    return (
        <Box className={styles.statusNode}>
            <Box className={styles.statusIconWrapper}>
                <Icon className={styles.statusIcon} />
            </Box>
            <Typography className={styles.statusLabel}>{data.label}</Typography>
            <Handle type="source" position={Position.Bottom} id="bottom" />
            <Handle type="target" position={Position.Top} id="top" />
            <Handle type="source" position={Position.Right} id="right" />
            <Handle type="target" position={Position.Left} id="left" />
        </Box>
    );
};

StatusNode.propTypes = {
    data: PropTypes.object,
};

export default StatusNode;
