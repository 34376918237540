import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles(() => ({
    label: {
        fontSize: '16px',
        color: '#000000',
        lineHeight: '19px',
    },
    checked: {
        color: '#154683',
        '& svg': {
            color: '#154683',
        },
    },
}));

const CheckBoxComponent = ({ className = '', label, ...rest }) => {
    const classes = useStyle();
    return (
        <>
            <Checkbox
                className={className}
                classes={{
                    checked: classes.checked,
                }}
                {...rest}
            />
            <span className={classes.label}>{label}</span>
        </>
    );
};

export default CheckBoxComponent;
