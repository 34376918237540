import React from 'react';

import MuiChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import chipStyles from './styles';

function Chip({ classes, content, className, intent = 'contained', size, ...props }) {
    const variants = cva(classes.default, {
        variants: {
            intent: {
                contained: classes.contained,
                // outlined classes will be implemented later
                outlined: classes.outlined,
            },
            size: {
                md: classes.md,
                lg: classes.lg,
            },
        },
    });

    return (
        <MuiChip
            label={content}
            className={clsx(variants({ intent, size }), className)}
            classes={{ ...classes }}
            {...props}
        />
    );
}

Chip.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    content: PropTypes.string,
    intent: PropTypes.oneOf(['contained', 'outlined']),
    size: PropTypes.oneOf(['md', 'lg']),
};

export default withStyles(chipStyles)(Chip);
