const routeEnpoints = {
    default: '/',
    register: '/register',
    twoFA: '/2fa',
    consent: '/termsprivacy',
    resetPassword: '/forgot-password',
    setResetPassword: '/reset-password',
    setInvitePassword: '/verify-account',
    verifyEmail: '/verify/email',
    signupv2: '/signup-v2',
    dashboard: '/dashboard',
    billingDashboard: '/billing/dashboard',
    integration: '/integrations',
    settings: '/settings',
    verifyUi: '/verify-ui',
    guides: '/guides',
    userSearch: '/user/search',
    workflows: '/workflows',
    signDocument: '/signDocument',
    tcm: '/tcm',
    users: '/users',
    logout: '/logout',
};
export default routeEnpoints;
