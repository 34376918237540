import React from 'react';

import PropTypes from 'prop-types';
import ListingsModal from 'views/V2/Integration/components/ListingsModal';
import SearchBrandAndListings from 'views/V2/Integration/components/SearchBrandAndListings';
import { useListings } from 'views/V2/Integration/hooks/useListings';
import { prepareListingsTableHeader } from 'views/V2/Integration/utils';

import classes from './viewListings.module.css';

import ListingsReusableContent from '../ListingsReusableContent';

const ViewListings = ({ open, onClose, brandUuid }) => {
    const {
        listingRef,
        handleScroll,
        isLoading,
        isFetching,
        rows,
        searchTerm,
        setSearchTerm,
        searchTags,
        setSearchTags,
        onSubmit,
        onRemoveSearchTag,
    } = useListings('view', brandUuid);

    const header = prepareListingsTableHeader(['Listing Name', 'Address']);

    const onCloseExtended = () => {
        setSearchTags([]);
        onClose();
    };

    return (
        <ListingsModal open={open} onClose={onCloseExtended} className={classes.container}>
            <ListingsModal.Header title="View Listings" onClose={onCloseExtended}>
                <SearchBrandAndListings
                    value={searchTerm}
                    setValue={setSearchTerm}
                    onSubmit={onSubmit}
                    tags={searchTags}
                    setTags={setSearchTags}
                    removeSearchTag={onRemoveSearchTag}
                />
            </ListingsModal.Header>
            <ListingsModal.Content>
                <ListingsReusableContent
                    ref={listingRef}
                    handleScroll={handleScroll}
                    header={header}
                    rows={rows}
                    isLoading={isLoading}
                    isFetching={isFetching}
                />
            </ListingsModal.Content>
        </ListingsModal>
    );
};

ViewListings.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    brandUuid: PropTypes.string,
};

export default ViewListings;
