import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Grid } from '@material-ui/core';
import useAccessRole from 'hooks/useAccessRole';
import DeleteFinalStepModal from 'views/V2/Integration/components/DeleteBrandModals/DeleteFinalStepModal';
import DeleteInitialStepModal from 'views/V2/Integration/components/DeleteBrandModals/DeleteInitialStepModal';
import EditBrandHeader from 'views/V2/Integration/components/EditBrandHeader';
import useBrandListings from 'views/V2/Integration/hooks/useBrandListings';
import useBrandNameForm from 'views/V2/Integration/hooks/useBrandNameForm';
import useCustomFormik from 'views/V2/Integration/hooks/useCustomFormik';
import useFetchWorkflow from 'views/V2/Integration/hooks/useFetchWorkflow';

import actionConstants from 'redux/actions/actionConstant';
import { openToast } from 'redux/actions/toaster';
import {
    useDeleteBrandMutation,
    useGetDefaultBrandQuery,
    useLazyListBrandsQuery,
    useMoveListingsMutation,
} from 'redux/slices/globalApi';

import styles from './configureBrand.module.css';

import EditBrandForm from '../EditBrandForm';
import ListBrands from '../ListBrands';
import MoveListings from '../MoveListings';
import ReusableBrandListings from '../ReusableBrandListings';
import UnsavedBrandWarning from '../UnsavedBrandWarning';
import UpdateBrandName from '../UpdateBrandName';

function ConfigureBrand(props) {
    const dispatch = useDispatch();

    const history = useHistory();
    const { uuid } = useParams();

    const [openMoveModal, setOpenMoveModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openFinalDeleteModal, setOpenFinalDeleteModal] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [brandNameModal, setBrandNameModal] = useState(false);
    const [isNewBrand, setIsNewBrand] = useState(false);
    const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [fetchBrands, { data }] = useLazyListBrandsQuery();
    const { data: defaultBrand } = useGetDefaultBrandQuery();
    const [deleteBrand] = useDeleteBrandMutation();
    const [moveListings] = useMoveListingsMutation();

    const { defaultWorkflow } = useFetchWorkflow();
    const { formik, brandData } = useCustomFormik(uuid, setUnsavedChanges);

    const { brandNameForm } = useBrandNameForm(
        defaultWorkflow,
        brandData,
        setBrandNameModal,
        isNewBrand
    );
    const { listingRef, listings, onChange, handleScroll, searchTerm, totalCount, isFetching } =
        useBrandListings(uuid);

    const { canUserUpdate } = useAccessRole();

    const chatbotConfig = window?.HubSpotConversations;

    const onOpenModal = () => {
        setOpenMoveModal(true);
        if (chatbotConfig) {
            chatbotConfig.widget.remove();
        }
    };

    const onCloseModal = () => {
        setOpenMoveModal(false);
        if (chatbotConfig) {
            chatbotConfig.widget.refresh();
        }
    };

    const fetchBrandsList = async () => {
        const payload = {
            page: 1,
            size: 20,
            filter: '',
        };

        try {
            await fetchBrands(payload);
        } catch (err) {}
    };

    const handleDeleteBrand = async () => {
        if (listings.length > 0) {
            setOpenDeleteModal(true);
            await fetchBrandsList();
        } else {
            setOpenFinalDeleteModal(true);
        }
    };

    useEffect(() => {
        if (data) {
            const options = data.brands
                .filter((brand) => brand.uuid !== uuid)
                .map((brand) => ({
                    label: `${brand.brandName} ${brand.isDefault ? '- Default' : ''} - (${
                        brand.listingCount
                    } listings)`,
                    value: brand.uuid,
                }));
            setSelectOptions(options);
        }
    }, [data, uuid]);

    const modalTitle =
        listings.length === 0 ? 'Delete Confirmation' : 'Step 2 - Delete Confirmation';

    const showToastMessage = (success, brandName, destinationBrand, errMessage) => {
        const customSuccessMessage = !destinationBrand
            ? `${brandName} deleted successfully.`
            : `${brandName} has been deleted. It's listings have been moved to ${destinationBrand}.`;

        const message = success ? customSuccessMessage : errMessage;

        dispatch(
            openToast({
                variant: success ? 'success' : 'error',
                message,
            })
        );
    };

    const callDeleteBrandApi = async () => {
        const result = await deleteBrand({ brandUuid: uuid }).unwrap();

        if (result?.success) {
            const label = selectOptions?.find((option) => option.value === selectedBrand)?.label;
            const selectedBrandName = label ? label.split(' - ')[0] : undefined;
            showToastMessage(true, brandData?.brandName, selectedBrandName);

            history.push(`/integrations/guesty/brands/${defaultBrand?.uuid}`);
        }
    };

    const onConfirmDelete = async () => {
        //note: need to handle loader globally wrt RTK query
        dispatch({ type: actionConstants.START_LOADER });

        try {
            if (listings.length > 0) {
                const payload = {
                    filters: {
                        searchTerms: [],
                        brandUuid: uuid,
                        ignoreCurrentBrand: false,
                    },
                    destinationBrandUuid: selectedBrand,
                };

                const moveResult = await moveListings(payload).unwrap();
                if (moveResult?.success) {
                    await callDeleteBrandApi();
                }
            } else {
                await callDeleteBrandApi();
            }
        } catch (err) {
            if (err?.data?.errorMessage) {
                showToastMessage(false, '', '', err?.data?.errorMessage);
            }
        } finally {
            dispatch({ type: actionConstants.STOP_LOADER });
            setOpenFinalDeleteModal(false);
            setSelectedBrand('');
        }
    };

    const onNext = () => {
        setOpenFinalDeleteModal(true);
        setOpenDeleteModal(false);
    };

    const onCloseInitialModal = () => {
        setOpenDeleteModal(false);
        setSelectedBrand('');
    };

    const onCloseNameModal = () => {
        setBrandNameModal(false);
        brandNameForm.resetForm();
    };

    return (
        <>
            <Box className={styles.configureBrandContainer}>
                <form onSubmit={formik.handleSubmit} className={styles.innerConfigureContainer}>
                    <Box className={styles.configureHeader}>
                        <EditBrandHeader
                            title={canUserUpdate ? 'Configure Brands' : 'View Brands'}
                            {...props}
                            isDefaultBrand={brandData?.isDefault}
                            handleDeleteBrand={handleDeleteBrand}
                            unsavedChanges={unsavedChanges}
                            setUnsavedChanges={setUnsavedChangesModal}
                            defaultBrandScreen={false}
                            canUserUpdate={canUserUpdate}
                        />
                    </Box>
                    <Grid className={styles.configureBrandLayout}>
                        <Box className={styles.listBrandsContainer}>
                            <ListBrands
                                brandUUID={uuid}
                                brandData={brandData}
                                unsavedChanges={unsavedChanges}
                                setUnsavedChanges={setUnsavedChangesModal}
                                openBrandNameModal={setBrandNameModal}
                                setIsNewBrand={setIsNewBrand}
                                canUserUpdate={canUserUpdate}
                            />
                        </Box>
                        <Box className={styles.editBrandContainer}>
                            <EditBrandForm
                                brandUUID={uuid}
                                brandData={brandData}
                                formik={formik}
                                listings={listings}
                                openBrandNameModal={setBrandNameModal}
                                setIsNewBrand={setIsNewBrand}
                                canUserUpdate={canUserUpdate}
                                setUnsavedChanges={setUnsavedChanges}
                            />
                        </Box>
                        <Box className={styles.editListingContainer}>
                            <ReusableBrandListings
                                noListings={
                                    (listings.length === 0 && searchTerm === '') || isNewBrand
                                }
                                listings={listings}
                                listingRef={listingRef}
                                onOpenModal={onOpenModal}
                                canUserUpdate={canUserUpdate}
                                totalCount={totalCount ?? 0}
                                onScroll={handleScroll}
                                value={searchTerm}
                                onChange={onChange}
                                isLoading={isFetching}
                            />
                        </Box>
                    </Grid>
                </form>
            </Box>
            <MoveListings
                brandName={brandData?.brandName}
                brandUuid={uuid}
                open={openMoveModal}
                onClose={onCloseModal}
                onCancel={() => {}}
            />
            <DeleteInitialStepModal
                open={openDeleteModal}
                onClose={onCloseInitialModal}
                onNext={onNext}
                brandName={brandData?.brandName}
                options={selectOptions}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                isNextDisabled={!selectedBrand}
            />
            <DeleteFinalStepModal
                open={openFinalDeleteModal}
                onClose={() => setOpenFinalDeleteModal(false)}
                onConfirm={onConfirmDelete}
                title={modalTitle}
                brandName={brandData?.brandName}
            />
            <UnsavedBrandWarning
                open={unsavedChangesModal}
                setOpen={setUnsavedChangesModal}
                resetForm={formik.resetForm}
                setUnsavedChanges={setUnsavedChanges}
            />
            <UpdateBrandName
                open={brandNameModal}
                setOpen={setBrandNameModal}
                isEditBrand={!isNewBrand}
                brandName={brandNameForm.values.brandName}
                handleChange={brandNameForm.handleChange}
                error={brandNameForm.touched.brandName && Boolean(brandNameForm.errors.brandName)}
                helperText={brandNameForm.touched.brandName && brandNameForm.errors.brandName}
                onConfirm={brandNameForm.handleSubmit}
                isDisabled={!brandNameForm.dirty || brandNameForm.values?.brandName?.trim() === ''}
                onClose={onCloseNameModal}
            />
        </>
    );
}

export default ConfigureBrand;
