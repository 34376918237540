import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        setCompanyRegistrationData: (state, action) => {
            return {
                ...state,
                ...action.payload.values,
            };
        },
        clearCompanyRegistrationData: () => initialState,
    },
});

export const { setCompanyRegistrationData, clearCompanyRegistrationData } = registerSlice.actions;
export default registerSlice.reducer;
