import actionType from 'redux/actions/actionConstant';

const initialState = {
    invoiceData: undefined,
    currentCredit: undefined,
    billingPlans: undefined,
};

export default function billing(state = initialState, action) {
    switch (action.type) {
        case actionType.GET_INVOICE_DATA_SUCCESS:
            return {
                ...state,
                invoiceData: action.payload,
            };

        case actionType.GET_CURRENT_CREDIT_SUCCESS:
            return {
                ...state,
                currentCredit: action.payload,
            };

        case actionType.GET_BILLING_PLANS_SUCCESS:
            return {
                ...state,
                billingPlans: action.payload,
            };

        case actionType.RESET_ALL_REDUCERS:
            return {
                ...state,
                invoiceData: undefined,
                currentCredit: undefined,
                billingPlans: undefined,
            };

        default:
            return state;
    }
}
